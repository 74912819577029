import { statusDisplayInfo, backgroundColour, backgroundBorderColour, overrideTextColour, alarmTextColour,evaluateCondition } from "./constants";
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon, CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

function convertDateString(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
}
function StatusRecord({ point, children }) {
    //let Icon = statusDisplayInfo[override.get('icon')].Icon;
      

    // console.log("Filters applied", siteAlarmFilterGroups);
    // console.log("AlarmRecord", currentUser);

    //let icon = alarm.get('icon');
    // console.log("icon value before");
    


     let icon = 0;

     if ( point.m_inAlarm > 0 ) icon = 1;

    

    return (  
        icon != undefined &&
        
            <div className={`@container rounded-md ${backgroundColour[statusDisplayInfo[icon].colour]} p-2 border-4 border-double ${backgroundBorderColour[statusDisplayInfo[icon].colour]}`}>
                <div className="flex ml-8 item-center justify-between  flex-col @2xl:flex-row">
                { point.m_softwareOverriden > 0  &&
                    <div className="text-nowrap">
                         
                        Point In Override : { point.m_pointName}
                    </div>}
                    { point.m_inAlarm > 0  &&
                    <div className="text-nowrap">
                         
                        Point In Alarm : { point.m_pointName}
                    </div>}

                    <div className="flex flex-row gap-4">
                        
                    </div>
                </div>
                <div className="flex ml-8 item-center justify-between">
                    Current Value : { point.m_formattedValueText }
                </div>
                { point.m_softwareOverriden > 0  &&
                <div className="flex ml-8 item-center justify-between">
                    Last Command : { point.m_lastCommand  } 
                </div> }
                { point.m_softwareOverriden > 0  &&
                <div className="flex ml-8 gap-3 justify-end">
                    {children}
                </div>}
            </div>
    )
}

StatusRecord.ReleaseButton = StatusRecordReleaseButton;

function StatusRecordReleaseButton({ point, onReleasePoint }) {
    return <div className="flex" onClick={() => onReleasePoint(point, 0)}>
        Release
        <div><CheckCircleIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" /></div>
    </div>
}


export default StatusRecord;