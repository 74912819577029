
import { create } from 'zustand';
import { List } from 'immutable';


export const useTimezoneScreenStore = create((set, get) => ({
    events: List(),
    dirty: false,
    displaying: 'current',
    clipboard: {},
    bacnetScheduleDefault: '',
    isSelectedDayTemplate: undefined,
    setEvents: (events) => set((state) => ({ events: callWithXIfFunction(state.events, events) })),
    setDirty: (dirty) => set({ dirty }),
    setDisplaying: (displaying) => set({ displaying }),
    setClipboard: (clipboard) => set({ clipboard }),
    setBacnetScheduleDefault: (bacnetScheduleDefault) => set({ bacnetScheduleDefault }),
    setIsSelectedDayTemplate: (isSelectedDayTemplate) => set({ isSelectedDayTemplate }),
    resetState: () => set({ 
        events: [], 
        dirty: false, 
        displaying: 'current',
        clipboard: {},
        bacnetScheduleDefault: '',
        isSelectedDayTemplate: undefined
    }),
}))

function callWithXIfFunction(x, fn) {
    return typeof fn === 'function' ? fn(x) : fn;
}
