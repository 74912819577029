import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Immutable from 'immutable';
import ServiceBus from 'Services/ServiceBus';
import {getFileCommunic} from 'BmsView/Actions/Common';
import {getCollection, deleteDevice, saveItem} from '../Actions';

import PointList from './PointList';
import ControllerSelection from './ControllerSelection';
import ScanForPointsDialog from '../ScanForPoints';
import Swal from 'sweetalert2';
import Button from 'Controls/Button/UserDefined';

export default function SeachangeSetupDialog({opendialog, onClose, customer, selecteditem})
{
	const [open, setOpen] = useState(opendialog);
	const [lans, setLans] = useState(Immutable.List());
	const [selectedprimarycontroller, setSelectedPrimaryController] = useState(undefined);
	const [selectedprimarycontrollertype, setSelectedPrimaryControllerType] = useState(undefined);
	const [selectedsecondarycontroller, setSelectedSecondaryController] = useState(undefined);
	const [outstations, setOutstations] = useState(Immutable.List());
	const [points, setPoints] = useState(Immutable.List());
	const [selectedoutstation, setSelectedOutstation] = useState(undefined);
	const [dialogtype, setDialogType] = useState(undefined);
	const [overwrite, setOverwrite] = useState(false);
	const [histories, setHistories] = useState(false);
	const [paneltypes, setPanelTypes] = useState(undefined);

    const getPointList = () => {
		let query = {};
	//	if (!selectedlan || !selectedoutstation) return;

		query['addr.0'] = selecteditem.get('number');
	//	query['addr.3'] = selectedlan.get('number');
	//	query['addr.4'] = selectedoutstation.get('outnumber');

		getCollection(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
			`${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'points', query).then((data) => {
				setPoints(Immutable.fromJS(data.map((point) => {
					return {
						subsystem: point.addr[0],
						subsystemtype: point.addr[1],
						type: point.addr[2],
						lan: point.addr[3],
						outstation: point.addr[4],
						name: point.name,
						number: point.addr[5]
					}
				})));
			})
	}

	const scan = (type, primaryController, primaryControllerType) => {
		setSelectedPrimaryController(primaryController);
		setSelectedPrimaryControllerType(primaryControllerType);
		setDialogType(type);
	}

	
	const closeDialog = (name) => {
		if (name == 'discoversite') {
			this.getTrendLanList();
		}

		if (name == 'scanforpoints') {
			getPointList();
		}

		setDialogType(undefined);
	}

    return (
        <React.Fragment>
			{dialogtype == "scanforpoints" && selectedprimarycontroller && <ScanForPointsDialog selecteditem={dialogtype} type={"seachange"} customer={customer} selectedSubsystem={selecteditem.get('number')} selectedlan={selectedprimarycontroller} selectedcontroller={selectedprimarycontrollertype} overwrite={overwrite} histories={histories} onClose={() => closeDialog('scanforpoints')} />}
			{dialogtype == "discoversite" && <ScanForPointsDialog selecteditem={dialogtype} type={"trend"} customer={customer} selectedSubsystem={selecteditem.get('number')} overwrite={overwrite} histories={histories} onClose={() => closeDialog('scanforpoints')} />}
			<Transition.Root show={opendialog} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-lg mx-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full sm:p-6">
									<div>
										<div className="mt-2 sm:mt-3">
											<Dialog.Title as="h2" className="text-xl leading-6 font-semibold text-gray-900">
												Seachange Configuration Setup
											</Dialog.Title>
											<div class="grid grid-cols-11 gap-8 mt-8">
												<div class="col-span-3">
													<ControllerSelection
														scan={(type, primarycontroller, primarycontrollertype )=> scan(type, primarycontroller, primarycontrollertype )}
														 />
													
												</div>
												<div class="col-span-4">
													
												</div>
												<div class="col-span-4">
													<PointList
														selectedsubsystem={selecteditem.get('number')}
														selectedlan={selectedprimarycontroller}
														selectedoutstation={selectedprimarycontrollertype}
														points={points}
													/>
												</div>
											</div>
										</div>
										<div className="mt-6 flex justify-end">
											
											<div className="pl-1">
												<Button
													role="primary"
													onClick={() => scan('discoversite')}>Auto Point Discovery</Button>	
											</div>
											<div className="pl-1">
												<Button
													role="secondary"
													onClick={() => onClose()}>Exit</Button>
											</div>
											
											
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</React.Fragment>

    )

}