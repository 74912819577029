import React from 'react';
import EventRecord from '../../../Components/Events/EventRecord';


export function EventListPanel({ children }) {
    return <ul role="list" className="divide-y divide-gray-200 min-h-[10vh] max-h-[70vh] overflow-y-auto">
        {children}
    </ul>
}

export function EventListGrid({ children }) {

    let amountOfColumns = React.Children.count(children);
    // console.log("Amount of columns", amountOfColumns);

    let gridClassNames = {
        1: "grid-cols-1",
        2: "grid-cols-2",
        3: "grid-cols-3",
        4: "grid-cols-4",
        5: "grid-cols-5",
        6: "grid-cols-6",
        7: "grid-cols-7",
        8: "grid-cols-8",
    }

    let gridClassName = "grid-cols-1";
    if( amountOfColumns < 9) {
        gridClassName = gridClassNames[amountOfColumns];
        if(amountOfColumns == 1) {
            gridClassName = "grid-cols-1 container mx-auto";
        }
    } else {
        
        throw new Error("Amount of columns must have a maximum of 8 columns.");
    }

    return <div className={`grid ${gridClassName} gap-4`}>
        {children}
    </div>
}

export function EventListGridColumn({ children }) {
    return <div>
        {children}
    </div>
}

EventListPanel.EventRecordPanel = ({ children }) => {
    return <li className="py-1 text-black">
        {children}
    </li>
}


const EventListPanelComplete = ({records, displayEventDetails, onClose, onSave}) => {

    return (
        <div> 
            <ul role="list" className="divide-y divide-gray-200 min-h-[10vh] max-h-[70vh] overflow-y-auto">
                {(records || []).map((event, index) =>  { return (
                    <li key={index} className="py-1 text-black">
                        <EventRecord event={event}>
                            <EventRecord.ShowDetailsButton event={event} displayEventDetails={displayEventDetails} />
                        </EventRecord>
                    </li>)}
                )}
            </ul>
        </div>
    )
}


export default EventListPanelComplete;