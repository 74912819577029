import { createContext, useContext, useEffect, useState } from 'react';
import { useServiceBus } from './ServiceBus';
import ServiceBus from '../../Services/ServiceBus';

const RealtimeDebuggerContext = createContext();

export const useRealtimeDebugger = () => {
    return useContext(RealtimeDebuggerContext);
}

export const allChannels = [
    'logger',
    'servicebus-events',
    'mongo-command'
]


export const RealtimeDebuggerProvider = ({ children }) => {

    const { authenticated } = useServiceBus();
    const [messages, setMessages] = useState([]);
    const [messageFilter, setMessageFilter] = useState([...allChannels.filter(x => x == 'mongo-command')]);

    useEffect(() => {
        if(authenticated) {
            console.log("sending attach debugger");
            ServiceBus.send("WEB_MESSAGE_REQ", {
                type: 'ATTACH_DEBUGGER',
                target: 'webserver'
            }, {});

            return () => {
                ServiceBus.send("WEB_MESSAGE_REQ", {
                    type: 'DETACH_DEBUGGER',
                    target: 'webserver'
                }, {});
            }
        }
    }, [authenticated])

    useEffect(() => {

        if(messageFilter && messageFilter.length > 0) {
            setMessages((prev) => {
                return prev.filter((message) => {
                    return messageFilter.includes(message.channel);
                });
            });
        }
    }, [messageFilter])

    useEffect(() => {

        if(authenticated) {
            let unsubscribe = ServiceBus.on('DEBUG_MESSAGE', (data) => {

                if(messageFilter.length > 0) {
                    if(!messageFilter.includes(data.channel)) {
                        return;
                    }
                }

                setMessages((prev) => {
                    let prevCopy = [...prev]
                    if(prevCopy.length > 1000) {
                        prevCopy.shift();
                    }
                    prevCopy.push(data);
                    return prevCopy;
                });
            });
            return () => {
                unsubscribe();
            }
        }

    }, [authenticated, messageFilter])

    const toggleMessageFilter = (channel) => {
        setMessageFilter((prev) => {
            if(prev.includes(channel)) {
                return prev.filter((item) => item !== channel);
            } else {
                return [...prev, channel];
            }
        });
    }

    const clearMessages = () => {
        setMessages([]);
    }

    return (
        <RealtimeDebuggerContext.Provider value={{
            messages: [...messages],
            toggleMessageFilter,
            messageFilter,
            clearMessages

        }}>
            {children}
        </RealtimeDebuggerContext.Provider>
    )

}