import React, {useState, useEffect} from 'react';
import Immutable from 'immutable';
import {getGroups, deleteGroup, saveGroup} from './Actions';
import Grid from 'Controls/GridV2';
import Select from 'Controls/Form/Select';
import PointGroupEditDialog from './PointGroupEditDialog';
import Swal from 'sweetalert2';
import {PencilSquareIcon, TrashIcon, PlusIcon, CursorArrowRaysIcon } from '@heroicons/react/24/solid';
import { usePointGroupManagementStore } from './PointGroupManagement';
import PointGroupManagementButton from './PointGroupManagement/Components/PointGroupManagementButton';
import PointGroupManagementDialog from './PointGroupManagement/Components/PointGroupManagementDialog';

const types = [ 'Alarm','Calendar','Schedule','Point Transfer','Program','Bacnet Schedule' ];
const dialogCursor = 'PointGroupSetup';


const PointGroups = ({customer}) => {

    const close = usePointGroupManagementStore((state) => state.close);
    const [groups, setGroups] = useState(null);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [isOpened, setIsOpened] = useState(false);
    const [query, setQuery] = useState('');

    const filteredGroups =
    query === ''
      ? groups
      : groups.filter((group) => {
          return group.get('type') == query;
        })

    useEffect(() => {
        getPointGroups();
    }, []);
     
    const getPointGroups = () => {
        getGroups(  `${customer.get('name').toLowerCase().replace(/ /g,"")}`,
                    `${customer.get('ipaddress').toLowerCase()}`,'pointgroups').then((data) => {
            let groups = Immutable.fromJS(data.map((group) => {
                    return group;
                }));

            setGroups(groups);
            });    
    };

    const displayDialog = (group) => {
        setSelectedItem(group);
        setIsOpened(true);
    }

    const closeDialog = () => {
        setSelectedItem(undefined);
        setIsOpened(false);
    }

    const saveCurrentGroup = (group) => {
        let msg = "The group has been updated successfully";

        if (!group.get('_id')) {
          // check for duplicate
          for(var i=0;i<groups.size;i++) {
              if ( groups.get(i).get('name') == group.get('name')) {
                  Swal.fire("Error", "Duplicate Group Name", "error");
                  return;
              }
          }
          msg = "The group has been added successfully";
        }  

        saveGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(), 'pointgroups', group).then(() => {
            Swal.fire("Success", msg, "success");
            getPointGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error adding group", "error");
        });
    }

    const removeGroup = (group) => {
        deleteGroup(customer.get('name').toLowerCase().replace(/ /g, ""), customer.get('ipaddress').toLowerCase(),'pointgroups', group.get('_id')).then(() => {
            Swal.fire("Success", "The group has been removed successfully", "success");
            getPointGroups();
        }).catch((err) => {
            Swal.fire("Error", "Error removing group", "error");
        });
    }

    const clearSelection = () => {
        setSelectedItem(undefined);
    }

    const onGroupManagementClose = () => {
        close({ cursor: dialogCursor });
    }

    return (
        <div  className='container mx-auto my-4'>
          <PointGroupManagementDialog cursor={dialogCursor} onClose={() => onGroupManagementClose()} />
          {isOpened && <PointGroupEditDialog 
            isOpened={isOpened} 
            selecteditem={selectedItem} 
            onClose={() => closeDialog()} 
            onSave={(group) => saveCurrentGroup(group)}>
            </PointGroupEditDialog>}
          <h2>Point Group Setup</h2>              
          <div className="grid grid-cols-16 gap-2">
            <div className="col-span-16 md:col-span-5">
              <Select
                label="Type"
                value={query}
                onChange={(value) => setQuery(value)}
                options={[{text: 'All Groups', value: ''}, ...types.map((type) => {
                  return (
                    {text: type, value: type
                  });
                })]}
              />
            </div>
          </div>
          <Grid>
            <Grid.Header>
              <Grid.Column  classes={'col-span-16 md:col-span-4'}>Name</Grid.Column>
              <Grid.Column  classes={'col-span-16 md:col-span-2'}>Type</Grid.Column>
              <Grid.Column  classes={'col-span-16 md:col-span-6'}>Description</Grid.Column>
              <Grid.Column classes={"col-span-16 md:col-span-4"} name="add" type="complex">
                <Grid.Controls>
                  <Grid.Control>
                      <PlusIcon className="w-6 text-white cursor-pointer" title="add new" onClick={() => displayDialog(Immutable.Map({"type":"Alarm"}))} />
                  </Grid.Control>
                </Grid.Controls>
              </Grid.Column>
            </Grid.Header>
            <Grid.Data className="max-h-[70vh] overflow-x-hidden">
              {(filteredGroups || []).map((group, index) => {
                  return (
                      <Grid.Row key={index} className="hover:bg-gray-300 group">
                        <Grid.Column classes="col-span-16 md:col-span-4">{group.get('name')}</Grid.Column>
                        <Grid.Column  classes="col-span-16 md:col-span-2">{group.get('type', '')}</Grid.Column>
                        <Grid.Column  classes="col-span-16 md:col-span-6">{group.get('description')}</Grid.Column>
                        <Grid.Column classes="col-span-16 md:col-span-4">
                          <Grid.Controls>
                            <Grid.Control>
                              <PointGroupManagementButton 
                                type={group.get('type')} 
                                customer={customer} 
                                cursor={dialogCursor} 
                                panelType="EditPointsInGroup"
                                group={group} 
                                editable={true} />
                              </Grid.Control>
                            <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue w-6" onClick={() => displayDialog(group)} /></Grid.Control>
                            <Grid.Control><TrashIcon title="Remove" className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500 w-6" onClick={() => removeGroup(group)} /></Grid.Control> 
                          </Grid.Controls>
                        </Grid.Column>
                      </Grid.Row>
                  )
              })}
            </Grid.Data>
          </Grid>
        </div>
    )
}

export default PointGroups;

