import React, {useState} from 'react';
import Select from 'Controls/Form/Select';
import { FunnelIcon } from '@heroicons/react/24/outline';

const StatusLogFilter = ({logtype, subsystems, selectedSubsystem, setSubsystem, controllers, selectedController, setController, hideToggle = false, defaultOpen = true}) => {
    const [open, setOpen] = useState(hideToggle || defaultOpen);

    return(
        <div className="flex flex-col md:flex-row gap-2 px-3 items-center">
           {!hideToggle && <div className="">
                <button
                    onClick={() => setOpen(!open)}
                    aria-controls="filters"
                    className="py-1"
                    aria-expanded={open} ><FunnelIcon className="w-6 h-6" /></button>
            </div> }
            { open && logtype == 'override' && 
            <div className="">
                <Select
                    containerClassName="!mb-0"
                    label="Subsystem"
                    value={selectedSubsystem}
                    onChange={ value => setSubsystem(value)}
                    options={[ ...(subsystems || []).map((subsystem,index) => {
                        return {
                            value: subsystem.get('number'),
                            text: subsystem.get('name')
                        }
                    })]}
                />
            </div>}
            {open && logtype == 'override' &&
                <div className="">
                    <Select
                        containerClassName="!mb-0"
                        label="Controller"
                        value={selectedController}
                        onChange={value => setController(parseInt(value))}
                        options={[ "", ...controllers.map((controller,index) => { 
                            return {
                                value: controller.get('instancenumber'),
                                text: `${controller.get('instancenumber')} ${controller.get('devicename')}`
                            }
                        })]}    
                    />
                </div>}
        </div>    
    );
}

export default StatusLogFilter;

