import React from 'react';
import StatusRecord from '../../../Components/Statuses/StatusRecord';

export function StatusListPanel({ children }) {
    return <ul role="list" className="divide-y divide-gray-200 min-h-[10vh] max-h-[70vh] overflow-y-auto">
        {children}
    </ul>
}

export function StatusListGrid({ children }) {
    let amountOfColumns = React.Children.count(children);

    let gridClassNames = {
        1: "grid-cols-1",
        2: "grid-cols-2",
        3: "grid-cols-3",
        4: "grid-cols-4",
        5: "grid-cols-5",
        6: "grid-cols-6",
        7: "grid-cols-7",
        8: "grid-cols-8",
    }

    let gridClassName = "grid-cols-2";
    if( amountOfColumns < 9) {
        gridClassName = gridClassNames[amountOfColumns];
        if(amountOfColumns == 1) {
            gridClassName = "grid-cols-1 container mx-auto";
        }
    } else {  
        throw new Error("Amount of columns must have a maximum of 8 columns.");
    }

    return <div className={`grid ${gridClassName} gap-4`}>
        {children}
    </div>
}

export function StatusListGridColumn({ children }) {
    return <div>
        {children}
    </div>
}

StatusListPanel.StatusRecordPanel = ({ children }) => {
    return <li className="py-1 text-black">
        {children}
    </li>
}

const StatusListPanelComplete = ({ pointList, onReleasePoint, showFilters = true }) => {
    return (
        <div> 
            {(pointList && pointList[0] == -1 ) && 
                (showFilters && <div>Please Select Controller...</div> || <div>Loading point override statusses</div>)
            }
            {(!pointList ) && 
                <div>Retrieving point information. Please Wait...</div>
            }
            {(pointList?.length < 1 ) && 
                <div>There are no points to check</div>
            }
            <ul role="list" className="divide-y divide-gray-200 min-h-[10vh] max-h-[70vh] overflow-y-auto">
                {pointList && (pointList[0] != -1 ) && (pointList || []).map((point, index) =>  { return (
                    <li key={index} className="py-1 text-black">
                        <StatusRecord point={point}>
                            <StatusRecord.ReleaseButton point={point} onReleasePoint={onReleasePoint}/>
                        </StatusRecord>
                    </li>)}
                )}
            </ul>
        </div>
    )
}

export default StatusListPanelComplete;