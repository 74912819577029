import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bars3BottomLeftIcon, Bars3BottomRightIcon, BarsArrowDownIcon, BarsArrowUpIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { TooltipHoverable } from '../../../../../Controls/Tooltip'; 

let transparentImage = new Image();
transparentImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR4nGNgYGBgAAAABQABpfZFQAAAAABJRU5ErkJggg=='

export function ToolboxPortal({ children }) {


    const elRef = useRef(document.createElement('div'));

    useEffect(() => {

        const el = elRef.current;
        const container = document.getElementById('toolbox-portal-space');
        container.appendChild(el);

        return () => {
            container.removeChild(el);
        }

    }, [])

    return ReactDOM.createPortal(
        children,
        elRef.current
    );


}


export default function Toolbox({ onToolboxAction, toolboxState }) {

    const [expanded, setExpanded] = useState(false);


    const styleRef = useRef({  });
    const buttonRef = useRef(null);

    const setMarginTop = () => {
        let initialY = styleRef.current.startY;
        let currentY = styleRef.current.y;
        let diff = currentY - initialY;
        let marginTop = diff + 'px';
        buttonRef.current.style.marginTop = marginTop;
    }

    const buttonDragStartEvent = (evt) => {

        styleRef.current.startY = styleRef.current.startY || evt.clientY;
        styleRef.current.y = evt.clientY;
        setMarginTop();

        buttonRef.current.addEventListener('drag', buttonDragEvent);
        buttonRef.current.addEventListener('dragend', buttonDragEndEvent);
        
        evt.dataTransfer.setDragImage(transparentImage, 0, 0);
    }

    const buttonDragEvent = (evt) => {
        // console.log("button drag event called");

        // console.log(evt);
        styleRef.current.y = evt.clientY;
        setMarginTop();
    }

    const buttonDragEndEvent = (evt) => {
        styleRef.current.y = evt.clientY;
        setMarginTop();
        buttonRef.current.removeEventListener('drag', buttonDragEvent);
        buttonRef.current.removeEventListener('dragend', buttonDragEndEvent);

    }

    return <ToolboxPortal><div>
        <div className="font-bold p-2">
            Toolbox
        </div>
        <div className="grid grid-cols-2 gap-2 p-2 relative">

           

            <TooltipHoverable tooltip={`${toolboxState.selectionmode ? 'Leave' : 'Enter'} election mode`}>
                <ToolboxButton 
                    onClick={() => onToolboxAction('selection-mode-toggle')}>
                    { toolboxState.selectionmode && <SelectionSVG className="w-8 h-8" alt={'Disable selectionmode'} /> }
                    { !toolboxState.selectionmode && <ArrowSVG className="w-4 h-8" alt={'Enable selectionmode'} /> }
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Copy selected components`}>
                <ToolboxButton 
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('copy')}>
                    <CopySVG className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Paste copied components`}>
                <ToolboxButton 
                    disabled={!toolboxState.hasContentToPaste}
                    onClick={() => onToolboxAction('paste')}>
                    <PasteSVG className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Delete selected components`}>
                <ToolboxButton 
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('delete')}>
                    <DeleteSVG className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Align selected components to the left`}>
                <ToolboxButton
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('align-left')}>
                        <Bars3BottomLeftIcon className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Align selected components to the right`}>
                <ToolboxButton
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('align-right')}>
                        <Bars3BottomRightIcon className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Align selected components to the bottom`}>
                <ToolboxButton
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('align-bottom')}>
                        <BarsArrowDownIcon className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

            <TooltipHoverable tooltip={`Align selected components to the top`}>
                <ToolboxButton
                    disabled={toolboxState.selectedItems === 0}
                    onClick={() => onToolboxAction('align-top')}>
                        <BarsArrowUpIcon className="w-8 h-8" />
                </ToolboxButton>
            </TooltipHoverable>

        </div> 

    </div>
    </ToolboxPortal>

}

function ToolboxToggleButton({ className = '', expanded, toggleExpanded }) {

  

    return <button className={`${className}`} onClick={toggleExpanded} >
        { !expanded && <ChevronDoubleLeftIcon className="w-8 h-8" /> }
        { expanded && <ChevronDoubleRightIcon className="w-8 h-8" /> }
    </button>

}

function ToolboxButton({ children, active, disabled, onClick }) {

    let className = 'bg-gray-100';
    if(active) {
        className = 'bg-gray-100 text-blue-600 border border-blue-600';
    }
    if(disabled) {
        className = 'bg-gray-100 text-gray-400';
    }

    const clickListener = (evt) => {
        if(disabled) return;
        onClick(evt);
    }

    return <div onClick={clickListener} className={`p-2 cursor-pointer flex flex-col items-center justify-center ${className}`}>
        {children}
    </div>
}

function SelectionSVG({ className = '', alt = '' }) {
    return <svg
        alt={alt}
        className={className}
        viewBox="0 0 16 16"
        version="1.1"
        >
        <g
            fill="currentColor">
            <path
                d="M 0,0 V 3 H 3 V 0 Z M 4,0 V 2 H 7 V 0 Z m 4,0 v 2 h 4 V 0 Z m 5,0 v 3 h 3 V 0 Z M 0,4 V 8 H 2 V 4 Z m 14,0 v 3 h 2 V 4 Z m 0,4 v 4 h 2 V 8 Z M 0,9 v 3 H 2 V 9 Z m 0,4 v 3 h 3 v -3 z m 13,0 v 3 h 3 v -3 z m -9,1 v 2 h 4 v -2 z m 5,0 v 2 h 3 v -2 z m 0,0"
                />
            <path
                d="M 5,4 C 4.445312,4 4,4.445312 4,5 V 6 C 4,6.554688 4.445312,7 5,7 H 6 C 6.554688,7 7,6.554688 7,6 V 5 C 7,4.445312 6.554688,4 6,4 Z M 9,4 C 8.445312,4 8,4.445312 8,5 v 2 c 0,0.554688 0.445312,1 1,1 h 2 c 0.554688,0 1,-0.445312 1,-1 V 5 C 12,4.445312 11.554688,4 11,4 Z M 5,8 C 4.445312,8 4,8.445312 4,9 v 2 c 0,0.554688 0.445312,1 1,1 h 2 c 0.554688,0 1,-0.445312 1,-1 V 9 C 8,8.445312 7.554688,8 7,8 Z m 5,1 C 9.445312,9 9,9.445312 9,10 v 1 c 0,0.554688 0.445312,1 1,1 h 1 c 0.554688,0 1,-0.445312 1,-1 V 10 C 12,9.445312 11.554688,9 11,9 Z m 0,0"
                fillOpacity="0.34902"
                />
        </g>
    </svg>

}

function CopySVG({ className = '' }) {
    return <svg
    className={className}
        viewBox="0 0 16 16"
        version="1.1">
    <path
        d="M 0,3 C 0,1.355469 1.355469,0 3,0 h 5 c 1.644531,0 3,1.355469 3,3 0,0.550781 -0.449219,1 -1,1 C 9.449219,4 9,3.550781 9,3 9,2.429688 8.570312,2 8,2 H 3 C 2.429688,2 2,2.429688 2,3 V 8 C 2,8.570312 2.429688,9 3,9 3.550781,9 4,9.449219 4,10 4,10.550781 3.550781,11 3,11 1.355469,11 0,9.644531 0,8 Z M 5,8 C 5,6.355469 6.355469,5 8,5 h 5 c 1.644531,0 3,1.355469 3,3 v 5 c 0,1.644531 -1.355469,3 -3,3 H 8 C 6.355469,16 5,14.644531 5,13 Z m 2,0 v 5 c 0,0.570312 0.429688,1 1,1 h 5 c 0.570312,0 1,-0.429688 1,-1 V 8 C 14,7.429688 13.570312,7 13,7 H 8 C 7.429688,7 7,7.429688 7,8 Z m 0,0"
        fill="currentColor"
        />
    </svg>
}

function PasteSVG({ className }) {
    return <svg
        className={className}
        viewBox="0 0 14 16"
        version="1.1">
    <path
        d="M 6,0 C 5.445312,0 5,0.445312 5,1 H 3 C 1.355469,1 0,2.355469 0,4 v 9 c 0,1.644531 1.355469,3 3,3 h 8 c 1.644531,0 3,-1.355469 3,-3 V 4 C 14,2.355469 12.644531,1 11,1 H 9 C 9,0.445312 8.554688,0 8,0 Z M 3,3 H 4 V 4 C 4,4.554688 4.445312,5 5,5 H 9 C 9.554688,5 10,4.554688 10,4 V 3 h 1 c 0.570312,0 1,0.429688 1,1 v 9 c 0,0.570312 -0.429688,1 -1,1 H 3 C 2.429688,14 2,13.570312 2,13 V 4 C 2,3.429688 2.429688,3 3,3 Z m 0,0"
        fill="currentColor"
     />
    </svg>
}

function DeleteSVG({ className }) {
    return <svg
        className={className}
        viewBox="0 0 16 16"
        version="1.1">
   
    <path
        d="M 8,0 C 3.589844,0 0,3.589844 0,8 c 0,4.410156 3.589844,8 8,8 4.410156,0 8,-3.589844 8,-8 C 16,3.589844 12.410156,0 8,0 Z m 0,2 c 3.332031,0 6,2.667969 6,6 0,3.332031 -2.667969,6 -6,6 C 4.667969,14 2,11.332031 2,8 2,4.667969 4.667969,2 8,2 Z M 5.96875,4.96875 c -0.265625,0 -0.519531,0.105469 -0.707031,0.292969 -0.390625,0.390625 -0.390625,1.023437 0,1.414062 L 6.554688,7.96875 5.261719,9.261719 c -0.390625,0.390625 -0.390625,1.023437 0,1.414062 0.390625,0.390625 1.023437,0.390625 1.414062,0 L 7.96875,9.382812 9.261719,10.675781 c 0.390625,0.390625 1.023437,0.390625 1.414062,0 0.390625,-0.390625 0.390625,-1.023437 0,-1.414062 L 9.382812,7.96875 10.675781,6.675781 c 0.390625,-0.390625 0.390625,-1.023437 0,-1.414062 -0.1875,-0.1875 -0.441406,-0.292969 -0.707031,-0.292969 -0.265625,0 -0.519531,0.105469 -0.707031,0.292969 L 7.96875,6.554688 6.675781,5.261719 C 6.488281,5.074219 6.234375,4.96875 5.96875,4.96875 Z m 0,0"
        fill="currentColor" />
    </svg>
}

function ArrowSVG({ className, alt }) {
    return <svg
        className={className}
        alt={alt}
        version="1.1"
       >
        <g
            transform="translate(-10.156413,-2.8198797)">
            <path
                d="M 10.874818,4.690519 21.10339,16.043395 h -5.455238 l 4.091428,10.596016 -1.363809,0.756859 -4.091429,-10.596017 -3.409524,3.784292 z"
                fill="currentColor"
                />
        </g>
    </svg>
}