import React,{useState, useEffect} from 'react';
import { fromJS, List, Map, OrderedMap,Iterable } from 'immutable';
import Input from 'Controls/Form/Input';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import Checkbox from 'Controls/Form/Checkbox';
import { useBMS } from '../../Context/BMS';
import { saveSiteConfiguration } from '../Site/Actions';
import { useRevalidator } from 'react-router-dom';
// import { get } from '../../../../server/services/configuration';
import axios from 'axios';

async function getAutoMenus() {
    try {
        let autoMenuList = await axios.get('/automenus/all');
        return autoMenuList.data;
    } catch(e) {
        return OrderedMap();
    }
}

function useAutoMenus() {
    const [autoMenus, setAutoMenus] = useState(OrderedMap());
    useEffect(() => {
        getAutoMenus().then((menus) => {
            setAutoMenus(fromJS(menus, function (key, value) {
                let isIndexed = Iterable.isIndexed(value);
                return isIndexed ? value.toList() : value.toOrderedMap();
            }));
        });
    }, []);
    return autoMenus;
}

function GenericMenuDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} >
        <GenericMenuDialogPanel selectedItem={selecteditem} onClose={onClose} {...props}/>
    </Dialog>
}

function createChildren(item) {
    let menuNodes = List();
    if(!item.get('children') || item.get('children').size < 1) return menuNodes;
        item.get('children').forEach(child => {
            if(!child.get('checked')) return;
            let childNodes = List();
            let quantity = child.get('quantity') || 1;
            let name = quantity > 1 ? child.get('areaName') || child.get('name') : child.get('name');
            for(let i = 0; i < quantity; i++) {

                childNodes = childNodes.push(Map({
                    name: name + (quantity > 1 ? ` ${i + 1}` : ''),
                    isV2: true,
                    treenodes: createChildren(child)
                }));
            }
            if(child.get('group') && quantity > 1) {
                let groupNode = Map({
                    name: child.get('name'),
                    isV2: true,
                    treenodes: childNodes
                });
                menuNodes = menuNodes.push(groupNode);
            } else {
                menuNodes = menuNodes.concat(childNodes);
            }
           
        });
    return menuNodes;

}

const GenericMenuDialogPanel = ({ onClose}) => {
   
    const autoMenus = useAutoMenus();
    const [defaultItems, setDefaultItems] = useState(OrderedMap());
    const { site, revalidateSite } = useBMS();
    const { params } = useBMS();
    const revalidator = useRevalidator();

    useEffect(() => {
        console.log("useEffect automatic menus", autoMenus);
        if(autoMenus.size > 0) {
           // temporary select the first one till requirements come for multiple auto menus
           
           console.log("trying to set default items", autoMenus);
           
           let autoMenu = autoMenus.first();
            setDefaultItems(autoMenu.get('structure'));
            
        }
    }, [autoMenus])

    const saveGenericMenu = async () => {
        if(site.get('treenodes') && site.get('treenodes').size > 0) {
            return;
        }
        let menuNodes = List();
        defaultItems.forEach((item, key) => {
            let itemHasCheckedChildren = item.get('children') && item.get('children').filter(child => child.get('checked')).size > 0;   
            if(itemHasCheckedChildren || item.get('checked')) { 
                let nodes = List();
                let quantity = item.get('quantity') || 1;
                let name = quantity > 1 ? item.get('areaName') || item.get('name') : item.get('name');
                for(let i = 0; i < quantity; i++) {
                    let nodeName = name + (quantity > 1 ? ` ${i + 1}` : '');
                    let node = Map({
                        name: nodeName,
                        isV2: true
                    });
                    node = node.set('treenodes', createChildren(item));
                    nodes = nodes.push(node);
                }
                if(item.get('group') && quantity > 1) {
                    let groupNode = Map({
                        name: item.get('name'),
                        isV2: true,
                        treenodes: nodes
                    });
                    menuNodes = menuNodes.push(groupNode);
                } else {
                    menuNodes = menuNodes.concat(nodes);
                }

                
            }
        });
        await saveSiteConfiguration(params.customer, params.site, menuNodes);
        revalidateSite();
        revalidator.revalidate();
        onClose();
    }
    
    return (
        <DialogContent className="md:w-[500px] lg:w-[800px] xl:w-[1140px]"> 
            <DialogHeader>
                <DialogTitle>Generic Menu Creation</DialogTitle>
            </DialogHeader> 
            <DialogBody>
                <div className="flex flex-col gap-3">
                {defaultItems && defaultItems.map((item, key) => {
                    return (
                        <div key={key} className="flex flex-col gap-2">
                            <div className="flex flex-row gap-6 items-center">
                                <Checkbox 
                                    checked={item.get('checked')} 
                                    label={item.get('name')} 
                                    onChange={(v) => {
                                        setDefaultItems(defaultItems.setIn([key, 'checked'], v));
                                    }} />
                                    { item.get('quantity') && <div className="inline-flex items-center gap-2">quantity: <Input containerClassName="!mb-0" type="number" value={item.get('quantity')} onChange={(v) => {
                                        setDefaultItems(defaultItems.setIn([key, 'quantity'], v));
                                    }} /></div>}
                            </div>
                            {item.get('children') && item.get('children').map((child, childKey) => {
                                return (
                                    <div key={key + childKey} className="ml-6 flex flex-row items-center gap-6">
                                        
                                        <Checkbox 
                                            id={key + childKey}
                                            checked={child.get('checked')} 
                                            label={child.get('name')} 
                                            onChange={(v) => {
                                                setDefaultItems(defaultItems.setIn([key, 'children', childKey, 'checked'], v));
                                            }} />
                                            { child.get('quantity') && <div className="inline-flex items-center gap-2">quantity: <Input  
                                                
                                                containerClassName="!mb-0"  
                                                ype="number" 
                                                value={child.get('quantity')} 
                                                onChange={(v) => {
                                                    setDefaultItems(defaultItems.setIn([key, 'children', childKey, 'quantity'], v));
                                                }} /></div>}
                                    </div>
                                )
                            }).toList()}
                        </div>
                    )
                }).toList()}
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="flex p-3 items-center justify-end gap-2">
                    <Button role="primary" onClick={saveGenericMenu}>Save</Button>
                    <Button role="close" className="" onClick={() => onClose()}>Close</Button>
                </div>
            </DialogFooter>
        </DialogContent>

    );
}

export default GenericMenuDialog;

// const initialItemsState = fromJS({
//     "overview": {
//         name: "Overview",
//         type: "area",
//         checked: true,
//     },
//     "boiler-plant": {
//         name: "Boiler Plant",
//         type: "area",
//         checked: true,
//     },
//     "heating-pump": {
//         name: "Heating Pumps",
//         type: "area",
//         checked: true,
//     },
//     "dhws": {
//         name: "DHWS",
//         type: "area",
//         checked: true,
//     },
//     "chiller-plant": {
//         name: "Chiller plant",
//         type: "area",
//         checked: true,
//     },
//     "chiller-pumps": {
//         name: "Chiller Pumps",
//         areaName: "Chiller Pump",
//         type: "area",
//         checked: true,
//     },
//     "ahus": {
//         name: "AHU's",
//         areaName: "AHU",
//         type: "area",
//         quantity: 1,
//         checked: true,
//     },
//     "toilet-extract": { 
//         name: "Toilet Extract",
//         type: "area",
//         checked: true,
//     },
//     "water-tanks": {
//         name: "Water Tanks",
//         type: "area",
//         checked: true,
//     },
//     "generator": {
//         name: "Generator",
//         type: "area",
//         checked: true,
//     },
//     "ventilation": {
//         name: "Ventilation",
//         type: "area",
//         checked: true,
//         children: {
//             "ahus": {
//                 name: "AHU's",
//                 areaName: "AHU",
//                 type: "area",
//                 quantity: 1,
//                 checked: true
//             }
//         }
//     }
    
// })