import { Description, Dialog, DialogTitle } from '@headlessui/react'
import { useEffect, useMemo, useState, Fragment } from 'react'
import UserDefinedButton from 'Controls/Button/UserDefined'
import { OrderedMap } from 'immutable';
import ServiceBus from '../../../../../../../Services/ServiceBus';
import { getPointScheduleType } from '../../../../../Configuration/PointGroupManagement';
import saveToPanel from '../shared/saveToPanel';

function useTimezoneCompleteHandlers({ status, onComplete }) {

    

    useEffect(() => {

        if(status.get('loading')) {

            const setTrendIQ3TimezoneComplete = (message) => {
                onComplete({ error: message.m_errorNumber != 0 });
            }
        
            const setBacnetTimezoneComplete = (message) => {
                onComplete({ error: message.m_errorNumber != 0 });
            }
        
            const setTrendIQ2TimezoneComplete = (message) => {
                onComplete({ error: message.m_errorNumber != 0 });
            }



            ServiceBus.on('508-51', setTrendIQ3TimezoneComplete);
            ServiceBus.on('25-151', setBacnetTimezoneComplete);
            ServiceBus.on('506-51', setTrendIQ2TimezoneComplete);

            return () => {
                ServiceBus.off('508-51', setTrendIQ3TimezoneComplete);
                ServiceBus.off('25-151', setBacnetTimezoneComplete);
                ServiceBus.off('506-51', setTrendIQ2TimezoneComplete);
            }

        }

    }, [status.get('loading')]);


    return null;

}

export default function MultipeTimeZonesConfirmDialog({ 
    opened, 
    onClose, 
    onSaveSingleToPanel, 
    groups,
    customer,
    currentuser,
    events
}) {

   

    


    return <Dialog className="relative z-[1051]" open={opened} onClose={onClose}>
            <Dialog.Backdrop className="fixed  z-[1050] inset-0 bg-black opacity-30" />
           <MultipeTimeZonesConfirmPanel 

                opened={opened}
                onClose={onClose}
                onSaveSingleToPanel={onSaveSingleToPanel}
                groups={groups}
                customer={customer}
                currentuser={currentuser}
                events={events}
              />
    </Dialog>


}

function MultipeTimeZonesConfirmPanel({ 
    opened, 
    onClose, 
    onSaveSingleToPanel, 
    groups,
    customer,
    currentuser,
    events
}) {
    const [status, setStatus] = useState(OrderedMap({
        loading: false,
        
    }));

    const points = useMemo(() => {
        let points = OrderedMap();
        groups.forEach((group) => {
            group.get('points').forEach((point) => {
                points = points.set(point.get('_id'), point);
            })
        });
        return points;
    }, [groups])

    
    const getPin = (subsystemNumber) => {

        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
 
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }

    const processNext = (error) => {
        setStatus((status) => {
            let newStatus = status;
            let processedPoint = newStatus.get('pointsToProcess').first();
            let pointsToProcess = newStatus.get('pointsToProcess').skip(1);

            if(error) {
                newStatus = newStatus.update('pointsFailed', OrderedMap(), x => x.set(processedPoint.get('_id'), processedPoint));
            } else {
                let pointsProcessed = newStatus.get('pointsProcessed').set(processedPoint.get('_id'), processedPoint);
                newStatus = newStatus.set('pointsProcessed', pointsProcessed);
            }
            
            newStatus = newStatus.set('pointsToProcess', pointsToProcess);
            if(pointsToProcess.size == 0) {
                return newStatus.set('loading', false).set('loaded', true);
            }

            let nextPoint = pointsToProcess.first();
            let address = nextPoint.get('addr').toArray();
            let subsystemNumber = address[0];
            saveToPanel({
                customer,
                address: nextPoint.get('addr').toArray(),
                currentuser,
                events,
                pin: getPin(subsystemNumber)
            });

            return newStatus;
            

        })
    }

    const onSaveMultipleToPanel = () => {
        setStatus((status) => {
            let newStatus = status.set('loading', true);
            let pointsToProcess = points;
            let pointsProcessed = OrderedMap();
            let pointsFailed = OrderedMap();
            newStatus = newStatus.set('pointsToProcess', pointsToProcess);
            newStatus = newStatus.set('pointsProcessed', pointsProcessed);
            newStatus = newStatus.set('pointsFailed', pointsFailed);
            return newStatus;
        })
    }

    const onComplete = ({ error }) => {
        processNext(error);
        

    }

    useEffect(() => {

        if(status.get('loading')) {

            
            let nextPoint = status.get('pointsToProcess').first();
            let address = nextPoint.get('addr').toArray();
            let subsystemNumber = address[0];
            saveToPanel({
                customer,
                address: nextPoint.get('addr').toArray(),
                currentuser,
                events,
                pin: getPin(subsystemNumber)
            });
        }
    }, [status.get('loading')])

    

    

    useTimezoneCompleteHandlers({ status, points, onComplete });

    const groupType = opened ? groups.first().get('type') : '';   

    return  <div className="fixed inset-0 flex w-screen  items-center justify-center p-4">
            
        <Dialog.Panel className="">
            <div className="bg-white min-w-96 p-4 rounded-lg flex flex-col gap-4">
                <div className="text-xl">This point belongs to a '{groupType}'' group. 
                    <br />Apply the timezone to all group points?</div>
                    <div>
                    {
                        groups.map((group) => {
                            return <div className="flex flex-col">
                                <div className="font-bold">{group.get('name')}</div>
                                <div>
                                    {group.get('points').map((point) => {
                                        return <div className="px-4">
                                            { (status.get('loading') || status.get('loaded')) && <Fragment>
                                                {status.get('pointsProcessed').has(point.get('_id')) && <span className="text-green-500 px-2">✓</span>}
                                                {status.get('pointsFailed').has(point.get('_id')) && <span className="text-red-500 px-2">✗</span>}
                                                {status.get('pointsToProcess').has(point.get('_id')) && <span className="text-blue-500 px-2">...</span>}
                                            </Fragment>}
                                        
                                            {point.get('name')}
                                        </div>
                                    })}
                                </div>
                            </div>
                        })
                    }

                </div>
                <div className="flex justify-end gap-4 mt-4">
                    { !status.get('loaded') && <Fragment>
                        
                        <UserDefinedButton disabled={status.get('loading')} role="success" onClick={() => {
                            onSaveSingleToPanel()
                            onClose();
                        }}>Apply Only to this Point</UserDefinedButton>
                        <UserDefinedButton disabled={status.get('loading')} role="success" onClick={() => onSaveMultipleToPanel()}>Apply to All Points in Group</UserDefinedButton>
                        <UserDefinedButton disabled={status.get('loading')} role="danger" onClick={onClose}>Cancel</UserDefinedButton>
                    </Fragment> }

                    { status.get('loaded') && <Fragment>
                        <UserDefinedButton role="success" onClick={onClose}>Close</UserDefinedButton>
                    </Fragment> }
                </div>
            </div>
        </Dialog.Panel>
    </div>

}