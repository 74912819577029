import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import ColorPicker from 'Controls/ColorPicker';
import Immutable, {fromJS}from 'immutable';
import Gradient from './Gradient';
import Rules from './Rules';

class Shape extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentvalue: Immutable.Map({})
        };
    }

    valueChanged(field, value) {
        let { onChange } = this.props;
        onChange(field, value);
    }

    render() {
        let { currentvalue } = this.state;
        let { selectedcomponent, onChange } = this.props;

        let shapetype = selectedcomponent.getIn(['configuration','shapetype'], 'rectangle');

        let rotation = selectedcomponent.getIn(['configuration', 'rotation'], 0);
        let grads = fromJS([
            { "stop": "0%", "colour":"#00aad4" },
            { "stop": "100%", "colour":"#004050"}]);

        return (
            <div className="my-4">
                <div className="grid grid-cols-16">
                    <div className="col-span-4">
                        <Select
                            label="Shape Type"
                            value={shapetype + ''}
                            onChange={this.valueChanged.bind(this, ['configuration','shapetype'])}
                            options={Immutable.List([{'value':'rectangle', 'text' :'Rectangle'}, {'value':'circle', 'text' :'Circle'}, {'value':'eqtriangle', 'text' :'Equilateral Triangle'}])}
                        />    
                    </div>
                </div>
                <div className="grid grid-cols-16 gap-2">
                    { shapetype == 'rectangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Height"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'height'], 100)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'height'])}
                        />
                    </div>}
                    { shapetype == 'rectangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'width'], 200)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'width'])}
                        />
                    </div>}
                    { shapetype == 'circle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Radius"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'radius'], 50)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'radius'])}
                        />
                    </div>}
                    { shapetype == 'eqtriangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Side Length"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'sidelength'], 100)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'sidelength'])}
                        />
                    </div>}
                    { shapetype == 'eqtriangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Stroke Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'strokewidth'], 1)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'strokewidth'])}
                        /> 
                    </div>}
                    { shapetype == 'eqtriangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={rotation}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>}
                    { shapetype == 'rectangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Rotate Angle"
                            type="number"
                            value={rotation}
                            onChange={this.valueChanged.bind(this, ['configuration', 'rotation'])}
                        /> 
                    </div>}
                    { shapetype != 'eqtriangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Border Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'borderwidth'], 1)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'borderwidth'])}
                        />
                    </div>}
                    { shapetype == 'rectangle' &&
                    <div className="col-span-12 md:col-span-2 my-2">
                        <Input
                            label="Radius Corner"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'ry'], 0)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'ry'])}
                        />
                    </div>}
                </div>  
                <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'])} label={"Gradient"} gradientField={""}
                    gradients={selectedcomponent.getIn(['configuration', 'grads'])} defaultGrads={grads}
                    onChange={onChange} type={selectedcomponent.getIn(['configuration','gradienttype'], 'simple')}/>
                {selectedcomponent.getIn(['component', 'stateids']) && selectedcomponent.getIn(['component', 'stateids']).get(0) && <Rules selectedcomponent={selectedcomponent} currentValue={currentvalue} onChange={onChange}/>}
                    <div className="grid grid-cols-16 mt-8 gap-2">
                        <div className="col-span-16">Colours</div>
                        <div className="col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'])} /></div>
                        <div className="col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'])} /></div>
                        <div className="col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'])} /></div>
                        <div className="col-span-2"><ColorPicker label="Stripe" value={selectedcomponent.getIn(['configuration', 'style', 'top-border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'top-border-color'])} /></div>
                    </div>
            </div>
            
        )
    }
}

export default Shape;
