import React, { Fragment, useState } from 'react';
import Immutable, { Map } from 'immutable';
import Input from 'Controls/Form/Input';
import Text from './Text';
import TextMultiline from './TextMultiline';
import PointControl from '../PointControl';
import PointListControl from '../PointControl/PointListControl';
import Checkbox from 'Controls/Form/Checkbox';
import EzyButton from './EzyButton';
import Compass from './Compass';
import TextDigital from './TextDigital';
import TextButton from './TextButton';
import CustomTextButton from './CustomTextButton';
import StatusButton from './StatusButton';
import DynamicTable from './DynamicTable';
import Dial from './Dial';
import Gauge from './Gauge';
import Image from './Image';
import Hotspot from './Hotspot';
import Chart from './Chart';
import Report from './Report';
import DateTimeText from './DateTimeText';
import Shape from './Shape';
import Pipe from './Pipe';
import Path from './Path';
import Duct from './Duct';
import SystemObject from './SystemObject';
import Compound from './Compound';
import Line from './Line';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Select from 'Controls/Form/Select';
import { useCustomerSettings } from 'Context/CustomerSettings';
import { useUserSettings } from 'Context/UserSettings';
import systemSettings from 'Configuration/SystemSettings';
import debuggerService from 'Services/DebuggerService';
import { PointControls } from '../PointStatusDialog/AdvancedPointStatus/Command';
import ModuleTypes from 'Configuration/ModuleTypes';

const logger = debuggerService.getDebugger("ComponentConfigurationDialog", "gold");

function ComponentConfigurationDialog({ customer, selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <ComponentConfigurationDialogPanel customer={customer} selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

// default font size types
// ['text','text-multiline','label','striped-text','gauge','date-time-text']

const typesWithDefaultFontSize = ['text','text-multiline','label','striped-text','gauge','date-time-text'];

const ComponentTypes = {
    'animation': Image,
    'text': Text,
    'text-multiline': TextMultiline,
    'label': Text,
    'striped-text': Text,
    'image': Image,
    'image-png': Image,
    'image-jpg': Image,
    'ezybutton': EzyButton,
    'compass': Compass,
    'text-digital': TextDigital,
    'text-button': TextButton,
    'custom-text-button': CustomTextButton,
    'status-button': StatusButton,
    'dynamic-table': DynamicTable,
    'dial': Dial,
    'gauge': Gauge,
    'hotspot': Hotspot,
    'chart': Chart,
    'report': Report,
    'date-time-text': DateTimeText,
    'shape': Shape,
    'pipe': Pipe,
    'path': Path,
    'duct': Duct,
    'systemobject': SystemObject,
    'line': Line,
    'compound': Compound 
}

/*

    Level of specificity for font size
        1. Component configuration
        2. User settings
        3. Customer settings
        4. System settings


*/

export function initializeDefaults({selecteditem, customerSettings, userSettings}) {
    if(!selecteditem) return null;
    logger.log("initializeDefaults", selecteditem);
    logger.log("initializeDefaults checking type", selecteditem.getIn(['component', 'type']));

    if(typesWithDefaultFontSize.indexOf(selecteditem.getIn(['component', 'type'])) > -1) {
        logger.log("initializeDefaults type was successfully loaded");
        let fontSize = systemSettings?.graphics?.fontSize;
        if(customerSettings) {
            fontSize = customerSettings.get('defaultFallbackFontSize', fontSize) || fontSize;
        }
        if(userSettings) {
            fontSize = userSettings.get('defaultFallbackFontSize', fontSize) || fontSize;
        }
        if(selecteditem.getIn(['configuration', 'style', 'fontsize']) === undefined) {
            selecteditem = selecteditem.setIn(['configuration', 'style', 'fontsize'], fontSize);
        }
    }
    logger.log("selecteditem after initializeDefaults", selecteditem);
    return selecteditem;
}


function ComponentConfigurationDialogPanel({ selecteditem, onSave, customer, area, onClose }) {

    const { customerSettings } = useCustomerSettings();
    const { userSettings } = useUserSettings();

    const [selectedcomponent, setSelectedComponent] = useState(initializeDefaults({
        selecteditem: selecteditem,
        customerSettings: customerSettings,
        userSettings: userSettings
    }));
    const [selectedTab, setSelectedTab] = useState('default');

    function valueChanged(field, value) {
        setSelectedComponent((selectedcomponent) => selectedcomponent.setIn(field, value));
    }

    function save() {
        if (onSave) {
            onSave(selectedcomponent);
        }
    }

    function changePoint(address, name) {
        setSelectedComponent((selectedcomponent) => selectedcomponent.setIn(['configuration', 'boundto'], address).setIn(['configuration', 'istooltip'], true).setIn(['configuration', 'tooltip'], name));
    }

    function addPointToList(point) {
        if (!selectedcomponent.getIn(['configuration', 'points'])) {
            setSelectedComponent((selectedcomponent) => selectedcomponent.setIn(['configuration', 'points'], Immutable.List([])));
        }

        let newpoint = Immutable.fromJS({
            label: point.get('name'),
            value: point.get('addr').join('-')
        });

        setSelectedComponent((selectedcomponent) => selectedcomponent.updateIn(['configuration', 'points'], arr => arr.push(newpoint)));
    }

    function removePointFromList(index) {
        setSelectedComponent((selectedcomponent) => selectedcomponent.deleteIn(['configuration', 'points', index]));
    }

    function removePoint() {
        setSelectedComponent((selectedcomponent) => selectedcomponent.deleteIn(['configuration', 'boundto']));
    }

    let CurrentComponent = ComponentTypes[selectedcomponent.getIn(['component', 'type'])];
    if (!CurrentComponent) {
        CurrentComponent = <data></data>;
    }

    let checked = selectedcomponent.getIn(['configuration', 'istooltip'], false);
    let menuChecked = selectedcomponent.getIn(['configuration', 'ismenu'], false);

    let hasPointSelected = false;

    if (selectedcomponent.getIn(['configuration','boundto'])) {
        let addr = selectedcomponent.getIn(['configuration','boundto']).split('-');
        if ( addr[1] == 9 && addr[2] == 17) hasPointSelected = true;
        if ( addr[1] == 5 && addr[2] == 14) hasPointSelected = true }

    let pointReq = true;
    let pointListReq = false;

    if (selectedcomponent.getIn(['component', 'type']) == 'systemobject') pointReq = false;
    if (selectedcomponent.getIn(['component', 'type']) == 'report') {pointListReq = true; pointReq = false;}
    
    let tabs = ['default'];
    if(menuChecked) {
        tabs = ['default', 'menu'];
    }

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle className="w-full">
                    <div className="flex flex-row justify-between">
                        <div>Component Configuration</div> <div className="text-gray-600 text-xl">({selectedcomponent.getIn(['component','description'])})</div>
                    </div>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="w-100 flex flex-col gap-3">
                    { tabs.length > 1 && area?.get('isV2') && <div className="flex flex-row gap-2 border-b border-gray-500">
                        <button className={`p-2 px-4 ${selectedTab != 'default' ? 'bg-gray-100' : 'bg-gray-500 text-white'} rounded-t-lg`} onClick={() => setSelectedTab('default')}>General</button>
                        <button className={`p-2 px-4  ${selectedTab != 'menu' ? 'bg-gray-100' : 'bg-gray-500 text-white'} rounded-t-lg`} onClick={() => setSelectedTab('menu')}>Menu</button>
                    </div> || null} 
                    { selectedTab == 'default' && <div>
                        {pointListReq && <PointListControl pointList={selectedcomponent.getIn(['configuration', 'points'], Immutable.List([]))} onAddPoint={addPointToList} onRemovePoint={removePointFromList} />}
                        {pointReq && <PointControl value={selectedcomponent.getIn(['configuration', 'boundto'])} onChange={changePoint} onRemove={removePoint} />}
                        {hasPointSelected && <DefaultTabSelector value={selectedcomponent.getIn(['configuration', 'defaulttab'], 'advancedpointstatus')} onChange={(v) => valueChanged(['configuration', 'defaulttab'], v)} /> }
                        <CurrentComponent customer={customer} selectedcomponent={selectedcomponent} onChange={valueChanged} />
                        
                        <div className="col-12 mt-4">
                            <Checkbox label="Area opens in a dialog" checked={selectedcomponent.getIn(['configuration', 'isdialog'], false)} onChange={(v) => valueChanged(['configuration', 'isdialog'], v)} />
                        </div>
                        <div className="col-12 mt-2">
                            <Checkbox label="Links to another area" checked={selectedcomponent.getIn(['configuration', 'islink'], false)} onChange={(v) => valueChanged(['configuration', 'islink'], v)} />
                        </div>
                        {selectedcomponent.getIn(['configuration', 'islink']) && <Input label="Link location" value={selectedcomponent.getIn(['configuration', 'linkto'])} onChange={(v) => valueChanged(['configuration', 'linkto'], v)} />}
                        
                       <CommandInput selectedcomponent={selectedcomponent} valueChanged={valueChanged} />
                        { area?.get('isV2') && <div className="col-12 mt-2">
                            <Checkbox label="Menu" key={"ismenu"} checked={menuChecked} onChange={(v) => valueChanged(['configuration', 'ismenu'], v)}/>
                        </div> }
                        <div className="col-12 mt-2">
                            <Checkbox label="Tooltip" key={"is" + checked} checked={checked} onChange={(v) => valueChanged(['configuration', 'istooltip'], v)}/>
                        </div>
                        {selectedcomponent.getIn(['configuration', 'istooltip']) && <Input label="Text" value={selectedcomponent.getIn(['configuration', 'tooltip'])} onChange={(v) => valueChanged(['configuration', 'tooltip'], v)} />}
                    </div> }
                    { selectedTab == 'menu' && <div>

                        <MenuEditPanel selectedcomponent={selectedcomponent} valueChanged={valueChanged} />

                    </div> || null}
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="pl-1"><Button role="secondary" onClick={onClose}>Cancel</Button></div>
                <div className="pl-1"><Button role="primary" onClick={save}>Save</Button></div>
            </DialogFooter>
        </DialogContent>            
    )

}

function CommandInput({ selectedcomponent, valueChanged }) {

    if(!selectedcomponent.getIn(['configuration', 'boundto'])) return null;

    let address = selectedcomponent.getIn(['configuration', 'boundto'], '').split('-');

    let isTrendSwitch = address[1] == 5 && address[2] == 1;
    let isAnalogKnob = address[1] == 9 && address[2] == 2; 
    let isDigitalKnob = address[1] == 9 && address[2] == 5; 

    // console.log("CommandInput address", address);
    let activeModuleType = ModuleTypes[address[1]];
    // console.log("CommandInput activeModuleType", activeModuleType);
    let PointType = activeModuleType.pointtypes[address[2]];
    // console.log("CommandInput PointType", PointType);
    let ActiveControl = PointControls[PointType.control];
    // console.log("CommandInput ActiveControl", ActiveControl);
    let selectedpoint = selectedcomponent;
    // console.log("CommandInput selectedpoint", selectedpoint);

    const isDigital = PointType.engunit == 'digital';

    const changeIsDirectCommand = (v) => {
        valueChanged(['configuration', 'isdirectcommand'], v);
        if(v) {
            valueChanged(['configuration', 'askdirectcommandconfirmation'], true);
        }
    }

    // let ActiveControle = PointControls[];
    // console.log("CommandInput render", selectedcomponent)

    return <Fragment>
        <div className="col-12 mt-2">
            <Checkbox label="Direct command" checked={selectedcomponent.getIn(['configuration', 'isdirectcommand'], false)} onChange={(v) => changeIsDirectCommand(v)} />
        </div>
        {
            selectedcomponent.getIn(['configuration', 'isdirectcommand']) && <Fragment>
            { isDigital && <div>
                Point value will be toggled    
            </div>}
            {!isDigital && <Input 
                label="Command Value" 
                value={selectedcomponent.getIn(['configuration', 'directcommandvalue'])} 
                onChange={(v) => valueChanged(['configuration', 'directcommandvalue'], v)} /> }

                </Fragment>
        }
        {selectedcomponent.getIn(['configuration', 'isdirectcommand'], false) && <div className="col-12 mt-2">
            <Checkbox label="Ask for confirmation before executing direct command" checked={selectedcomponent.getIn(['configuration', 'askdirectcommandconfirmation'], false)} onChange={(v) => valueChanged(['configuration', 'askdirectcommandconfirmation'], v)} />
        </div>} 
         <div className="col-12 mt-2">
            <Checkbox label="Override report" checked={selectedcomponent.getIn(['configuration', 'overridereport'], false)} onChange={(v) => valueChanged(['configuration', 'overridereport'], v)} />
        </div>
    </Fragment>

}

export default ComponentConfigurationDialog;


function MenuEditPanel({ selectedcomponent, valueChanged }) {

    let [newMenuItem, setNewMenuItem] = useState(Map({ label: '', linkto: '' }))
    let currentMenuItems = selectedcomponent.getIn(['configuration', 'menu'], Immutable.List([]));

    function addMenuItem() {
        valueChanged(['configuration', 'menu'], currentMenuItems.push(newMenuItem));
        setNewMenuItem(Map({ label: '', linkto: '' }));
    }

    // console.log("currentMenuItems", currentMenuItems);

    return <div>
        <table className="border-separate border-spacing-3 align-middle" >
            <thead>
                <tr><th>Label</th><th>Linkto</th><th>Dialog?</th><th></th></tr>
            </thead>
            <tbody>
                {currentMenuItems.map((menuItem, index) => {

                    // console.log("menuItem", menuItem);

                    return <tr key={index}>
                        <td className="align-middle"><Input containerClassName="mb-0" placeholder="Label" value={menuItem.get('label')} onChange={(value) => valueChanged(['configuration', 'menu', index, 'label'], value)} /></td>
                        <td className="align-middle"><Input containerClassName="mb-0" placeholder="Link to" value={menuItem.get('linkto')} onChange={(value) => valueChanged(['configuration', 'menu', index, 'linkto'], value)} /></td>
                        <td className="align-middle"> 
                            <Checkbox checked={menuItem.get('isdialog', false)} onChange={(value) => valueChanged(['configuration', 'menu', index, 'isdialog'], value)} />
                        </td>
                        <td className="align-middle"><Button className="mb-0" role="danger" onClick={() => valueChanged(['configuration', 'menu'], currentMenuItems.delete(index))}>Remove</Button></td>
                    </tr>
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td className="align-middle"><Input containerClassName="mb-0" placeholder="Label" value={newMenuItem.get('label')} onChange={(value) => setNewMenuItem(newMenuItem.set('label', value))} /></td>    
                    <td className="align-middle"><Input containerClassName="mb-0" placeholder="Link to" value={newMenuItem.get('linkto')} onChange={(value) => setNewMenuItem(newMenuItem.set('linkto', value))} /></td>
                    <td className="align-middle">
                        <Checkbox checked={newMenuItem.get('isdialog', false)} onChange={(value) => setNewMenuItem(newMenuItem.set('isdialog', value))} />
                    </td>
                    <td className="align-middle"><Button className="block h-min" role="success" onClick={addMenuItem}>Add</Button></td>
                </tr>
            </tfoot>
        </table>
    </div>
}


function DefaultTabSelector({ value, onChange }) {

    return <div className="flex flex-row items-center gap-3">
            <div className="">Default tab when accessing point status:</div>
            <div>
                <Select value={value} onChange={onChange} containerClassName="mb-0" options={[
                { text: 'Point Status', value: 'advancedpointstatus' },
                { text: 'Asset', value: 'asset' },
                { text: 'Histories', value: 'trending' },
                { text: 'Logging', value: 'logging' },
                { text: 'Timezone', value: 'timezone' },
                { text: 'Exceptions', value: 'timezoneexceptions' },
                { text: 'Calibration', value: 'calibration' }
                
            ]} />
            </div>
    </div>

}