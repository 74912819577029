import React, {useState} from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';


const ControllerSelection = ({scan}) => {
    const [primaryController, setPrimaryController] = useState(0);
    const [primaryControllerType, setPrimaryControllerType] = useState(-1);

    const primaryControllerTypes = ['BOILER',
    'AHU',
    'ZONE',
    'FANCOIL',
    'HEATSOURCE',
    'COOLSOURCE']

    return (
        <div className="container-fluid">
            <div className="grid grid-cols-16 gap-2">
                <div className="col-span-16 md:col-span-6">
                    <Input label="Primary Controller" value={primaryController} onChange={(value) => setPrimaryController(value)} />
                </div>
                <div className="col-span-16 md:col-span-6">
                    <Select label="Type"
                        value={primaryControllerType}
                        onChange={value => setPrimaryControllerType(parseInt(value))}
                        options={[{ name: "Select the type.",value: -1 }, ...primaryControllerTypes.map((type, index) => {
                            return {
                                text: type,
                                value: index
                            }
                        })]}
                    />
                </div>
            </div>
            <div className="pl-1"><Button role="success" onClick={() => scan('scanforpoints',primaryController, primaryControllerType )}>Scan</Button></div>
        </div>
    )
}

export default ControllerSelection;



