import React, {  useEffect, useRef, useState, useMemo, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import * as d3 from 'd3';
import { ImageFactory, PointList, Text, Image, LegacyBackdropLayer, EzyButton, Compass, TextDigital, TextButton, CustomTextButton, StatusButton, DynamicTable, Dial, Hotspot, Chart, Report, DateTimeText, TextMultiline, Shape, Pipe, Path, SystemObject, Gauge, Duct, Line } from './SVGComponents';
import { withClipboard } from '../../../../../Context/Clipboard';
import { useSubscription } from '../../../../../Context/AreaPoint';
import { useCustomerSettings } from '../../../../../Context/CustomerSettings';
import { useUserSettings } from '../../../../../Context/UserSettings';
import ModuleTypes from 'Configuration/ModuleTypes';
import { connect, useSelector } from 'react-redux';
import SelectionBox from './SelectionBox';
import StatusBar from './StatusBar';
import { useBMS } from 'BmsView/Context/BMS';
import { List, fromJS } from 'immutable';
import ContextMenu from '../../../../../Controls/ContextMenu/Portal';
import { useNavigate } from 'react-router-dom';
import debuggerService from '../../../../../Services/DebuggerService';
import useAreaCustomer from '../Hooks/useAreaCustomer';
import useAreaSite from '../Hooks/useAreaSite';
import Swal from 'sweetalert2';
import ServiceBus from 'Services/ServiceBus';
import { toast } from 'react-toastify';

const logger = debuggerService.getDebugger("SVGComponent", "orange");


export default SVGComponent;


// could be reused
function transformXYOnElement(element, dx, dy) {
    let transform = element.attr('transform');
    let transformMatch = transform.match(/matrix\((?<a>[\-\s\d\.]+),(?<b>[\-\s\d\.]+),(?<c>[\-\s\d\.]+),(?<d>[\-\s\d\.]+),(?<tx>[\-\s\d\.]+),(?<ty>[\-\s\d\.]+)?\)/)
    let rotationMatch = transform.match(/rotate\((?<r>[\-\s\d\.]+)?\)/)
    if(!transformMatch) {
        transformMatch = transform.match(/translate\((?<tx>[\-\s\d\.]+),(?<ty>[\-\s\d\.]+)?\)/);
    }
    let { groups } = transformMatch;
    let { tx, ty } = groups;
    let currentX = Number(tx);
    let currentY = Number(ty);
    let boxX = currentX + dx;
    let boxY = currentY + dy;
    if(transform.match(/matrix/)) {
        if(rotationMatch) {
            element.attr('transform', `matrix(${groups.a},${groups.b},${groups.c},${groups.d},${boxX}, ${boxY})rotate(${rotationMatch.groups.r})`);
        } else {
            element.attr('transform', `matrix(${groups.a},${groups.b},${groups.c},${groups.d},${boxX}, ${boxY})`);
        }
   } else {
        if(rotationMatch) {
            element.attr('transform', `translate(${boxX}, ${boxY})rotate(${rotationMatch.groups.r})`);
        } else {
            element.attr('transform', `translate(${boxX}, ${boxY})`);
        }
   }
   return {
       x: boxX, y: boxY
   }
}

// could be reused
function createTooltip() {
    let tooltip=d3.select("#tooltip");
    if (tooltip._groups[0] == 0) {
        tooltip = d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .style('z-index', '10000')
        .style('position', 'absolute')
        .style('visibility','hidden')
        .style('border-width', '2px')
        .style('border_color', 'grey')
        .style('border-style', 'ridge')
        .style('padding', '2px')
        .style('font-size', '12px')
    }
    return tooltip;
}

// could be reused
const svgComponentConfigurations = {
    'duct': {
        length: true,
        height: true,
        rotation: true,
        defaultX: () => 400,
        defaultY: () => 85
    },
    'image-png': {
        length: true,
        height: true,
        rotation: true,
        defaultX: (imageHandle) => imageHandle.getImageData().width,
        defaultY: (imageHandle) => imageHandle.getImageData().height
    },
    'image-jpg': {
        length: true,
        height: true,
        rotation: true,
        defaultX: (imageHandle) => imageHandle.getImageData().width,
        defaultY: (imageHandle) => imageHandle.getImageData().height
    },
    
    
}

// could be reused
const svgComponents = {
    'label': Text,
    'animation': Image,
    'striped-text': Text,
    'pointlist': PointList,
    'text': Text,
    'image': Image,
    'image-png': ImageFactory('png'),
    'image-jpg': ImageFactory('jpg'),
    'layer': LegacyBackdropLayer,
    'ezybutton': EzyButton,
    'compass': Compass,
    'text-digital': TextDigital,
    'text-button': TextButton,
    'custom-text-button': CustomTextButton,
    'text-multiline': TextMultiline,
    'status-button': StatusButton,
    'dynamic-table': DynamicTable,
    'dial': Dial,
    'hotspot': Hotspot,
    'chart': Chart,
    'report': Report,
    'date-time-text': DateTimeText,
    'shape': Shape,
    'pipe': Pipe,
    'path': Path,
    'systemobject': SystemObject,
    'gauge': Gauge,
    'duct': Duct,
    'line': Line
}

function SVGComponent({ pointUpdated, openAreaDialog, node, editmode, layerIndex, nodeIndex, selectionmode, scale, onNodeChange, onNodeConfiguration, onShowContextMenu, toggleSelection, toggleSingleSelection, selection, mouseMovement, setMouseMovement, onMultiDragFinished, onNodeMount }) {

    // const customer = useSelector(state => state.get('customer'));
    // const site = useSelector(state => state.get('site'));

    // console.log("111 SVG component render")

    

    const [contextMenu, setContextMenu] = useState(null);
    const navigate = useNavigate();

    const { customer } = useAreaCustomer();
    const { site } = useAreaSite();
    const { currentUser:currentuser } = useBMS();   

    const helpers = useRef(null);

    const {userSettings} = useUserSettings();
    const {customerSettings} = useCustomerSettings();

    const componentSelectionBoxConfiguration = svgComponentConfigurations[node.getIn(['component', 'type'])]



    helpers.current = {
        getCustomer: () => customer,
        getCurrentUser: () => currentuser,
        getCustomerSettings: () => {
            return customerSettings;
        },
        getUserSettings: () => {
            return userSettings;
        }
    }

    const getCustomerSettings = (key) => {
        return (helpers.current.getCustomerSettings() || Map()).get(key);
    }

    const getUserSettings = (key) => {
        return (helpers.current.getUserSettings() || Map()).get(key);
    }

    const siteBasePath = useMemo(() => `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`, [customer, site]);

    const subscription = useSubscription();
    const svgContainerRef = useRef(null);
    const componentRef = useRef(null);
    const handleRef = useRef(null);
    const selectionRef = useRef(null);
    const statusRef = useRef(null);
    const dragRef = useRef({ x: 0, y: 0 });
  
    // console.log("SVG component value of subscription");

    const registerSubscribers = (editmode) => {
        if(editmode) {
            return {
                subscriptions: [],
                abortController: new AbortController()
            }
        }
     
        let subsciptions = [];
        let abortController = new AbortController();
        if ( node && node.getIn(['configuration', 'boundto']) && node.getIn(['component', 'type']) != 'chart' && node.getIn(['component', 'type']) != 'report') {
            let address = node.getIn(['configuration', 'boundto']);
                let pointSubscription = subscription.subscribe(address, (value) => {
                    // console.log("getting point")
                    if(handleRef?.current && handleRef?.current?.setValue) {
                        handleRef.current.setValue(value);
                    } else {
                        // console.log("28-06-2023 subscription reports empty handleRef")
                    }
                    
                }, abortController);
                subsciptions.push(pointSubscription);

            // }
        }
        if (node && node.getIn(['configuration', 'points'])) {
            node.getIn(['configuration', 'points']).forEach((point) => {
                let address = point.get('value');
                    let pointSubscription = subscription.subscribe(address, (value) => {
                        if(handleRef?.current && handleRef?.current?.setValue) {
                            handleRef.current.setValue(value);
                        }
                    }, abortController);
                    subsciptions.push(pointSubscription);
                // }
            });
        }

        return {
            subscriptions: subsciptions,
            abortController: abortController

        }
    }

    useEffect(() => {
        if(!subscription) {
            return () => {}
        }
        let { subscriptions, abortController } = registerSubscribers(editmode);
        return () => {
            subscriptions.forEach((unsubscribe) => {
                unsubscribe();
            });
            abortController.abort();
        }
    }, [node, subscription, editmode])

    const onSelection = (_node, shiftKeyPressed) => {
        // console.log("onSelection");
        toggleSelection(node.get('id'), shiftKeyPressed);
    }

    const onSingleSelection = (_node, shiftKeyPressed) => {
        // console.log("onSinleSelection");
        toggleSingleSelection(node.get('id'), shiftKeyPressed);
    }

    helpers.current.onSelection = onSelection;
    helpers.current.onSingleSelection = onSingleSelection;

    const onResize = (dx, dy, isLeft, isTop) => {
        const cSBC = componentSelectionBoxConfiguration;
        const defaultX = cSBC.defaultX ? cSBC.defaultX(handleRef.current) : 400;
        const defaultY = cSBC.defaultY ? cSBC.defaultY(handleRef.current) : 85;
        let customNode = node;
        if(isTop) {
            customNode = customNode.updateIn(['position', 'y'], y => (y || 0) + dy);
            customNode = customNode.updateIn(['configuration', 'height'], x => (x || defaultY) - dy)
        } else {
            customNode = customNode.updateIn(['configuration', 'height'], x => (x || defaultY) + dy)
        }
        if(isLeft) {
            customNode = customNode.updateIn(['configuration', 'length'], x => (x || defaultX) - dx)
            customNode = customNode.updateIn(['position', 'x'], x => (x || 0) + dx)
        }
        else {
            customNode = customNode.updateIn(['configuration', 'length'], x => (x || defaultX) + dx)
        }
        handleRef.current.redrawElement(customNode, selectionmode);
    }

    const onResizeEnd = (dx, dy, isLeft, isTop) => {
        const cSBC = componentSelectionBoxConfiguration;
        const defaultX = cSBC.defaultX ? cSBC.defaultX(handleRef.current) : 400;
        const defaultY = cSBC.defaultY ? cSBC.defaultY(handleRef.current) : 85;
        let changedNode = node;
        if(isTop) {
            changedNode = changedNode.updateIn(['position', 'y'], y => (y || 0) + dy);
            changedNode = changedNode.updateIn(['configuration', 'height'], x => (x || defaultY) - dy)
        } else {
            changedNode = changedNode.updateIn(['configuration', 'height'], x => (x || defaultY) + dy)
        }
        if(isLeft) {
            changedNode = changedNode.updateIn(['configuration', 'length'], x => (x || defaultX) - dx)
            changedNode = changedNode.updateIn(['position', 'x'], x => (x || 0) + dx)
        }
        else {
            changedNode = changedNode.updateIn(['configuration', 'length'], x => (x || defaultX) + dx)
        }
        onNodeChange(layerIndex, changedNode);
    }

    const temporaryHideSelectionBox = (show = false) => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if(isSelected && !selectionmode && selectionRef.current) {
            if(show) {
                selectionRef.current.style.display = 'block';
            } else {
                selectionRef.current.style.display = 'none';
            }
        }
    }

    helpers.current.temporaryHideSelectionBox = temporaryHideSelectionBox;

    const onSingleDrag = (e, element) => {
        let subjectElement = d3.select(element);
        let dx = e.dx;
        let dy = e.dy;
        let { x, y } = dragRef.current;
        dragRef.current = { x: dx + x , y: dy + y }
        transformXYOnElement(subjectElement, dx, dy);
    }

    const onMultiDrag = ({ dx, dy }) => {
        let { x, y } = dragRef.current;
        dragRef.current = { x: dx + x , y: dy + y }
        setMouseMovement(({ x, y }) => ( { x: dx, y:dy} ))
        
    }

    const onSingleDragEnd = (e,element) => {
        helpers.current.temporaryHideSelectionBox(true);
        let { x: dx, y: dy } = dragRef.current;
        if(dx == 0 && dy == 0) return;
        let changedNode = node.updateIn(['position', 'x'], 0, (x) => x + dx ).updateIn(['position', 'y'], 0, y => y + dy);
        onNodeChange(layerIndex, changedNode);
        dragRef.current = { x: 0, y: 0 }
    }

    const onMultiDragEnd = ({ dx, dy, sourceEvent: { offsetX, offsetY} }) => {
        let { x, y } = dragRef.current;
        onMultiDragFinished(x, y);
        dragRef.current = { x: 0, y: 0 }

    }

    useEffect(() => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if(!handleRef.current || !componentRef.current) return;
        if(!mouseMovement || !isSelected) return;
        let dx = mouseMovement.x;
        let dy = mouseMovement.y;
        let _dx = dx < 0 ? (dx - (1 - scale) * dx) : (dx + (1 - scale) * dx);
        let _dy = dy < 0 ? (dy - (1 - scale) * dy) : (dy + (1 - scale) * dy);        
        let subjectElement = d3.select(componentRef.current);
        subjectElement = handleRef.current.getElement();
        transformXYOnElement(subjectElement, _dx, _dy);
    }, [mouseMovement.x, mouseMovement.y])

    useEffect(() => {
        drawSelectionOnElement();
    }, [selection, selection.selectionIds, selection.singleSelection])

    const drawSelectionOnElement = () => {
        let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
        if (isSelected && !selectionmode) {
            componentRef.current.classList.add('node-selected');
            
            if(!selectionmode && componentSelectionBoxConfiguration) {
                let {x,y,width,height} = componentRef.current.getBoundingClientRect();
                d3.select(selectionRef.current)
                .style('display', 'block')
                .style('top', (y - 10) + 'px')
                .style('left', (x - 10) + 'px')
                .style('width', (width + 20) + 'px')
                .style('height', (height + 20) + 'px')
            }
        } else if(isSelected && selectionmode) {
            componentRef.current.classList.add('node-selected');
        } else {
            if(componentRef.current.classList.contains('node-selected')) {
                componentRef.current.classList.remove('node-selected');
                if(selectionRef.current) {
                    d3.select(selectionRef.current)
                        .style('display', 'none')
                }
            }
            
        }
    }

    

    useEffect(() => {

        const processCommandPointConfirmation = (message) => {
            let address = node.getIn(['configuration', 'boundto']).split('-');
            let messageAddress = message.m_pointAddr;

            if(messageAddress && address.every((value, index) => { return value == messageAddress[index] })) {
                if (message.m_errorNumber != 0) {
                    toast.success('Error commanding point. Error :' + message.m_errorNumber, { autoClose: 2000 });
                    return;
                }
                console.log("it involves this node");
                pointUpdated(node);
                toast.success('Point successfully commanded', { autoClose: 2000 });
            }
          

            // console.log("node", node);
            // console.log("processCommandPointConfirmation", message);
        }

        if(node && node.getIn(['configuration', 'isdirectcommand'])) {
            ServiceBus.on('301-11', processCommandPointConfirmation);
            return () => {
                ServiceBus.off('301-11', processCommandPointConfirmation);
            }
        }


    }, [node])

    const commandPoint = (selectedpoint, type, value, priority) => {
        let customer = helpers.current.getCustomer();
        let currentuser = helpers.current.getCurrentUser();
        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');
        let subsystemNumber = address.map((value) => { return parseInt(value); })[0];
        if (type >= 2){
            value = parseFloat(value);
        }
        else{
            type = value;
        }
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: type,
            m_priority: priority, //|| getPin(subsystemNumber), <= is this necessary?, just commented it out
            m_value: value,
            m_engUnitGuarded: 0,
            m_oldValue: selectedpoint.getIn(['pointconfiguration', 'm_value'])
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const onDirectCommandClick = () => {
        // console.log("direct command clicked");
        let address = node.getIn(['configuration', 'boundto'], '').split('-');


        let isTrendSwitch = address[1] == 5 && address[2] == 1;
        let isAnalogKnob = address[1] == 9 && address[2] == 2; 
        let isDigitalKnob = address[1] == 9 && address[2] == 5; 

        let pointValues = subscription.getLastValue(node.getIn(['configuration', 'boundto']));
        // console.log("pointValues", pointValues);
        if(pointValues) {
            node = node.set('pointconfiguration', fromJS(pointValues.pointconfiguration))
            .set('rawvalue', pointValues.pointconfiguration.m_value)
            .set('currentvalue', pointValues.value)
            .set('currentalarmstate', pointValues.pointconfiguration.m_formattedAlarmText)
            .set('lastcommanded', pointValues.pointconfiguration.m_whoCommanded)
            .set('pointname', pointValues.pointconfiguration.m_pointName)
            .set('error', pointValues.pointconfiguration.m_errorNumber)
        }

        let digitalStatusTexts = pointValues?.pointconfiguration?.m_stateText;

        if(!digitalStatusTexts) {
            digitalStatusTexts = ['Off', 'On'];
        }
    

        let activeModuleType = ModuleTypes[address[1]];
        let PointType = activeModuleType.pointtypes[address[2]];

        const isDigital = PointType.engunit == 'digital';

        let texts = node.getIn(['pointconfiguration', 'm_stateText'], List([])); //.toJS();
        let currentValue = node.getIn(['pointconfiguration', 'm_value']);
        let changeText = ``;
        let value;
        if(isDigital) {
            let [falseText,trueText] = digitalStatusTexts;
            value = currentValue == 1 ? 0 : 1;
            changeText = currentValue == 1 ? `${trueText} to ${falseText}` : `${falseText} to ${trueText}`;
        } else {
            value = node.getIn(['configuration', 'directcommandvalue']);
            changeText = `${currentValue} to ${value}`;
        }

        let pointName = node.getIn(['configuration', 'tooltip']);
        let digitalMessage = `Are you sure you want to adjust ${pointName} from ${changeText}?`
        let type = isDigital ? 1 : 2;
        if(node.getIn(['configuration', 'askdirectcommandconfirmation'])) {
            Swal.fire({
                title: `Are you sure?`,
                text: digitalMessage,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(({ isConfirmed }) => {
                if(isConfirmed) {
                    commandPoint(node, type, value, 8)
                }
            })
        } else {
            commandPoint(node, type, value, 8)
        }
        return;
    }

    // this can be reused maybe in the form a function to be called to include its local dependencies
    const createElement = async (node, nodeIndex, layerIndex, abortSignal) => {
        let svgComponent = svgComponents[node.getIn(['component', 'type'])];
        let index = node.get('id');
        node = convertNodeTypeLabelToTypeText(node);
        node = convertNodeTypeStripedTextToTypeText(node);
        let path = undefined;
        let handle = undefined;
        if (node.getIn(['component', 'name']).indexOf('~') == 0) {
            path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.svg`;
        }
        if (node.getIn(['component', 'name']).indexOf('~') == 0 && node.getIn(['component', 'type']) == 'image-png') {
            
            path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.png`;
        }
        if (node.getIn(['component', 'name']).indexOf('~') == 0 && node.getIn(['component', 'type']) == 'image-jpg') {
            
            path = `${siteBasePath}_components_${node.getIn(['component', 'name']).replace('~', '')}.jpg`;
        }
        if(node.getIn(['component','type']) == 'layer') {
            // console.log("found a layer component")
            path = node.getIn(['url']);
            // console.log("path", path);
        }

        if (svgComponent) {
            let nodeX = node.getIn(['position', 'x']);
            let nodeY = node.getIn(['position', 'y']);
            let newPositionedNode = node;
            let tooltip = createTooltip();
            tooltip.style('visibility', 'hidden')
            newPositionedNode = newPositionedNode.setIn(['component', 'layer'], 'layer' + layerIndex);
            if(handleRef.current) {
                handleRef.current.remove();
                handleRef.current = null;
            }
            handle = await svgComponent({
                getCustomerSettings: getCustomerSettings,
                getUserSettings: getUserSettings,
                node: newPositionedNode,
                target: componentRef.current,
                index: index,
                editmode: editmode,
                tooltip: tooltip
            }, path);

            if(abortSignal.aborted) {
                handle.remove();
            } else {
                let { width, height, y, x } = handle.getElement().node().getBBox({ stroke: true });
                let bound = handle.getElement().node().getBoundingClientRect();
                onNodeMount(node.get('id'), { bound: bound, width, height, y, x, positionX: nodeX, positionY: nodeY });
                if(editmode) {
                    handle.getElement()
                    .on('contextmenu', (evt) => {
                        if(!selectionmode) {
                            onShowContextMenu(evt, node, nodeIndex);
                        }
                    })
                    .on('.drag', null)
                    .call(d3.drag().on('start', function () {
                        if(!selectionmode) {
                           
                            d3.select(this).raise();
                            helpers.current.temporaryHideSelectionBox();
                        }
                    }).on('drag', function (evt) {
                        if(selectionmode) {
                            onMultiDrag(evt, this);
                        } else {
                            onSingleDrag(evt, this);
                        }
                    }).on('end', function (evt) {
                        if(selectionmode) {
                            onMultiDragEnd(evt, this);
                        } else {
                            onSingleDragEnd(evt, this);
                        }
                    }))
                    .on('click', (evt) => {
                        if(selectionmode) {
                            helpers.current.onSelection(node, evt.shiftKey);
                            // onSelection(node, evt.shiftKey);
                            evt.preventDefault();
                            evt.stopPropagation();
                        } else {
                            helpers.current.onSingleSelection(node, evt.shiftKey);
                            // onSingleSelection(node, evt.shiftKey);
                            evt.preventDefault();
                            evt.stopPropagation();
                        }
                    })
                } else {
                    let d3Selection = d3.select(componentRef.current).on('click', (evt) => {

                        // check is direct command here
                        if(node.getIn(['configuration', 'isdirectcommand'])) {
                            onDirectCommandClick();
                        } else {
                            onNodeConfiguration(nodeIndex, node);
                        }

                        
                    });
                    if(node.getIn(['configuration', 'ismenu']) && (node.getIn(['configuration','menu'], List()) || List()).size > 0) {
                        d3Selection.on('contextmenu', (evt) => {
                            evt.preventDefault();
                            // console.log("context menu requested on this element");
                            setContextMenu({ x: evt.clientX, y: evt.clientY });
                        })
                        
                    }
                 
                    
                    d3Selection.on(".drag", null);
                }
            }
        }
        return {
            handle: handle,
            element: svgContainerRef.current
        }
    }

    useEffect(() => {
        let abortSignal = { aborted: false }
        if(node) {
            if(handleRef.current) {
                handleRef.current.remove();
                handleRef.current = null;
            }
            (async (abortSignal) => {
                let { handle, element } = await createElement(node, nodeIndex, layerIndex, abortSignal);
                if(handle) {
                    handleRef.current = handle;
                } 
            })(abortSignal);
        }

        
        return () => {
            abortSignal.aborted = true;
            if(componentRef.current) {
                componentRef.current.innerHTML = '';
            }
            if(handleRef.current) {
                handleRef.current.remove();
                handleRef.current = null;
            }
        }
    }, [node, selectionmode, editmode])

    useEffect(() => {
        if(handleRef.current) {
            let isSelected = (node.get('id') == selection.singleSelection) || (selectionmode && selection.selectedIds.indexOf(node.get('id')) > -1);
            handleRef.current.redrawElement(node, selectionmode);
        }
    }, [userSettings, customerSettings])

    const onMouseOver = (e) => {
        if(editmode && statusRef.current) {
            statusRef.current.classList.remove('hidden');
        }
    }

    const onMouseOut = (e) => {
        if(editmode && statusRef.current) {
            statusRef.current.classList.add('hidden');
        }
    }

    const onContextMenuClick = (menu) => {
        // setContextMenu(() => {}
        if(menu.get('linkto')) {
            if(menu.get('isdialog')) {
                setContextMenu(null);
                openAreaDialog(menu.get('linkto'));
            } else {
                navigate(menu.get('linkto'));
            }
            
        }
        
    }

    return <g ref={componentRef} className="pointer-events-auto" onMouseOver={onMouseOver} onMouseLeave={onMouseOut}>
        { !editmode && node.getIn(['configuration', 'ismenu']) && contextMenu && <ContextMenu contextMenu={contextMenu} onClose={() => setContextMenu(null)}>
            {node.getIn(['configuration', 'menu'], List()).map((menu, index) => {
                return <ContextMenu.Item key={index} onClick={() => onContextMenuClick(menu)}>{menu.get('label')}</ContextMenu.Item>
            }
            )}


        </ContextMenu> }
      { componentSelectionBoxConfiguration && <SelectionBox 
        configuration={componentSelectionBoxConfiguration} 
        ref={selectionRef} 
        onResize={onResize} 
        onResizeEnd={onResizeEnd} 
        node={node} /> }
        { editmode && node && node.getIn(['configuration','boundto']) && <StatusBar ref={statusRef}>{pointAddrToText(node.getIn(['configuration','boundto']))}</StatusBar>}
    </g>
}

function convertNodeTypeLabelToTypeText(node) {
    if (node.getIn(['component', 'type']) == 'label') {
        node = node.setIn(['component', 'type'], 'text');
        node = node.setIn(['component', 'name'], 'text');
        node = node.setIn(['configuration','displaystyle'], 'boxed');
    }
    return node;
}

function convertNodeTypeStripedTextToTypeText(node) {
    if (node.getIn(['component', 'type']) == 'striped-text') {
        node = node.setIn(['component', 'type'], 'text');
        node = node.setIn(['component', 'name'], 'text');
        node = node.setIn(['configuration','displaystyle'], 'striped');
    }
    return node;
}


function  pointAddrToText(_addr) {
        let addr = _addr.split('-');

        let selectedSubsystemType = ModuleTypes[addr[1]];
        let pointtypes = selectedSubsystemType.pointtypes;
        let pointtype = pointtypes[addr[2]];
        let fields = pointtype.fields;

        if ( selectedSubsystemType == 9 ) return `${fields[0].label} ${addr[3]} ${pointtype.name} ${addr[5]}`

        return `${fields[0].label} ${addr[3]} ${fields[1].label} ${addr[4]} ${pointtype.name} ${addr[5]}`          
}