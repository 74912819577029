
import { getPointNumber } from '../utils';
import ServiceBus from 'Services/ServiceBus';

export default function requestTimezones({ customer, address }) {
    let addressAsInt = address.map((byte) => parseInt(byte));

    let request = {
        trackMessage: true,
        m_subsystemNumber: addressAsInt[0],
        databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        target: 'signalr'
    }

    if (address[1] == 5 && address[2] == 4) {
        //IQ2 timezone
        request.m_level = 50;
        request.m_communicNum = 505;

    }
    if (address[1] == 5 && address[2] == 12) {
        //IQ3 timezone
        request.m_level = 50;
        request.m_communicNum = 507;
    }

    if (address[1] == 9 && address[2] == 17) {
        //Bacnet timezone
        // console.log("Trying to retrieve bacnet timezone");
        request.m_level = 150;
        request.m_communicNum = 24;
        
    }

    if (address[1] == 11) {
        //UCC4 timezone
        request.m_level = 120;
        request.m_communicNum = 0;
    }

    let comm = {};

    if (address[1] == 5) {
        comm.m_lanIndex = addressAsInt[3];
        comm.m_outstationNumber = addressAsInt[4];
        comm.m_zoneNumber = getPointNumber(addressAsInt);
    }

    if (address[1] == 11) {
        comm.m_pointAddr = address.map((value) => { return parseInt(value); });
    }

    if(address[1] == 9 && address[2] == 17) {
        comm.m_pointAddr = address.map((value) => { return parseInt(value); });
    }

    // console.log("request", request);

    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
}