import React from 'react';
import Input from 'Controls/Form/Input';
import Select from 'Controls/Form/Select';
import Button from 'Controls/Button/UserDefined';
import classnames from 'classnames';
import swal from 'sweetalert2';
import Immutable from 'immutable';

class Analog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            priority:8,
            value:0
        }
    }
    
    componentDidMount() {
        let { value, mode, priority } = this.props;

        this.setState({
            value: value,
            mode: mode,
            priority: priority || 8
        });
    }

    onChange(value){
        this.setState({
            value: parseFloat(value || 0) || 0
        });
    }

    onChangeMode(){
        let { onChangeMode, mode } = this.props;

        if (onChangeMode) {
            onChangeMode(mode == 1 ? 0 : 1, mode == 1 ? 0 : 1);
        }
    }
    
    onChangePriority(priority){
        this.setState({
            priority:priority
        });
    }
    
    commitChange(){
        let {value, priority} = this.state;
        let {selectedpoint} = this.props;

        if ( selectedpoint.getIn(['pointconfiguration', 'm_botV'])) {
            let fValue = parseFloat(value);
            let fValueBot = parseFloat(selectedpoint.getIn(['pointconfiguration', 'm_botV']));

            if (selectedpoint.getIn(['pointconfiguration', 'm_topV'])) {
                let fValueTop = parseFloat(selectedpoint.getIn(['pointconfiguration', 'm_topV']));

                if ( fValue < fValueBot || fValue > fValueTop ) {
                    swal.fire("Error", "Out of range", "Error");
                    return;
                }
            }
        }
        
        let {onChange} = this.props;

        onChange(2, value, priority);
    }

    setRelease() {
        let {value, priority} = this.state;
        let {onChange} = this.props;

        onChange(3, value, priority);
    }

    render() {
        let {value, priority} = this.state;
        let {mode, selectedpoint, onShowPointPriorities } = this.props;
        let modes = ["Auto", "Manual"];
        let texts = undefined;

        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');

        let role = !value ? 'success' : 'secondary';
            
        if (selectedpoint.getIn(['pointconfiguration', 'm_stateText']) && selectedpoint.getIn(['pointconfiguration', 'm_stateText']).size > 1) {           
            texts = selectedpoint.getIn(['pointconfiguration', 'm_stateText'], Immutable.List([])).toJS();
        } 

        return (
            <div className="grid grid-cols-2">
             
                    {address[1] == 6 && 
                        <div className="col-span-9">
                            <Button role={role} className={`mt-2`} onClick={this.onChangeMode.bind(this)}>{modes[mode == 1 ? 0 : 1]}</Button> 
                        </div>
                    }
                    {!texts && 
                        <div className="mt-4 col-span-16 md:col-span-12">
                            <Input label="Value"  value={this.state && this.state.value || value} onBlur={this.onChange.bind(this)} />
                            {selectedpoint.getIn(['pointconfiguration', 'm_botV'])  &&  selectedpoint.getIn(['pointconfiguration', 'm_topV']) && 
                                <div className="-mt-4">
                                    {`Range ${selectedpoint.getIn(['pointconfiguration', 'm_botV'])}-${selectedpoint.getIn(['pointconfiguration', 'm_topV'])}`}
                                </div>}
                            <Button role="success" className="mt-4" onClick={this.commitChange.bind(this)}>Set</Button>
                            {address[1] == 9 && 
                            <Button role="success" className="mt-4 ml-4" onClick={this.setRelease.bind(this)}>Release</Button>}
                            {address[1] == 9 &&
                            <Button role="success" className="mt-4 ml-4" onClick={onShowPointPriorities}>Priorities</Button>}
                        </div>}
                    {texts && 
                        <div className="mt-4">
                            <Select label="Select Value"
                                value={value}
                                onChange={this.onChange.bind(this)}
                                options={[...texts.map((item, index) => {
                                    return {
                                        text: item,
                                        value: index
                                    }})]
                                }    
                            />
                            <Button role="success" className="mt-4" onClick={this.commitChange.bind(this)}>Set</Button> 

                        </div>}

            </div>
        );
    }
}

export default Analog;


