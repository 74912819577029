import * as d3 from 'd3';
import {fromJS} from 'immutable';
import { drawRect, drawPath, setDefs, createTextNode, drawTspan, setStateids, blink, displayTooltip } from "../Utils";

export default function CustomTextButton(options, path) {
    return new Promise(async (resolve, reject) => {

        let rotation = options.node.getIn(['configuration','rotation'], 0);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`);

        let drawButton = (placementElement, options, width, height, border, bordercolor, ry, id, backgroundcolor) => {
            let rect = drawRect(placementElement, border, border, width, height, 0, bordercolor, ry, false, id, backgroundcolor, 1);           
            let idPath = 1000 - options.node.get('id');
    
            let gradsHeader = fromJS([
                { "stop": "0%", "colour":"#5fbcd3" },
                { "stop": "100%", "colour":"#00aad4"}]);
    
            let path = `M0,0 ${width + border * 2},0 ${width + border},${border} ${border},${border} Z`;
            options.node.getIn(['configuration', 'gradientHeader'], true ) && setDefs(placementElement, idPath,
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'gradsHeader'], gradsHeader));
            
            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
            options.node.getIn(['configuration', 'style', 'background-color'],'#cccccc'),0,0,null);

            path = `M${width + border},${border} ${width + border * 2},0 ${width + border * 2},${height + border * 2} ${width + border},${height + border} Z`;

            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
            options.node.getIn(['configuration', 'style', 'background-color'],'#999999'),0,0,null);

            path = `M0,${height + border * 2} ${border},${height + border} ${width + border},${height + border} ${width + border * 2},${height + border * 2} Z`;

            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
            options.node.getIn(['configuration', 'style', 'background-color'],'#999999'),0,0,null);

            path = `M0,0 ${border},${border} ${border},${height + border} 0,${height + border * 2} Z`;

            drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
            options.node.getIn(['configuration', 'style', 'background-color'],'#b3b3b3'),0,0,null);

            return rect;
        }

        let drawText = (element, fontsize, fontstyle, underline, textcolor, text) => {
            // console.log('text ', textcolor, fontsize);
            let box1 = element.node().getBBox();
        
            let yoffset = 2; //fontsize / 2;
            let xoffset = box1.width / 2;

        //    fontsize = 22;
        
            let textNode = createTextNode(element, null, xoffset, yoffset, fontsize, fontstyle, underline, textcolor);

            drawTspan(textNode, xoffset, fontsize, text);

            return textNode;
        }

        let placementElement = createPlacementElement(options); 

        let rect = drawButton(placementElement, options, parseInt(options.node.getIn(['configuration', 'width'], 90)), 
            parseInt(options.node.getIn(['configuration', 'height'], 24)), parseInt(options.node.getIn(['configuration', 'borderwidth'], 3)),
            options.node.getIn(['configuration', 'border-color'], 'black'),
            options.node.getIn(['configuration', 'ry'], 0), options.node.get('id'), options.node.getIn(['configuration', 'background-color'],'lightgrey'));

        let fontsize = options.node.getIn(['configuration', 'height'], 24) - 2;
        let textNode = drawText(placementElement, fontsize,
            options.node.getIn(['configuration', 'fontstyle'], 'bold'), `${options.node.getIn(['configuration', 'underline']) ? 'underline' : 'none'}`,
            options.node.getIn(['configuration', 'text-color'], 'black'),
            options.node.getIn(['configuration', 'value'], 'Polling...'));

        placementElement.attr('transform', `matrix(${options.node.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${options.node.getIn(['configuration', 'scale'], 100.0) / 100},${options.node.getIn(['position', 'x'])},${options.node.getIn(['position', 'y'])})`);    

        let tooltip = displayTooltip(placementElement, options); 

        let oldvalue = -1;
        var animate;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;

                if (point.m_value != oldvalue) {
                    // console.log('values not equal ', point.m_value, oldvalue );
                    oldvalue = point.m_value;

                    if (options.node.getIn(['configuration', 'rules'])) {
                        let rules = options.node.getIn(['configuration', 'rules']);

                        //textNode.remove();
                        if (animate) animate.remove();
                        placementElement.style('display', 'none');
                        
                        options.node.getIn(['configuration', 'rules'], []).map((rule) => {
                            // console.log('rule ', rule );
                            if (rule.get('matchvalue') == point.m_value) {
                                textNode.remove();

                                placementElement.style('display', 'block');

                                //let rule = rules.get(point.m_value);
                                rect.style('display', 'block');
                                rect.style("fill", rule.get('background-color', '#222222'));
    
                                textNode = drawText(placementElement, fontsize, //rule.get('fontsize', 18),
                                   rule.get('fontstyle', 'bold'),
                                    `${rule.get('underline') ? 'underline' : 'none'}`,
                                    rule.get('text-color', 'black'),
                                    rule.get('text', 'Polling...'));
    
                                if (rule.get('action') == 'flashing') {
                                    animate = blink(placementElement);
                                } else {
                                    if (animate) animate.remove();
                                } 
                            }
                        })
/*
                        if (rules && rules.get(point.m_value)) {
                            textNode.remove();

                            let rule = rules.get(point.m_value);
                            rect.style("fill", rule.get('background-color', '#222222'));

                            textNode = drawText(placementElement, rule.get('fontsize', 18),
                               rule.get('fontstyle', 'bold'),
                                `${rule.get('underline') ? 'underline' : 'none'}`,
                                rule.get('text-color', 'black'),
                                rule.get('text', 'Polling...'));

                            if (rule.get('action') == 'flashing') {
                                animate = blink(placementElement);
                            } else {
                                if (animate) animate.remove();
                            }     
                        }*/
                    }        
                } 
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            setContextMenu: (editmode) => {
            //    if (animate) animate.remove();
                options.editmode = editmode;

                /*
                if (!image && images) {
                    image = images[Object.keys(images)[0]];
                    placementElement = displayImage(options, image.layer);
                }*/
            },
            redrawElement: async (node) => {
                placementElement.remove();           
                options.node = node;

                /*
                images = await getImages(node.getIn(['configuration', 'rules']), node.getIn(['component', 'componentid']));

                if (!node.getIn(['configuration', 'rules'])) {
                    image = images[0];
                }

                else {
                    image = images[Object.keys(images)[0]];
                }    
            
                if (image) {
                    placementElement = displayImage(options, image.layer);

                    tooltip = displayTooltip(placementElement, options);
                }*/
            //    oldvalue = -1;
            },
            remove: () => {
                placementElement.remove();               
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    })
}
