import React, {useState} from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import SettingsPanel from 'Controls/SettingsForm/SettingsPanel';
import { useCustomerSettings } from 'Context/CustomerSettings';
import Button from 'Controls/Button/UserDefined'
import { useSystemSettings } from '../../Context/SystemSettings';
import { uploadBackground } from '../Actions';
import { set, Map } from 'immutable';

export const SystemSettingsDialogButton = () => {
    const [active, setActive] = useState(false);
    return <div>
        <Button onClick={() => setActive(true)}>System settings</Button>
        <SystemSettingsDialog onClose={() => setActive(false)} active={active} />
    </div>
}

function useSystemSettingsForm(systemSettings) {

    const [systemSettingsForm, setSystemSettingsForm] = useState(systemSettings || Map());

    const setFormValue = (key, value) => {
        setSystemSettingsForm((prev) => prev.setIn(key, value));
    }

    return { systemSettingsForm, setFormValue };


}

const SystemSettingsDialog = ({ onClose, active }) => {

    const { systemSettings, save, reset } = useSystemSettings();
    const backgroundRef = React.createRef();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const { systemSettingsForm, setFormValue } = useSystemSettingsForm(systemSettings);

    const imageUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : null;

    const closeDialog = () => {
        onClose();
    }

    const onSave = async () => {
        setUploading(() => true);
        let updatedSystemSettings = systemSettingsForm;
        if(uploadedFile) {
            await uploadBackground(uploadedFile);
            updatedSystemSettings = updatedSystemSettings.set('loginBackground', `private/backgrounds/${uploadedFile.name}`);
        }
        
       
        await save(updatedSystemSettings);
        setUploading(() => false);
    }



    const logModules = {
        'system': "System",
        'mongo': "Mongo",
        'mongo-command': "Mongo Command",
        'service-bus': "Service Bus",
        'file-api': "File API",
        'customer-api': "Customer API",
    }

    return (<TransitioningDialog panelWidthClass="min-w-min max-w-max " onClose={onClose} opened={!!active}>

        <div className="">
            <div className="text-2xl">System settings</div>
        </div>
        <div className="flex flex-row gap-2">
            <div className="p-2">
                <div>Logging</div>
                <div className="grid grid-cols-3 gap-2">
                    <div>Module</div>
                    <div className="text-center">Events</div>
                    <div className="text-center">Errors</div>


                    {Object.keys(logModules).map((key) => (
                        <div key={key} className="grid grid-cols-subgrid col-span-3 hover:bg-gray-300 py-1 px-2">
                            <div>{logModules[key]}</div>
                            <label className="text-center">
                                <input type="checkbox" 
                                    checked={systemSettingsForm.getIn(['logging', key, 'info', 'enabled'], false)} 
                                    onChange={(e) => setFormValue(['logging', key, 'info', 'enabled'], e.target.checked)}
                                    />
                            </label>
                            <label className="text-center">
                                <input type="checkbox" 
                                    checked={systemSettingsForm.getIn(['logging', key, 'error', 'enabled'], false)} 
                                    onChange={(e) => setFormValue(['logging', key, 'error', 'enabled'], e.target.checked)}
                                    />
                            </label>
                        </div>
                    ))}

                </div>
            </div>

            <div className=" p-2">
                <strong>Login Background:</strong> (click to change)
                <div className="">
                    <div className="w-96" onClick={() => {
                        backgroundRef?.current?.click()
                    }}>
                        <input type="file" className="hidden" onChange={(e) => setUploadedFile(e.target.files[0])} ref={backgroundRef}/>
                        <img  src={`${imageUrl || '/login-background.webp'}`} alt="Login background"  />
                    </div>
                </div>

            </div>
        </div>


        <div className="flex p-2 gap-2 justify-end">
                <Button role="save" className="disabled:opacity-70" disabled={uploading} onClick={() => onSave()}>
                    {uploading ? "Saving..." : "Save"}
                </Button>
                <Button role="close" className="" onClick={() => closeDialog()}>Close</Button>
        </div>

    </TransitioningDialog>)

}

export default SystemSettingsDialog;


