export default [
    {
        name: 'Not in use',
        pointtypes: []
    }, {
        name: 'User Interface',
        pointtypes: []
    }, {
        name: 'SR',
        pointtypes: []
    }, {
        name: 'Excel',
        pointtypes: []
    }, {
        name: 'MBUS',
        moduleid:  4,
        pointtypes: [
            {
                name: 'Analog Input',
                prefix: 'AI',
                fields: [
                    {
                        label:'Device ID'
                    },
                    {
                        label:'Point Number'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }
        ]
    }, {
        name: 'Trend',
        moduleid:  5,
        scanfields: {
            'network':'lans',
            'device':'outstations',
            'deviceheader': 'Outstation'
        },
        pointtypes: [
            {
                name: 'Digital Input',
                prefix: 'I',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'digital',
                commandable: false
            }, {
                name: 'Switch',
                prefix: 'W',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'digital',
                control: 'Digital',
                type: 0
            }, {
                name: 'Sensor',
                prefix: 'S',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'Knob',
                prefix: 'K',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'analog',
                type: 2,
                control: 'Analog'
            }, {
                name: 'IQ2 TimeZone',
                prefix: 'TZ',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                commandable: true,
                engunit: 'timezone',
            }, {
                name: 'Driver',
                prefix: 'D',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'Loop',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'digital',
                commandable: false
            }, {
                name: 'Schedule',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Address'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'Analog Point',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Address'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'Generic Analog',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Address'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'Digital Bit',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Address'
                    },
                    {
                        label:'Bit'
                    }
                ],
                engunit: 'digital',
                commandable: false
            }, {
                name: 'Function',
                prefix: 'PT',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Number'
                    }
                ],
                engunit: 'analog',
                commandable: false
            }, {
                name: 'IQ3 TimeZone',
                prefix: 'TZ',
                fields: [
                    {
                        label:'Lan'
                    },
                    {
                        label:'Outstation'
                    },
                    {
                        label:'Point'
                    }
                ],
                engunit: 'digital',
                commandable: true
            }, {
                name: 'Performance',
                prefix: 'PT',
                fields: [
                    {
                        label: 'Point'
                    }
                ],
                engunit: 'analog'
            }, {
                name: 'Logic',
                prefix: 'LG',
                fields: [
                    {
                        label: 'Lan'
                    },
                    {
                        label: 'Outstation'
                    },
                    {
                        label: 'Point'
                    }
                ],
                engunit: 'digital'
            }, {
                name: 'Time',
                prefix: 'TP',
                fields: [
                    {
                        label: 'Lan'
                    },
                    {
                        label: 'Outstation'
                    },
                    {
                        label: 'Point'
                    }
                ],
                engunit: 'analog'
            }
        ]
    }, {
        name: 'Excel 500',
        moduleid:  6,
        scanfields: {
            'network':'',
            'device':'controllers',
            'deviceheader': 'Controller'
        },
        pointtypes: [{
            name: 'Analog input',
            prefix: 'AI',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'IO Board'
                },
                {
                    label: 'Point'
                }
            ],
            engunit: 'analog',
            control: 'Analog'
        }, {
            name: 'Digital Input',
            prefix: 'DI',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'IO Board'
                },
                {
                    label: 'Point'
                }
            ],
            engunit: 'digital',
            control: 'Digital'
        }, {
            name: 'Digital Output',
            prefix: 'DO',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'IO Board'
                },
                {
                    label: 'Point'
                }
            ],
            engunit: 'digital',
            control: 'Digital'
        }, {
            name: 'Analog Output',
            prefix: 'AO',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'IO Board'
                },
                {
                    label: 'Point'
                }
            ],
            engunit: 'analog',
            control: 'Analog'
        }, {
            name: 'Pulse Input',
            prefix: 'PT'
        }, {
            name: ''
        }, {
            name: 'Virtual Analog',
            prefix: 'VA',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'Point'
                }
            ],
            engunit: 'analog',
            control: 'Analog'
        }, {
            name: 'Virtual Digital',
            prefix: 'VD',
            fields: [
                {
                    label: 'Controller'
                },
                {
                    label: 'Point'
                }
            ],
            commandable: true,
            engunit: 'digital',
            control: 'Digital'
        }, {
            name: 'Global Analog',
            prefix: 'GA',
            commandable: true
        }, {
            name: 'Global Digital',
            prefix: 'GD',
            commandable: true
        },{
            name: ''
        }, {
            name: ''
        }, {
            name: 'Flexible',
            prefix: 'FL',
            commandable: false,
            control: 'Analog'
        }, {
            name: 'Pulse Point',
            prefix: 'PU',
            commandable: false,
            control: 'Analog'
        }]
    }, {
        name: 'JEL',
        pointtypes: []
    }, {
        name: 'Echelon',
        pointtypes: []
    }, {
        name: 'Bacnet',
        moduleid:  9,
        scanfields: {
            'network':'',
            'device':'devices',
            'deviceheader': 'Device'
        },
        pointtypes: [{
            name: 'Analog Input',
            prefix: 'AI',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'analog',
            commandable: false
        }, {
            name: 'Analog Output',
            prefix: 'AO',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'analog',
            commandable: false
        }, {
            name: 'Analog Value',
            prefix: 'AV',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'analog',
            commandable: true,
            control: 'Analog'
        }, {
            name: 'Binary Input',
            prefix: 'BI',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'digital',
            commandable: false
        }, {
            name: 'Binary Output',
            prefix: 'BO',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'digital',
            commandable: true,
            control: 'Digital'
        }, {
            name: 'Binary Value',
            prefix: 'BV',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'digital',
            commandable: true,
            control: 'Digital'
        }, {
            name: 'Calendar',
            prefix: 'CA'
        }, {
            name: 'Command',
            prefix: 'CO'
        }, {
            name: 'Device',
            prefix: 'DE'
        }, {
            name: 'Event Enrollment',
            prefix: 'EE'
        }, {
            name: 'File',
            prefix: 'FI'
        }, {
            name: 'Group',
            prefix: 'GR'
        }, {
            name: 'Loop',
            prefix: 'LO'
        }, {
            name: 'Multi State Input',
            prefix: 'MI',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            commandable: false,
            control: 'Multistate',
            engunit: 'analog'
        }, {
            name: 'Multi State Output',
            prefix: 'MO',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            commandable: false,
            control: 'Multistate',
            engunit: 'analog'
        }, {
            name: 'Notification Class',
            prefix: 'NC',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            commandable: false,
            engunit: 'analog'
        }, {
            name: 'Program',
            prefix: 'PR'
        }, {
            name: 'Schedule',
            prefix: 'SC',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'digital',
            commandable: true
        }, {
            name: 'Averaging',
            prefix: 'AV'
        }, {
            name: 'Multi State Value',
            prefix: 'MV',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            commandable: true,
            control: 'Multistate',
            engunit: 'analog'
        }, {
            name: 'Trend log',
            prefix: 'TL'
        },{
            name: 'Life Safety Point',
            prefix: 'LS'
        },{
            name: 'BmsView Timezone',
            prefix: 'TZ',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            commandable: false,
            engunit: 'digital',
        },{
            name: 'Accumulator',
            prefix: 'AC',
            fields: [
                {
                    label: 'Device Instance'
                },
                {
                    label: 'Object Instance'
                }
            ],
            engunit: 'analog',
            commandable: false,
            control: 'Analog'
        }]
    }, {
        name: 'Telensa',
        pointtypes: []
    }, {
        name: 'Cylon',
        moduleid: 11,
        scanfields: {
            'network':'network controllers',
            'device':'controllers'
        },
        pointtypes: [{
            name: "Analog Input",
            prefix: 'AI',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'analog',
            commandable: false
        },  {
            name: "Analog Output",
            prefix: 'AO',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'analog',
            commandable: false,
            control: "Analog"
        }, {
            name: "Digital Input",
            prefix: 'DI',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'digital',
            commandable: false
        }, {
            name: 'Digital Output',
            prefix: 'DO',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'digital',
            commandable: false
        }, {
            name: "Analog Virtual",
            prefix: 'AV',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'analog',
            control: 'Analog',
            type: 2
        }, {
            name: "Digital Virtual",
            prefix: 'DV',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'digital',
            control: "Digital",
            type: 0
        }, {
            name: "Global Analog",
            prefix: 'GA',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'analog',
            commandable: false,
            control: "Analog"
        }, {
            name: "Global Digital",
            prefix: 'GD',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'digital',
            commandable: true,
            control: "Digital"
        }, {
            name: "Logpoint"
        }, {
            name: "Schedule",
            prefix: 'SC',
            fields: [
                {
                    label:'Site ID'
                },
                {
                    label:'UCC4'
                },
                {
                    label:'UC16'
                },
                {
                    label:'Point'
                }
            ],
            engunit: 'digital'
        }]
    }, {
        name: 'EMCCBUS',
        pointtypes: []
    }, {
        name: 'Amartin',
        pointtypes: []
    }, {
        name: 'Modbus',
        moduleid: 14,
        scanfields: {
            'network':'',
            'device':'devices',
            'deviceheader': 'Controller'
        },
        pointtypes: [{
            name: "Unused",
            prefix: '',
            type: 0,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                },
                {
                    label:'Address Bit'
                }
            ]
        },{
            name: "Coil",
            prefix: 'COIL',
            type: 1,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                },
                {
                    label:'Address Bit'
                }
            ],
            engunit: 'digital',
            control: 'Digital'
        }, 
        {
            name: "Input Status",
            prefix: 'INST',
            type: 2,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                },
                {
                    label:'Address Bit'
                }
            ],
            engunit: 'digital',
            commandable: false
        }, {
            name: "Holding Register",
            prefix: 'HOLDREG',
            type: 3,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                }
            ],
            engunit: 'analog',
            control: 'Analog'
        }, {
            name: "Input Register",
            prefix: 'INREG',
            type: 4,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                }
            ],
            engunit: 'analog'
        }, {
            name: "Holding Register (Ana/Dig)",
            prefix: 'HOLDREGAD',
            type: 5,
            fields: [
                {
                    label:'Device ID'
                },
                {
                    label:'Address Byte'
                }
            ],
            engunit: 'analog',
            commandable: true
        }]
    },{
        name: 'Logic',
        pointtypes: []
    }, {
        name: 'Web report client',
        pointtypes: []
    }, {
        name: 'Net report client',
        pointtypes: []
    }, {
        name: 'Web Integrator client',
        pointtypes: []
    }, {
        name: 'Bacnet Server client',
        pointtypes: []
    }, {
        name: 'Reporting Program',
        moduleid: 20,
        pointtypes: [{
            name: "Report"
        }]
    }, {
        name: 'Modbus IP',
        pointtypes: []
    }, {
        name: 'Seachange',
        moduleid: 22,
        pointtypes: [{
            name: 'Digital Input',
            prefix: 'I',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit :"digital",
            commandable: false
        },{
            name: 'Switch',
            prefix: 'W',
            type: 0,
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit :"digital",
            commandable: true,
            control: 'Digital'
        }, {
            name: 'Sensor',
            prefix: 'S',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit: 'analog',
            commandable: false
        }, {
            name: 'Knob',
            prefix: 'K',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit: 'analog',
            type: 2,
            control: 'Analog'
        }, {
            name: 'Config Type',
            prefix: 'CT',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            commandable: true,
            engunit: 'analog'
        }, {
            name: 'Zone',
            prefix: 'Z',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit: 'analog',
            commandable: false
        }, {
            name: 'Schedule',
            prefix: 'SC',
            fields: [
                {
                    label: 'Primary Controller Type'
                }, {
                    label: 'Primary Controller'
                }, {
                    label: 'Secondary Controller Type'
                },  {
                    label: 'Secondary Controller'
                },  {
                    label:'Number'
                }
            ],
            engunit: 'timezone',
            commandable: false
        }

    ]
    }

];