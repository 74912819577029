
import ServiceBus from '../../../../../../../Services/ServiceBus';

// Completely the same as the original, except for the dep injection and the pin
export default function saveToPanel({ customer, address, currentuser, events, pin }) {


    let subsystemNumber = parseInt(address[0]);

    let saveRequest = {
        m_subsystemNumber: subsystemNumber,
        target: 'signalr',
        type: 'GENERIC',
        databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        trackMessage: true
    };

    let comm = {
        m_password: pin,
        m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
        m_pointAddr: address.map((byte) => parseInt(byte))
    };

    if (address[1] == 5 && address[2] == 4) {
        //IQ2 timezone
        saveRequest.m_level = 50;
        saveRequest.m_communicNum = 506;
        comm.m_sendToPanel = events.reduce((dayToSend, event) => {
            let indexToUpdate = (event.getIn(['day'], 0)) < 0 ? 6 : event.getIn(['day'], 0);
            if (event.get('dirty')) {
                if (event.get('type') == 'standard'){
                    indexToUpdate += 7;
                }
                dayToSend[indexToUpdate] = 1;
            }
            return dayToSend;
        }, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        comm.m_day = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        comm.m_warmUpLimit = 0;
        comm.m_coolDownLimit = 0;

        events.sort((a, b) => {
            return a.getIn(['starttimeindex']) - b.getIn(['starttimeindex']);
        }).forEach((event, index) => {
            let dow = event.getIn(['day'], 0);
            if (dow < 0) {
                dow = 6;
            }
            if (event.get('type') == 'standard'){
                dow += 7;
            }
            let alreadyProcessed = false;
            for (var i = 1; i < 4; i++){
                if (!comm.m_day[dow][`bH${i}`] && !alreadyProcessed){
                    comm.m_day[dow][`bH${i}`] = (parseInt(event.get('startindouble')));
                    comm.m_day[dow][`bM${i}`] = Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5;
                    comm.m_day[dow][`eH${i}`] = (parseInt(event.get('endindouble')));
                    comm.m_day[dow][`eM${i}`] = Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5;
                    alreadyProcessed = true;
                }
            }
        });
        
    }
    if (address[1] == 5 && address[2] == 12) {
        //IQ3 timezone
        saveRequest.m_level = 50;            
        saveRequest.m_communicNum = 508;
        comm.m_sendToPanel = events.reduce((dayToSend, event) => {
            if (event.get('dirty')) {
                dayToSend[event.getIn(['day'])] = 1;
            }
            return dayToSend;
        }, [0, 0, 0, 0, 0, 0, 0]);
        comm.m_prds = [[], [], [], [], [], [], []];

        events.filter((event) => !event.get('removed')).sort((a, b) => {
            return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
        }).forEach((event) => {              
            let dow = event.getIn(['day'], 0);

            comm.m_prds[dow].push({
                h: (parseInt(event.get('startindouble'))),
                m: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                v: 1});

            comm.m_prds[dow].push({
                h: (parseInt(event.get('endindouble'))),
                m: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                v: 0});
        });
    }

    if (address[1] == 9 && address[2] == 17) {
        saveRequest.m_level = 150;            
        saveRequest.m_communicNum = 25;
        comm.m_prds = [[], [], [], [], [], [], []];
        let eventsToProcess =  events.filter((event) => !event.get('removed')).sort((a, b) => {
            return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
        });

        // console.log("eventsToProcess", eventsToProcess);

        let lastProcessedEvent = undefined;
        eventsToProcess.forEach((event) => {              
            let dow = event.getIn(['day'], 0);
            let onlyProcessEndTime = false;
            if(lastProcessedEvent && ((lastProcessedEvent.get('day') + 1) % 7) == event.get('day')) {   
                if(event.get('startindouble') == 0) {
                    comm.m_prds[dow].push({ 
                        h: 0,
                        m: 0,
                        v: 1
                    });
                    onlyProcessEndTime = true;
                } else {
                    comm.m_prds[dow].push({
                        h: 0,
                        m: 0,
                        v: 0
                    });
                }
                lastProcessedEvent = undefined;
            }
            if(!onlyProcessEndTime) {
                comm.m_prds[dow].push({
                    h: (parseInt(event.get('startindouble'))),
                    m: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                    v: 1
                });
            }
            if(event.get('endindouble') == 24) {
                lastProcessedEvent = event;
                return;
            }
            comm.m_prds[dow].push({
                h: (parseInt(event.get('endindouble'))),
                m: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5,
                v: 0});
        });
        if(lastProcessedEvent) {
            let dow = (lastProcessedEvent.getIn(['day'], 0) + 1) % 7;
            if(dow == 0) {
                comm.m_prds[dow].unshift({
                    h: 0,
                    m: 0,
                    v: 0
                });
            } else {
                comm.m_prds[dow].push({
                    h: 0,
                    m: 0,
                    v: 0
                });
            }
            
        }
        comm.m_prds = comm.m_prds.map((day) => {

            const hasOffAtMidnight = day.some((period) => period.h == 0 && period.m == 0 && period.v == 0);
            const hasOnAtMidnight = day.some((period) => period.h == 0 && period.m == 0 && period.v == 1);

            if(hasOffAtMidnight && hasOnAtMidnight) {
                return day.filter((period) => !(period.h == 0 && period.m == 0 && period.v == 0));
            }
            return day;

        });
        comm.m_pointGroupIds = [];
    }

    if (address[1] == 11 ) {
        //Cylon timezone
        saveRequest.m_level = 120;
        saveRequest.m_communicNum = 1;
        comm.m_scheduleTimes = [];

        // console.log('Events : ', events);

        events.filter((event) => !event.get('removed')).sort((a, b) => {
            return a.getIn(['starttimeindex'], 0) - b.getIn(['starttimeindex'], 0);
        }).forEach((event) => {              
            let dow = event.getIn(['day'], 0);

            comm.m_scheduleTimes.push({
                m_bHr: (parseInt(event.get('startindouble'))),
                m_bMn: Math.round((parseInt((event.get('startindouble') % 1) * 60)) / 5) * 5,
                m_eHr: (parseInt(event.get('endindouble'))),
                m_eMn: Math.round((parseInt((event.get('endindouble') % 1) * 60)) / 5) * 5});
        });
    }

    ServiceBus.send('WEB_MESSAGE_REQ', saveRequest, comm);
}
