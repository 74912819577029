import { useEffect } from 'react';
import swal from 'sweetalert2';
import { useTimezoneScreenStore } from '../store/useTimezoneScreenStore';
import requestTimezones from '../shared/requestTimezones';
import ServiceBus from 'Services/ServiceBus';
import { convertDoubleToTimeString, convertTimeStringToDouble } from 'Utils/Timestamp';

export default function useTimezoneCompleteHandlers({ customer, address }) {

    const { events, setEvents, setDirty } = useTimezoneScreenStore();

    useEffect(() => {

        const trendIQ2TimezoneCompleteHandler = (message) => {
            if (message.m_errorNumber != 0) {
                swal.fire("Error", `Error number ${message.m_errorNumber}`, "error");
            }
            else {
                swal.fire("Success", `The new timezones have been saved to the panel`, "success");
    
                setEvents((events) => events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }));

                setDirty(false);

            }
            requestTimezones({ customer, address });
        }

        const iq3CompleteHandler = (message) => {
            if (message.m_errorNumber != 0) {
                swal.fire("Error", `Error number ${message.m_errorNumber}`, "error");
            }
            else {
                swal.fire("Success", `The new timezones have been saved to the panel`, "success");
    
                setEvents((events) => events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }));

                setDirty(false);

            }
            requestTimezones({ customer, address });
        }

        const bacnetCompleteHandler = (message) => {
            if (message.m_errorNumber != 0) {
                swal.fire("Error", `Error number ${message.m_errorNumber}<br />${(message.m_responseText || []).join("<br />")}`, "error");
            }
            else {
                swal.fire("Success", `The new schedule have been saved to the panel<br /><br />${(message.m_responseText || []).join("<br />")}`, "success");
    
                setEvents((events) => events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }));

                setDirty(false);

            }
            requestTimezones({ customer, address });
        }

        const ucc4CompleteHandler = (message) => {
            let parsedMessage = JSON.parse(message);
            if (parsedMessage.m_errorNumber != 0) {
                swal.fire("Error", `Error number ${parsedMessage.m_errorNumber}`, "error");
            }
            else {
                swal.fire("Success", `The new timezones have been saved to the panel`, "success");
    
                setEvents((events) => events.filter((event) => { return !event.get('removed'); }).map((event) => {
                    return event.set('dirty', false);
                }));

                setDirty(false);

            }
            requestTimezones({ customer, address });
        }

        ServiceBus.on('508-51', iq3CompleteHandler);
        ServiceBus.on('506-51', trendIQ2TimezoneCompleteHandler);
        ServiceBus.on('25-151', bacnetCompleteHandler);
        ServiceBus.on('SET_CYLON_UCC4_TIMEZONES_RESPONSE', ucc4CompleteHandler);

        return () => {
            ServiceBus.off('508-51', iq3CompleteHandler);
            ServiceBus.off('506-51', trendIQ2TimezoneCompleteHandler);
            ServiceBus.off('25-151', bacnetCompleteHandler);
            ServiceBus.off('SET_CYLON_UCC4_TIMEZONES_RESPONSE', ucc4CompleteHandler);
        }



    }, [])

    return null;

}