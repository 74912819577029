import React, { useEffect } from 'react';
import SVGComponent from './SVGComponent';
import SVGComponentCompound from './SVGComponentCompound';
import { useBMS } from 'BmsView/Context/BMS';
import useAreaSite from '../Hooks/useAreaSite';

export default SVGLayer;

function SVGLayer({  pointUpdated, openAreaDialog, layer, layerIndex, editmode, selectionmode, scale, onNodeChange, onNodeConfiguration, onShowContextMenu, toggleSelection, toggleSingleSelection, selection, mouseMovement, setMouseMovement, onMultiDragFinished, onNodeMount }) {

    
    const { site } = useAreaSite();

   

    return <g  id={`layer-${layerIndex}`} key={site?.get('name')}>
        { layer && (layer.get('nodes') || []).map((node, nodeIndex) => {

            const nodeType = node.getIn(['component','type']);

            if(nodeType === 'compound') {
                return <SVGComponentCompound 
                    key={node.get('id') + "-" + nodeIndex}
                    mouseMovement={mouseMovement}
                    onNodeMount={onNodeMount}
                    setMouseMovement={setMouseMovement}
                    toggleSelection={toggleSelection}
                    selection={selection}
                    toggleSingleSelection={toggleSingleSelection}
                    onMultiDragFinished={onMultiDragFinished}
                    onShowContextMenu={onShowContextMenu}
                    onNodeConfiguration={onNodeConfiguration}
                    scale={scale}
                    editmode={editmode}
                    selectionmode={selectionmode}
                    // key={nodeIndex} 
                    node={node} 
                    onNodeChange={onNodeChange}
                    nodeIndex={nodeIndex} 
                    layerIndex={layerIndex} 
                />
            }

            return <SVGComponent 
                key={node.get('id') + "-" + nodeIndex}
                mouseMovement={mouseMovement}
                onNodeMount={onNodeMount}
                setMouseMovement={setMouseMovement}
                toggleSelection={toggleSelection}
                selection={selection}
                toggleSingleSelection={toggleSingleSelection}
                onMultiDragFinished={onMultiDragFinished}
                onShowContextMenu={onShowContextMenu}
                onNodeConfiguration={onNodeConfiguration}
                scale={scale}
                editmode={editmode}
                selectionmode={selectionmode}
                openAreaDialog={openAreaDialog}
                pointUpdated={pointUpdated}
                // key={nodeIndex} 
                node={node} 
                onNodeChange={onNodeChange}
                nodeIndex={nodeIndex} 
                layerIndex={layerIndex} 
            />

        })}
    </g>
}