import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Immutable from 'immutable';
import {getDevices, saveItem, deleteDevice, deleteDevicePoints} from '../Actions';
import { getPoints } from 'BmsView/Customer/Site/Actions';
import ControllerList from './ControllerList';
import PointList from './PointList';
import ScanDialog from '../ScanDialog';
import ScanForPointsDialog from '../ScanForPoints';
import Swal from 'sweetalert2';

export default function BacnetSetupDialog({opendialog, onClose, customer, selecteditem}) {
  const [open, setOpen] = useState(opendialog);
  const [controllers, setControllers] = useState(Immutable.List());
  const [selectedcontroller, setCurrentController] = useState(undefined);
  const [points, setPoints] = useState(Immutable.List());
  const [dialogtype, setDialogType] = useState(undefined);

  useEffect(() => {
      getControllerList();
  }, []);


  useEffect(() => {
    getPointList();
  }, [selectedcontroller]);

  const getControllerList = () => {
    getDevices(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
      `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'bacnetdevices').then((data) => {
          setControllers(Immutable.fromJS(data.map((controller) => {return controller}   
          )))
        });
  }
    
  const getPointList = () => {
    getPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`).then((data) => (
            setPoints(Immutable.fromJS(data.map((point) => {
                return {
                  subsystem: point.addr[0],
                  subsystemtype: point.addr[1],
                  lan:0,
                  controller: point.addr[3],
                  name: point.name,
                  type: point.addr[2],
                  number: point.addr[4]
                }
            })))
        )
      )
  }

  const updateItem = (device) => {
    saveItem(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
      `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'bacnetdevices', device).then(() => {
          Swal.fire("Success", "The device has been succesfully saved.", "success");
          getControllerList();
      }).catch((err) => {
          Swal.fire('Error', "An error occurred while saving the device, please contact the administrator.", "error");
      }) 
  }
    
  const deleteItem = (objecttype, device) => {
    Swal.fire({
        title: "Are you sure you want to delete the Controller ?",
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.value) {
            await deleteDevice(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                `${customer.get('ipaddress').toLowerCase()}`, objecttype, device);
                getControllerList();
            Swal.fire(
                'Deleted!',
                'The CONTROLLER has been deleted',
                'success'
            );
        }
    });
  }

  const scan = (type) => {
    setDialogType(type);
  }

  const scanForPoints = async (controller) => {
    const { value: formValues } = await Swal.fire({
			title: 'Are you sure you want to scan for points ?',
			icon: 'warning',
			html:
				'<label for="delete">Delete existing points</label></br>' +
				'<input type="checkbox" id="delete" value=0></br>',
			focusConfirm: false,
			showCancelButton: true,
			preConfirm: () => {
				return [
					document.getElementById('delete').checked
				]
			}
		})

		if (formValues) {
      if (formValues[0]) {deleteControllerPoints(controller.get('instancenumber'))}
			setDialogType('scanforpoints');
		}   
  };  
  
  const deleteControllerPoints = (controllernumber) => {
    let query = {};
       
    query['addr.0'] = parseInt(selecteditem.get('number'));
    query['addr.3'] = parseInt(controllernumber);

    deleteDevicePoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                `${customer.get('ipaddress').toLowerCase()}`, 'points', query);
  }

  const closeDialog = (name) => {
    if (name == 'scanforcontrollers') {
      getControllerList();
    }

    if (name == 'scanforpoints') {
        getPointList();
    }

    setDialogType(undefined);
  }

  return (
    <React.Fragment>
      {dialogtype == "scanforcontrollers"  && <ScanDialog selecteditem={dialogtype} customer={customer} selectedSubsystem={selecteditem} selectedlan={0} onClose={() => closeDialog('scanforcontrollers')} />}
      {dialogtype == "scanforpoints" && selectedcontroller && <ScanForPointsDialog selecteditem={dialogtype} type={"bacnet"} customer={customer} selectedSubsystem={selecteditem.get('number')} selectedcontroller={selectedcontroller.get('instancenumber')} selectedlan={0} overwrite={0} histories={0} onClose={() => closeDialog('scanforpoints')} />}      
      <Transition.Root show={opendialog} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg mx-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full sm:p-6">
                  <div>
                    <div className="mt-2 sm:mt-3">
                      <Dialog.Title as="h2" className="text-xl leading-6 font-semibold text-gray-900">
                        Bacnet Subsystem Configuration
                      </Dialog.Title>
                      <div className="grid grid-cols-11 gap-8 mt-8">
                        <div className="col-span-6">
                        <ControllerList
                          customer={customer}
                          selectedsubsystem={selecteditem.get('number')}
                          selectedcontroller={selectedcontroller}
                          controllers={controllers}
                          setCurrentController={(controller) => setCurrentController(controller)}
                          scan={(type) => scan(type)}
                          scanForPoints={(controller) => scanForPoints(controller)}
                          saveItem={(device) => updateItem(device)}
                          deleteItem={(type, controller) => deleteItem(type, controller) }/>
                        </div>
                        <div className="col-span-5">
                        <PointList
                          selectedSubsystem={selecteditem.get('number')}
                          selectedController={selectedcontroller}
                          points={points}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex rounded-md border border-gray-300 bg-gray-500 shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        onClick={() => onClose()}>Exit</button>
                    </div>
                  </div>  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      </React.Fragment>
  )
}



