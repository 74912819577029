import React, {useState} from 'react';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { RealtimeDebuggerProvider, useRealtimeDebugger } from '../../BmsView/Context/RealtimeDebugger';
import { allChannels } from '../../BmsView/Context/RealtimeDebugger';

function ServiceBusEventsEntry({ payload, timestamp }) {
    let message = payload?.message;
    let time = payload?.time;

    let stats = payload?.currentStats;
    let statString = `Connected clients: ${stats?.connectedClients}, ConnectedUsers: ${stats?.connectedUsers}, Attached Debuggers: ${stats?.attachedDebuggers}, Attached Trackers: ${stats.attachedTrackers}`;
    let dateTimeString = new Date(timestamp).toLocaleString();
    return <div className={`flex flex-row gap-2`}>
        <div>{dateTimeString} - ServiceBus(events)</div>
        <div>{statString}</div>
    </div>
}

function MongoCommandEntry({ payload, timestamp }) {
    const [expand, setExpand] = useState(false);

    let statusClassName = '';
    let statusName = 'none';
    let collectionName = payload?.command?.[payload?.commandName];
    if(payload?.eventType == 'commandStarted') {
        statusClassName = 'text-blue-700 bg-blue-100';
        statusName = 'Started';
    } else if(payload?.eventType == 'commandSucceeded') {
        statusClassName = 'text-green-700 bg-green-100';
        statusName = 'Succeeded';
        collectionName = payload?.reply?.ns;
    } else if(payload?.eventType == 'commandFailed') {
        statusClassName = 'text-red-700 bg-red-100';
        statusName = 'Failed';
    }

    let dateTimeString = new Date(timestamp).toLocaleString();



    return <div className={`${statusClassName} cursor-pointer`} onClick={() => setExpand(x => !x)} >
        <div className="">{dateTimeString} Mongo command ({statusName}): {payload.commandName} on {collectionName} </div>
        {expand && <div>  {JSON.stringify(payload, null, 2)}</div>}
      
    </div>
}

function LoggerEntry({ payload }) {
    let message = payload?.message;
    let time = payload?.time;
    let logName = payload?.logName;

    return <div className={`flex flex-row gap-2`}>
        <div>{time} - {logName}</div>
        <div>{message}</div>
    </div>
}

const DebugComponents = {
    'logger': LoggerEntry,
    'mongo-command': MongoCommandEntry,
    'servicebus-events': ServiceBusEventsEntry,
}

function RealtimeDebuggerDialog({ opened, onClose, ...props }) {
    return <Dialog className={`rounded-md p-2 border-4 border-double md:min-w-[80vw] md:max-w-[80vw]`} opened={!!opened} onClose={onClose}>
        <RealtimeDebuggerProvider><RealtimeDebuggerPanel onClose={onClose} {...props} /></RealtimeDebuggerProvider>
    </Dialog>
}

const displayMultilineText = (mltext) => {
    const textWithBreaks = mltext.split('\n').map((text, index) => (
        <React.Fragment key={index}>
            {text}<br/>
        </React.Fragment>
    ));
    
    return textWithBreaks;
}

const RealtimeDebuggerPanel = ({ onClose}) => {

    const { messages, messageFilter, toggleMessageFilter, clearMessages } = useRealtimeDebugger();
    

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle className="flex flex-row w-full justify-between items-center">
                    <div>Realtime Debugger</div>
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <div className="flex flex-row gap-2 py-2 items-center">
                    <div>Filters:</div>
                    {allChannels.map((channel) => {
                    
                        let stateClassName = '';
                        if(messageFilter.includes(channel)) {
                            stateClassName = 'bg-green-300 text-green-900';
                        }

                    return <div className={`p-2 ${stateClassName} bg-gray-200 rounded-lg text-gray-600 cursor-pointer`} onClick={() => toggleMessageFilter(channel)}>
                            {channel}
                    </div>


                    })}
                    <button className="p-2 bg-orange-600 text-white rounded-lg" onClick={clearMessages}>Clear</button>
                </div>
                <pre>
                    {messages.reverse().map((message, index) => {
                        let Component = DebugComponents[message.channel];
                        if(Component) {
                            return <Component timestamp={message.time} key={index} payload={message.payload} />
                        }

                        return (
                            <div key={`${index}-${message?.time}`}>{JSON.stringify(message.payload, null)}</div>
                        )         
                    })}
                </pre>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    )
}

export default RealtimeDebuggerDialog;