import { List } from 'immutable';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import ServiceBus from '../../Services/ServiceBus';
import axios from 'axios';

window.axios = axios;

const ServiceBusContext = React.createContext({});

export const ServiceBusProvider = ServiceBusContext.Provider;



export function useServiceBus() {
    return useContext(ServiceBusContext);
}

async function getSocketToken(abortController) {
    try {
        let tokenResponse = await axios.post("/getSocketToken", { 
            body: {},
            signal: abortController.signal 
        });
        return tokenResponse.data.token;
    } catch(err) {
        throw new Error("NOT_AUTHORIZED");
    }
    
}

async function authenticate(abortController, attempts = 0) {
    // console.log("1309 trying to authenticate");
    try {
        let token = await getSocketToken(abortController);
        ServiceBus.authenticate(token);
    } catch(err) {
        // console.log("an error occured while trying to authenticate");
        // console.log(err);
    }
}

export default function ServiceBusWrapper({ children }) {

    const [authenticated, setAuthenticated] = useState(false);
    const [offline, setOffline] = useState(false);
    

    useEffect(() => {
        // console.log("1309 ServiceBusWrapper useEffect mount");
        ServiceBus.setContextCallback((connected, error) => {
            if(connected) {
                setAuthenticated(() => true);
                return;
            }
           
            if(
                error.message === "ERR_JWT_EXPIRED" ||
                error.message === "ERR_JWT_INVALID" || 
                error.message === "Authentication error" ||
                error.message === "ERR_JWS_SIGNATURE_VERIFICATION_FAILED") {

                    // console.log("error in useEffect setContextCallbackx", error); 

                setAuthenticated(() => false);
                return;
            }
        })

        const onOnline = () => {
            // console.log("1309 ServiceBusWrapper online")
            setOffline(() => false);
        }

        const onOffline = () => {
            // console.log("1309 ServiceBusWrapper offline")
            setOffline(() => true);
        }

        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        return () => {
            // console.log("1309 ServiceBusWrapper useEffect unmount");
            ServiceBus.setContextCallback(null);
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        }
    }, [])

    useEffect(() => {
        if(!authenticated && !offline) {
            const abortController = new AbortController();
            // console.log("1309 ServiceBusWrapper authenticated change useEffetct");
            authenticate(abortController)

            

            
            return () => {
                abortController.abort();
                // console.log("1309 ServiceBusWrapper authenticated change useEffect cleanup")
            }
        }
    }, [authenticated, offline])

    const value = {
        authenticated: authenticated,
        offline: offline
    };

    return <ServiceBusProvider value={value}>
        {children}
    </ServiceBusProvider>
}