import React, {useState, useEffect, Fragment} from 'react';
import Immutable from 'immutable';
import EventLog from './EventLog';
import EventListPanel from './EventListPanel';
import {getLog} from './Actions';
import DateTimeSelection from 'Controls/DateTimeSelection';
import AlarmLogFilter from './AlarmLogFilter';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';

function EventLogDialog({ show, onClose, ...props }) {
    return <Dialog opened={!!show} onClose={onClose} className="">
        <EventLogDialogPanel show={show} onClose={onClose} {...props} />
    </Dialog>
}

function EventLogDialogPanel({show, customer, onClose}) {
    const [log, setLog] = useState(Immutable.List([]));
    const [dateTimeRange, setDateTimeRange] = useState({
        rangeType:'Today',
        startDate:new Date(new Date().setHours(0,0,0)),
        endDate:new Date(new Date().setHours(23,59,59))
    });

    const [open, setOpen] = useState(false);
    const [type, setType] = useState(-1);

    useEffect(() => {
        if (customer && show) getLogRecords();
    }, [show, dateTimeRange, type]);

    const getLogRecords = () => { 
        let query = {}; 
        if (type > 0 ) query = {'type': type};
        
        getLog('event', `${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, query, dateTimeRange.startDate, dateTimeRange.endDate).then((data) => {
                let log = Immutable.fromJS(data.map((record) => {
                    return {
                        datetime: new Date(record.datetime).toLocaleString(), 
                        type: record.type,
                        panel: record.panel,
                        point: record.point,
                        event: record.event,
                        user: record.user
                    }
                }));

                setLog(log);
        });
    }
           
    return (
        <Fragment>
            <DialogContent className="md:w-[80rem]">
                <DialogHeader className="items-center">
                    <DialogTitle>Event Log</DialogTitle>
                    <div>
                        <AlarmLogFilter customer={customer} logtype={'event'} type={type} setType={(value) => setType(value)}
                                    dateTimeRange={dateTimeRange} setDateTimeRange={setDateTimeRange}/>  
                    </div>
                </DialogHeader>
                <DialogBody>
                    

                        <div className="px-3 py-2">
                            <EventListPanel records={log} />
                        </div>

                </DialogBody>
                <DialogFooter>
                    <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        </Fragment>
    );
}

export default EventLogDialog;
