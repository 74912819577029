import React from 'react';
import Swal from 'sweetalert2';
import Immutable from 'immutable';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';

import ModuleTypes from 'Configuration/ModuleTypes';
import Select from 'Controls/Form/Select';
import Input from 'Controls/Form/Input';
import Grid from 'Controls/GridV2';
import Button from 'Controls/Button/UserDefined';
import PointEditDialog from './PointEditDialog';
import {deletePoint} from '../Actions';
import {searchPoints} from 'BmsView/Customer/Site/Actions';

class PointEditor extends React.Component {
    constructor(props){
        super(props);

        let { customer } = this.props;
        let subsystems = customer.get('subsystems', Immutable.List([]));

        this.state = {
            editPoint: false,
            selectedSubsystemNumber: subsystems.get(0).get('number'),
            selectedSubsystemIndex: 0,
            selectedSubsystemType: subsystems.get(0).get('type'),
            selectedPointType : 0
        }
    }

    componentDidMount() {
        let {selectedSubsystemNumber} = this.state;
        this.searchPoints(selectedSubsystemNumber);
    }

    async searchPoints(selectedsubsystemnumber) {
        let {customer} = this.props;       
        let query = {};
       
        query['addr.0'] = parseInt(selectedsubsystemnumber);

        let points = await searchPoints(customer.get('name')
            .toLowerCase()
            .replace(/ /g, ""), customer.get('ipaddress').toLowerCase().replace(/ /g, ""), query);

        this.setState({
            points: Immutable.fromJS(points.map((point, index) => {
                return {
                    point:point,
                    index:index
                };
            }))
        });    
    }

    updatePointList() {
        let {selectedSubsystemNumber} = this.state;
        this.searchPoints(selectedSubsystemNumber);
    }

    
    displayPointEdit(selectedPoint) {
        // console.log('point editor displayPointEdit');
        this.setState({
            selectedpointforedit: selectedPoint.get('addr'),
            editPoint:true
        })
    }

    addPoint() {
        let engunittype = ModuleTypes[this.state.selectedSubsystemType].pointtypes[this.state.selectedPointType].engunit;

        let engunits = [''];

        if (engunittype == 'digital') {
            engunits = ['Off', 'On'];
        }

        this.setState( {
            selectedpointforedit: [this.state.selectedSubsystemNumber,this.state.selectedSubsystemType,this.state.selectedPointType,0,0,0,0,0,0,0],
            editPoint:false
        });
    }

    deletePoint(point) {
        let {customer} = this.props;

        let _id = point.get('_id');

        Swal.fire({
            title: `Are you sure you want to delete the point ${point.get('name')}?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value) {
                await deletePoint(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
                    `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, _id);

                let {points} = this.state;
            
                let pointsnew = points.filter((pointitem) => {
                    return _id != pointitem.get('point').get('_id');
                });
            
                this.setState({
                    points: pointsnew
                });    

                Swal.fire(
                    'Deleted!',
                    'The point has been deleted',
                    'success'
                );
            }
        }) 
    }

    subsystemNumberChanged(subsystem) {
        let {customer} = this.props;
        let subsystemNumber = customer.get('subsystems').get(subsystem).get('number');
        let subsystemType = customer.get('subsystems').get(subsystem).get('type');

        this.setState({
            selectedSubsystemNumber: subsystemNumber,
            selectedSubsystemIndex: subsystem,
            selectedSubsystemType: subsystemType,
            selectedPointType: 0
        });

        this.searchPoints(subsystemNumber);
    }

    pointTypeChanged(type) {
        this.setState({
            selectedPointType: parseInt(type)
        });
    }

    clearSelection(){
        this.setState({
            selectedpoint: undefined,
            editPoint:false,
            selectedpointforedit: undefined
        });
    }

    render() {
        let {editPoint, selectedpointforedit, points, selectedSubsystemType, selectedSubsystemNumber, selectedPointType, selectedSubsystemIndex} = this.state;
        let {customer} = this.props;
 
        var gridFields;
        if (!ModuleTypes[selectedSubsystemType].pointtypes[selectedPointType].fields)
            gridFields = undefined;
        else {
            if (ModuleTypes[selectedSubsystemType].pointtypes[selectedPointType].fields.length < 4)
                gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'col-span-16 md:col-span-6' }];
            else
                gridFields = [{ label: 'Name', value: 'name', name: 'Point', width: 'col-span-16 md:col-span-4' }];

            ModuleTypes[selectedSubsystemType].pointtypes[selectedPointType].fields.forEach((field, index) => {
                    gridFields.push({
                        label: field.label,
                        value: 'addr',
                        byte: index + 3,
                        name: field.label.toLowerCase(),
                        width: 'col-span-16 md:col-span-2'
                }); 
            })   
        }      
            
        return (
            <div className='container mx-auto my-4'>
                <PointEditDialog customer={customer} selectedItem={selectedpointforedit} editPoint={editPoint} isOpened={editPoint} onSave={this.updatePointList.bind(this)} onClose={this.clearSelection.bind(this)} />
                <div>
                    <h2>Point Editor</h2>
                </div>
                <div className="grid grid-cols-16 gap-2">
                    <div className="col-span-16 md:col-span-4">
                        <Input disabled={true} label="Customer" value={customer.get('name')}/>
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Select
                            label="Subsystem"
                            value={selectedSubsystemIndex}
                            onChange={this.subsystemNumberChanged.bind(this)}
                            options={customer.get('subsystems').map((subsystem, index) => {
                                return {
                                    value: index,
                                    text: subsystem.get('name')
                                }
                            })}
                        />           
                    </div>
                    <div className="col-span-16 md:col-span-4">
                        <Select
                            label="Point Type"
                            value={selectedPointType}
                            onChange={this.pointTypeChanged.bind(this)}
                            options={ModuleTypes[selectedSubsystemType].pointtypes.map((pointtype, index) => {
                                return {
                                    value: index,
                                    text: pointtype.name
                                }
                            })}
                        />
                    </div>
                </div>
                {!gridFields && <div><h3>Point Not Supported</h3></div>}
                {gridFields && 
                <div>
                    <Grid>
                        <Grid.Header key={gridFields.map((field) => field.label).join('-')}>
                            {gridFields.map((field, index) => {
                                return <Grid.Column key={index} classes={`${field.width}`}>{field.label}</Grid.Column>
                            })}
                            {/* <Grid.Column classes="col-span-16 md:col-span-2" name="edit"/> */}
                            <Grid.Column classes="col-span-16 md:col-span-4" name="add" type="complex">
                                <Grid.Controls>
                                    <Grid.Control>
                                        <PlusIcon className="w-6 text-white cursor-pointer" title="add new" onClick={this.addPoint.bind(this)} />
                                        {/* <Button role="success" >Add</Button> */}
                                    </Grid.Control>
                                </Grid.Controls>
                                {/* <Button role="success" className="w-100" onClick={this.addPoint.bind(this)}>Add New</Button> */}
                            </Grid.Column>
                        </Grid.Header>
                        <Grid.Data className="max-h-[70vh] overflow-y-scroll overflow-x-hidden border-b border-b-gray-300">
                            {((points || []).filter((point, index) =>
                                point.get('point').getIn(['addr', 0]) == Number(selectedSubsystemNumber) &&
                                point.get('point').getIn(['addr', 2]) == Number(selectedPointType))).map((point, index) => {
                                return (
                                    <Grid.Row key={index} className="hover:bg-gray-300 group">
                                        {gridFields.map((field, _index) => {
                                            return (field.byte ?
                                                <Grid.Column key={_index}>{point.get('point').getIn([field.value, field.byte])}</Grid.Column> :
                                                <Grid.Column key={_index}>{point.get('point').get(field.value)}</Grid.Column>
                                            )
                                        })} 
                                        <Grid.Column classes="col-span-16 md:col-span-4">
                                            <Grid.Controls>
                                                <Grid.Control><PencilSquareIcon title="Edit" className="text-gray-300 group-hover:text-gray-800 w-6 group-hover:hover:text-blue" onClick={this.displayPointEdit.bind(this, point.get('point'))} /></Grid.Control>
                                                <Grid.Control><TrashIcon title="Remove" className="w-6 text-gray-300 group-hover:text-gray-800 group-hover:hover:text-red-500" onClick={this.deletePoint.bind(this, point.get('point'))} /></Grid.Control> 
                                            </Grid.Controls>
                                        </Grid.Column>  
                                    </Grid.Row>
                                )
                            })}
                        </Grid.Data>
                    </Grid>  
                </div> }      
            </div>
        )
    }
}

export default PointEditor;
