import React from 'react';
import Grid from 'Controls/GridV2';
import HistoriesSetupDialog from 'BmsView/Customer/Histories/HistoriesTemplateSetupDialog';
import HistoriesDialog from 'BmsView/Customer/Histories/HistoriesDialog';
import {searchPoints} from 'BmsView/Customer/Site/Actions';
import Immutable from 'immutable';
import Swal from 'sweetalert2';
class Histories extends React.Component {
    
    selectTemplate(selectedtemplate, mode, index, evt){
        if (evt != 'close' && evt.target.classList.contains('btn-edit-histories') && !evt.currentTarget.classList.contains('btn-edit-histories')){
            return;
        }

        this.loadPoints(evt);

        this.setState({
            mode: mode,
            selectedtemplate: selectedtemplate,
            selectedindex: mode == 'edit' ? index : undefined
        })
    }

    async loadPoints(evt) {
        if (evt == 'close') {
            this.setState({
                points: undefined
            });
            return;
        }    

        let {customer, nodeconfiguration} = this.props;
        if (!nodeconfiguration) return;

        let customername = customer.get('name').toLowerCase().replace(/ /g, "");
        let ipaddress = customer.get('ipaddress').toLowerCase().replace(/ /g, "");

        let pointsToFetch = nodeconfiguration.getIn(['nodes'], Immutable.List([])).filter((node) => {
            if (node.get('type'))
                return !!node.getIn(['configuration', 'boundto']);
        }).map((node) => {
            return node.getIn(['configuration', 'boundto']).split('-').map((n) => {
                return parseInt(n);
            });
        });
        if (pointsToFetch && pointsToFetch.size > 0) {
            let points = await searchPoints(customername, ipaddress, { historysetup: { $exists: true } , "historysetup.enabled": { $eq: 1 }, addr: { $in: Immutable.Iterable.isIterable(pointsToFetch) ? pointsToFetch.toJS() : pointsToFetch } });

            this.setState({
                points: Immutable.fromJS(points)
            });
        }
    }

    clearSelection(){
        this.selectTemplate(undefined, undefined, undefined, 'close');
    }

    saveTemplate(selectedtemplate){
        if (!selectedtemplate.get('name')) {
            Swal.fire("Error", "Please enter template name", "error");
            return;
        }
        if (!selectedtemplate.get('points')) {
            Swal.fire("Error", "Add at least one point to the template", "error");
            return;
        }
        let {areaconfiguration, onSave} = this.props;
        if (!onSave){
            return;
        }
        let {selectedindex, mode} = this.state;
        if (!areaconfiguration.get('historiestemplates')){
            areaconfiguration = areaconfiguration.set('historiestemplates', Immutable.List([]));
        }

        if (mode == 'add'){
            areaconfiguration = areaconfiguration.update('historiestemplates', arr => arr.push(selectedtemplate));
        }
        if (mode == 'edit'){
            areaconfiguration = areaconfiguration.setIn(['historiestemplates', selectedindex], selectedtemplate);
        }

        onSave(areaconfiguration);
        this.clearSelection();
    }

    render() {
        let {customer, site, areaconfiguration} = this.props;
        let {selectedtemplate, mode, points} = this.state || {};
        return (
            <div className="p-2">
                <HistoriesSetupDialog points={points} customer={customer} site={site} usePortal={true} selecteditem={['edit', 'add'].indexOf(mode) >= 0 && selectedtemplate} onSave={this.saveTemplate.bind(this)} onClose={this.clearSelection.bind(this)}  />
                <HistoriesDialog usePortal={true} customer={customer} site={site} selecteditem={['display'].indexOf(mode) >= 0 && selectedtemplate} onClose={this.clearSelection.bind(this)} />
                <Grid>
                    <Grid.Header>
                        <Grid.Column classes='col-span-16 md:col-span-12'>History templates</Grid.Column>
                        <Grid.Column classes='col-span-16 md:col-span-4' name="edit"></Grid.Column>
                    </Grid.Header>
                    <Grid.Data>
                        {areaconfiguration.getIn(['historiestemplates'], Immutable.List([])).map((historiestemplate, index) => {
                            return (
                                <Grid.Row className="hover:bg-green-lighter" key={index} onClick={this.selectTemplate.bind(this, historiestemplate, 'display', index)}>
                                    <Grid.Column classes='col-span-16 md:col-span-12'>{historiestemplate.get('name')}</Grid.Column>
                                    <Grid.Column classes='col-span-16 md:col-span-4'><a className="block px-2 py-1 bg-blue hover:bg-blue-light text-white rounded w-full btn-edit-histories" onClick={this.selectTemplate.bind(this, historiestemplate, 'edit', index)}>Edit</a></Grid.Column>
                                </Grid.Row>
                            )
                        })}
                    </Grid.Data>
                </Grid>
                <div className="mt-2 clearfix">
                    <a className="block float-right px-2 py-1 bg-blue-light hover:bg-blue-lighter text-white rounded" onClick={this.selectTemplate.bind(this, Immutable.Map({}), 'add', undefined)}>Add</a>
                </div>
            </div>
        )
    }
}

export default Histories;