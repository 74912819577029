import { useTimezoneScreenStore } from '../store/useTimezoneScreenStore';


export default function useTimezoneState() {

    const events = useTimezoneScreenStore((state) => state.events);
    const setEvents = useTimezoneScreenStore((state) => state.setEvents);
    const dirty = useTimezoneScreenStore((state) => state.dirty);
    const setDirty = useTimezoneScreenStore((state) => state.setDirty);
    const resetState = useTimezoneScreenStore((state) => state.resetState);
    const isSelectedDayTemplate = useTimezoneScreenStore((state) => state.isSelectedDayTemplate);
    const setIsSelectedDayTemplate = useTimezoneScreenStore((state) => state.setIsSelectedDayTemplate);



    return { events, setEvents, dirty, setDirty, resetState, isSelectedDayTemplate, setIsSelectedDayTemplate }

}
