import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Item({ children, as, ...props }) {

    return <Menu.Item as={as || 'div'} {...props}>
        {children}
    </Menu.Item>
} 

export default function Dropdown({ title,children, alignRight = false, className = '', innerClassName = ''}) {
  
  const alignRightClass = alignRight ? 'right-0' : 'left-0';


  return (
    <Menu as="div" className="relative xl:inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full xl:justify-center xl:items-center px-6 py-3 text-[rgba(255,255,255,0.5)] aria-expanded:border-5 aria-expanded:text-[rgb(255,255,255)] hover:bg-[#1c3d5a] "> { /* */}
          { title }
          <ChevronDownIcon className=" ml-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`xl:absolute ${alignRightClass} ${innerClassName} z-10 w-full xl:min-w-min origin-top-right xl:rounded-md bg-white xl:shadow-lg xl:ring-1 xl:ring-black xl:ring-opacity-5 focus:outline-none`}>
            {children}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export function SubDropdown({ title,children, alignRight = false, className = '', innerClassName = ''}) {
  const alignRightClass = alignRight ? 'right-0' : 'left-0';


  return (
    <Menu as="div" className="relative xl:inline-block text-left w-full">
      <div>
        <Menu.Button className="inline-flex w-full justify-between xl:items-center aria-expanded:text-[rgb(0,0,0)]"> { /* */}
          { title }
          <ChevronRightIcon className=" ml-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items anchor="top end" className={` absolute translate-x-full -translate-y-full ${innerClassName} z-10 w-full xl:min-w-min origin-top-right xl:rounded-md bg-white xl:shadow-lg xl:ring-1 xl:ring-black xl:ring-opacity-5 focus:outline-none`}>
            {children}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
