import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Immutable, { List, OrderedMap } from 'immutable';
import axios from 'axios';
import AlarmDetailsListDialog from './AlarmDetailsListDialog';
import {updateAlarmRecord, acknowledgeAlarms, getAlarmRecord} from './../../Logs/Actions';
import Configuration from 'Configuration';
import ServiceBus from 'Services/ServiceBus';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { useUnacknowledgedAlarms } from '../../Context/UnacknowledgedAlarms';
import { useBMS } from '../../../Context/BMS';
import { useAlarmFilterGroups } from '../../Context/AlarmFilterGroups';
import { evaluateCondition } from '../../../../Components/Alarms/constants';

// alarm button

function getCurrentAlarmFilters(user, customer, site, alarmFilterGroups = OrderedMap() ) {

    // console.log("running getCurrentAlarmFilters", user, customer, site, alarmFilterGroups)

    let sites = user.get('sites');

    let newsite = `${customer.get('name').toLcHyphen()}:${site.get('name')}`;

    let currentsite = sites.filter((site) => {
        return site && (site.get('name') == newsite);
    });

    if (currentsite.size != 0 && currentsite.first().get('alarmfilter')) {
        let alarmfilter = currentsite.first().get('alarmfilter');

        // console.log('Filter ', alarmfilter);
        if (alarmfilter.get('segregationtype') && alarmfilter.get('segregationtype') == 2 && alarmfilter.get('filtergroups')) {
            let selectedFilterGroups = alarmfilter.get('filtergroups', List()) || List();
            selectedFilterGroups = selectedFilterGroups.map((filter) => {
                return alarmFilterGroups.get(filter);
            }).filter(x => x);
            return selectedFilterGroups
        }
    }

    return List();
}

const Alarms = ({customer, site, user, urlsound}) => {


    let [open, setOpen] = useState(false);
    let [openBanner, setOpenBanner] = useState(false);
    let [filterGroup, setFilterGroup] = useState();
    let [alarms, setAlarms] = useState(Immutable.List([]));
    const [alarmsToAcknowledge, setAlarmsToAcknowledge] = useState(0);
    const [alarmsAcknowledged, setAlarmsAcknowledged] = useState(0);
    const { unacknowledgedAlarms, refreshUnacknowledgedAlarms } = useUnacknowledgedAlarms({ customer });

    const { currentUser } = useBMS();
    const { alarmFilterGroups } = useAlarmFilterGroups();

    // console.log("Alarms", currentUser, alarmFilterGroups)
    
    let alarmSoundInterval = useRef(null);
    let alarmSound = useRef(new Audio(`/wav/${user.get('alarmsound', 'ding')}.wav`));

    let banner = user.get('alarmbanner', false);

    useEffect(() => {
        // console.log('%cAlarms Class Executed', 'color:green;');
        if (user.get('sites').size != 0) {
            let sites = user.get('sites');

            let newsite = `${customer.get('name').toLcHyphen()}:${site.get('name')}`;

            let currentsite = sites.filter((site) => {
                return site && (site.get('name') == newsite);
            });

            // console.log("Current Site in Alarm Mounts", currentsite);

            if (currentsite.size != 0 && currentsite.first().get('alarmfilter')) {
                let alarmfilter = currentsite.first().get('alarmfilter');

                // console.log('Filter ', alarmfilter);
                if (alarmfilter.get('alarmsegregationtype') && alarmfilter.get('alarmsegregationtype') == 2 && alarmfilter.get('alarmfiltergroup')) {
                    getAlarmFilterGroup(alarmfilter.get('alarmfiltergroup'));
                }
            } else {
                setFilterGroup(Immutable.Map({}));
            }  
        }  
    }, []);

    useEffect(() => {
        let unsubscribe = ServiceBus.on('BROADCAST_ALARM', (msg) => {
            addAlarm(msg);
        });

        return () => {
            unsubscribe();
            // ServiceBus.off('BROADCAST_ALARM', (msg) => {
            //     addAlarm(msg);
            // })
        };
        
     }, [filterGroup]);

    const getAlarmFilterGroup =  async (group)=> {
        let customername = customer.get('name').toLowerCase().replace(/ /g, "");
        let ipaddress = customer.get('ipaddress').toLowerCase();

        const query = {name: {$eq: group}};
        let alarmfilter = await axios.post(`/api/alarmfiltergroups/searchone?database=${Configuration.product}${customername}&connection=${ipaddress}`, { selector: query });

        if (!alarmfilter.data) {
            return;
        }

        setFilterGroup(Immutable.fromJS(alarmfilter.data));
    }


    const alarmTimer = () => {
        if(alarmSound.current) {
            alarmSound.current.play();
        }
    }

    useEffect(() => {



        let filteredAlarms = unacknowledgedAlarms.filter((alarm) => {
            if(alarm.getIn(['addr', 1]) == 5) { // for now trends should not be filtered
                return true;
            }
            
            if (filterGroup && filterGroup.get('subsystemenabled')) {
                let subsystem = filterGroup.get('subsystemfilter');

                if (subsystem != alarm.getIn(['addr',0])) return;
            }

            return true;
        });

        let siteAlarmFilterGroups = getCurrentAlarmFilters(user, customer, site, alarmFilterGroups);
        if (siteAlarmFilterGroups && siteAlarmFilterGroups.size > 0) {
            filteredAlarms = filteredAlarms.filter((alarm) => {
                if(alarm.getIn(['addr', 1]) == 5) { // for now trends should not be filtered
                    return true;
                }
                return siteAlarmFilterGroups.some((filter) => {
                    if(alarm.get('priority') == filter.get('priority') && filter.get('priorityenabled') && filter.get('alarmprofileenabled')) {
                        let shouldFilter = evaluateCondition(filter.get('prioritycondition'), filter.get('priority'), alarm.get('priority'));
                        if(shouldFilter) {
                            return true;
                        }
                    }
                    return false;
                });
            });
        }

        if(filteredAlarms.size > 0 && alarmSoundInterval.current == null) {
            alarmSoundInterval.current = setInterval(alarmTimer, 2000);
        }
        setAlarms(filteredAlarms);

        return () => {
            clearInterval(alarmSoundInterval.current);
            alarmSoundInterval.current = null;
            
        }
    }, [unacknowledgedAlarms, alarmFilterGroups])

    const addAlarm = async (msg) => {
        const abortController = new AbortController();
        await refreshUnacknowledgedAlarms(abortController);
        setOpenBanner(() => true);
    };

    const saveAlarm = async () => {
        await ackAlarms(alarms);
        // setAlarms(Immutable.List([]));
        clearInterval(alarmSoundInterval.current);      
        setOpen(false);
        setOpenBanner(false);
        alarmSoundInterval.current = null;      
    }

    const onAck = async (alarm, refresh = true) => {
        let alarmrecord = alarm.toJS();
        let ack = {
            user:`${user.get('firstname')} ${user.get('lastname') ? user.get('lastname') : ""}`,
            datetime:Date.now()
        };

        alarmrecord.ackedby ? alarmrecord.ackedby.push(ack):alarmrecord.ackedby = [ack];
        let newalarmrecord = Immutable.fromJS(alarmrecord);
        await updateAlarmRecord(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
            `${customer.get('ipaddress').toLowerCase()}`, newalarmrecord);

        if(refresh) {
            const abortController = new AbortController();
            refreshUnacknowledgedAlarms(abortController);
        }

    } 

    const waitABit = () => {
        return new Promise((resolve,reject) => {
            setTimeout(() => resolve(), 250);
        })
    }

    const ackAlarms = async (alarmslist) => {
        setAlarmsToAcknowledge(alarmslist.size);
        setAlarmsAcknowledged(0);
        let alarmsIdsList = alarmslist.map((alarm) => {
            return alarm.get('_id');
        });
        let ackBody = {
            user:`${user.get('firstname')} ${user.get('lastname') ? user.get('lastname') : ""}`,
            datetime:Date.now()
        }
        await acknowledgeAlarms(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        `${customer.get('ipaddress').toLowerCase()}`, alarmsIdsList, ackBody);

        // for (let i=0; i<alarmslist.size; i++) {
        //     // let alarmrecord = await getAlarmRecord(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        //     //     `${customer.get('ipaddress').toLowerCase()}`, alarmslist.get(i).get('docid'));

        //     let alarmrecord = alarmslist.get(i).toJS();

        //     let ack = {
        //         user:`${user.get('firstname')} ${user.get('lastname') ? user.get('lastname') : ""}`,
        //         datetime:Date.now()
        //     };

        //     alarmrecord.ackedby ? alarmrecord.ackedby.push(ack):alarmrecord.ackedby = [ack];
        //     let newalarmrecord = Immutable.fromJS(alarmrecord);

        //     await updateAlarmRecord(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
        //         `${customer.get('ipaddress').toLowerCase()}`, newalarmrecord);
        //     setAlarmsAcknowledged((x) => x+1);
        //         await waitABit();
                


        // };
        setAlarmsToAcknowledge(0);
        setAlarmsAcknowledged(0);
        const abortController = new AbortController();
        await refreshUnacknowledgedAlarms(abortController);
    }

    const onClose = () => {
        if(banner && openBanner) {
            setOpenBanner(false);
            
        }
        setOpen(false);
    }

    const siteAlarmFilterGroups = useMemo(() => {
        return getCurrentAlarmFilters(user, customer, site, alarmFilterGroups);
    },[user, customer, site, alarmFilterGroups]);

    

    // console.log("Site Alarm Filter Groups", siteAlarmFilterGroups)

    return (
        <>           
            <AlarmDetailsListDialog 
                alarmsAcknowledged={alarmsAcknowledged}
                alarmsToAcknowledge={alarmsToAcknowledge}
                onAck={onAck} 
                siteAlarmFilterGroups={siteAlarmFilterGroups} 
                selecteditem={(banner && openBanner && alarms.size > 0) || open} 
                alarms={alarms} 
                onSave={saveAlarm} 
                onClose={onClose} />
            {alarms.size > 0 && <AlarmButton>
                <div className="cursor-pointer text-white py-3 px-6 bg-red-500 rounded-lg" onClick={() => setOpen(true)}>
                    {/* {banner && openBanner && <AlarmDetailsListDialog  selecteditem={openBanner} onAck={onAck} alarms={alarms} onSave={() => saveAlarm()} onClose={()=>setOpenBanner(false)}/>} */}
                    <div className="relative">
                        <BellAlertIcon className="w-6 h-6 animate-ping" />
                        <BellAlertIcon className="w-6 h-6 absolute top-0 left-0" />
                    </div>
                    {/* <img className="cursor-pointer" onClick={() => setOpen(true)} src="/images/alarm-bell.svg" alt="Alarms"/> */}
                </div>
            </AlarmButton>
            }

        </>
    )
}

export default Alarms;

export function AlarmButton({ children }) {

    const elRef = useRef(document.createElement('div'));

    useEffect(() => {

        const el = elRef.current;
        const container = document.getElementById('alarm-button');
        container.appendChild(el);

        return () => {
            container.removeChild(el);
        }

    }, [])

    return ReactDOM.createPortal(
        children,
        elRef.current
    );


}