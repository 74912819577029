import React, { useState, useEffect, useRef, Fragment } from 'react';
import Input from 'Controls/Form/Input';
import Checkbox from 'Controls/Form/Checkbox';
import Select from 'Controls/Form/Select';
import FileInput from 'Controls/Form/FileInput';
import axios from 'axios';
import swal from 'sweetalert2';
import Immutable, { List, Map } from 'immutable';
import { uploadBackdrop, saveCustomerBackdrops } from '../../Actions';
import * as d3 from 'd3';
import ColorPicker from 'Controls/ColorPicker';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';


function AreaDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose}>
        <AreaDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog>
}

class AreaDialogPanel extends React.Component {
    constructor(props) {
        super(props);

        // props.setSaveHandler(this.saveHandler.bind(this));

        this.state = {
            selectedarea: props.selecteditem,
            caneditname: !props.selecteditem.get('name'),
            selectedcategory: 'general',
            formErrors: Immutable.Map(),
        }
    }

    async componentDidMount() {
        let { selectedarea } = this.state;

        this.getBackdrops();
        this.getComposedTemplates();
        let templates = undefined

        if(selectedarea.get('isV2')) {
            templates = await this.getV2Templates();
        } else {
            templates = await this.getTemplates();
        }

        if (selectedarea.get('template') && templates.filter((x) => { return x.get('value') == selectedarea.get('template') }).first()) {
            
            if(selectedarea.get('isV2')) {

                this.getTemplateV2Configuration(selectedarea.get('template'));
            }
            else {
           
                this.getTemplateConfiguration(selectedarea.get('template'));
            }
        }
    }

    async getTemplateConfiguration(template) {
        let { customer, site } = this.props;

        let configFileName = template.replace('.template.', '.config.');
        let path = `/files/customers/${customer.get('name').toLcHyphen()}_templates_${configFileName}`;

        let templateConfigResponse = await axios.get(path);

        let backdrop = Immutable.fromJS(templateConfigResponse.data.backdrop);

        this.setState(({ selectedarea }) => ({
            selectedarea: selectedarea.setIn(['backdrop'], backdrop),
            templatevariables: Immutable.fromJS(templateConfigResponse.data.variables)
        }));
    }

    async getTemplateV2Configuration(template) {
        let { customer, site } = this.props;

        let configFileName = template.replace('.template.', '.config.');
        let path = `/files/customers/${customer.get('name').toLcHyphen()}_templates-v2_${configFileName}`;

        let templateConfigResponse = await axios.get(path);

        let backdrop = Immutable.fromJS(templateConfigResponse.data.backdrop);

        this.setState(({ selectedarea }) => ({
            selectedarea: selectedarea.setIn(['backdrop'], backdrop),
            templatevariables: Immutable.fromJS(templateConfigResponse.data.variables)
        }));
    }

    async getComposedTemplates(forceCategory) {

        let { customer, site } = this.props;
        try {
            let globalComposedTemplateResponse = await axios.get('/files/editor/backdrops-v2.json');
            let globalComposedTemplates = globalComposedTemplateResponse.data;

           
            let { selectedarea } = this.state;
            let selectedcategory = 'general';

            for (let i in globalComposedTemplates) {
                globalComposedTemplates[i].backdrops.forEach((composedTemplate) => {
                    if (composedTemplate.file == selectedarea.getIn(['composedTemplate', 'file'])) {
                        selectedcategory = i;
                    }
                });
            }

            let siteComposedTemplates = [];
            try {
                let siteComposedTemplatesResponse = await axios.get(`/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}_backdrops-v2_backdrops-v2.json`);
                siteComposedTemplates = siteComposedTemplatesResponse.data;
    
                this.setState(({ composedTemplates }) => {
                    return {
                        composedTemplates: composedTemplates.setIn(['site-specific'], Immutable.Map({
                            name: "Site specific",
                            value: 'site-specific',
                            composedTemplates: Immutable.fromJS(siteComposedTemplates)
                        })),
                        selectedcategory: forceCategory || selectedcategory
                    }
                });
            } catch(err) {
                console.error(err);

            }

            this.setState({
                composedTemplates: Immutable.fromJS(globalComposedTemplates).setIn(['site-specific'], Immutable.Map({
                    name: "Site specific",
                    value: 'site-specific',
                    composedTemplates: Immutable.fromJS(siteComposedTemplates)
                })),
                siteComposedTemplates: Immutable.fromJS(siteComposedTemplates),
                selectedcategory: forceCategory || selectedcategory
            });


           
        }
        catch (err) {
            console.error(err);
            swal.fire("Error", "An error occurred while loading the backdrops, please contact the administrator.", "error");
        }
    }

    async getBackdrops(forceCategory) {

        let { customer, site } = this.props;
        try {
            let globalBackdropResponse = await axios.get('/files/editor/backdrops.json');
            let globalBackdrops = globalBackdropResponse.data;

            this.setState({
                backdrops: Immutable.fromJS(globalBackdrops)
            });

            let { selectedarea } = this.state;
            let selectedcategory = 'general';

            for (let i in globalBackdrops) {
                globalBackdrops[i].backdrops.forEach((backdrop) => {
                    if (backdrop.file == selectedarea.getIn(['backdrop', 'file'])) {
                        selectedcategory = i;
                    }
                });
            }

            let siteBackdropsResponse = await axios.get(`/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}_backdrops_backdrops.json`);
            let siteBackdrops = siteBackdropsResponse.data;

            this.setState(({ backdrops }) => {
                return {
                    backdrops: backdrops.setIn(['site-specific'], Immutable.Map({
                        name: "Site specific",
                        value: 'site-specific',
                        backdrops: Immutable.fromJS(siteBackdrops)
                    })),
                    selectedcategory: forceCategory || selectedcategory
                }
            });
        }
        catch (err) {
            console.error(err);
            swal.fire("Error", "An error occurred while loading the backdrops, please contact the administrator.", "error");
        }
    }

    async getV2Templates() {
        let { customer, site } = this.props;

        let templatesResponse = await axios.get(`/files/customers/${customer.get('name').toLcHyphen()}_templates-v2/listing`);
        let templates = templatesResponse.data.filter((name) => {
            return name.indexOf('.template.') >= 0;
        }).map((name) => {
            return {
                value: name,
                text: name.split('.')[0]
            }
        });

        templates = Immutable.fromJS(templates);
        this.setState({
            v2Templates: templates
        });

        return templates;
    }

    async getTemplates() {
        let { customer, site } = this.props;

        let templatesResponse = await axios.get(`/files/customers/${customer.get('name').toLcHyphen()}_templates/listing`);
        let templates = templatesResponse.data.filter((name) => {
            return name.indexOf('.template.') >= 0;
        }).map((name) => {
            return {
                value: name,
                text: name.split('.')[0]
            }
        });

        templates = Immutable.fromJS(templates);
        this.setState({
            templates
        });

        return templates;
    }

    saveHandler() {
        let { onSave } = this.props;
        let { selectedarea, selectedvariables} = this.state;

        if(selectedarea.get('isV2') == true) {
            onSave(selectedarea, selectedvariables);
            return;
        }
        
        if(!selectedarea.get('backdrop') && !selectedarea.get('isV2') != true) {
            swal.fire("Error", "Please select a backdrop or select clear background.", "error");
            return;
        }
        onSave(selectedarea, selectedvariables);
    }

    backdropChanged(file) {
        let { backdrops, selectedcategory } = this.state;

        let currentBackdrop = backdrops.getIn([selectedcategory, 'backdrops']).filter((backdrop) => {
            return backdrop.get('file') == file;
        }).first();

        // console.log("Backdrop should be changed.");

        this.valueChanged(['backdrop'], currentBackdrop);
    }

    composedTemplateChanged(file) {
        let { composedTemplates, selectedcategory } = this.state;

        let currentComposedTemplate = composedTemplates.getIn([selectedcategory, 'backdrops']).filter((composedTemplate) => {
            return composedTemplate.get('file') == file;
        }).first();

        // console.log("Backdrop should be changed.");

        this.valueChanged(['composedTemplate'], currentComposedTemplate);
    }

    valueChanged(field, value) {
        const { selectedarea } = this.state;
        if (field == 'template') {
            if(selectedarea.get('isV2')) {
                this.getTemplateV2Configuration(value);
            } else {
                this.getTemplateConfiguration(value);
            }
            
        }

        if(field == "name") {

            if(value && value.match(/(\\|\/)/)) {
                this.setState(({ formErrors }) => ({
                    formErrors: formErrors.set('name', 'The name cannot contain slashes.')
                }));
                return;
            }

        }


        if ( field == 'isV2' && value == true) {
            this.setState(({ selectedarea }) => ({
                selectedarea: selectedarea.setIn(field, value).setIn(['style','width'], 1920).setIn(['style','height'], 800).setIn(['backdrop'], true)
            }));
            return;
        }

        // console.log("field changed", field);
        // console.log("value", value)
        this.setState(({ selectedarea }) => ({
            selectedarea: selectedarea.setIn(field, value)
        }));
    }

    areaChanged(newArea) {
        this.setState(() =>
            ({
                selectedarea: newArea
            })
        );
    }

    categoryChanged(value) {

        this.setState({
            selectedcategory: value
        });
    }

    render() {
        let { formErrors, selectedarea, backdrops, caneditname, selectedcategory, templates, templatevariables, v2Templates, composedTemplates } = this.state;
        let { customer, site, currentUser, disableVersion, rotationEnabled } = this.props;

        let customerBackdropPath = `/files/customers/${customer.get('name').toLcHyphen()}_${site.get('name')}_backdrops`;
        let customerComposedTemplatePath = `/files/customers/${customer.get('name').toLcHyphen()}_${site.get('name')}_composed-templates`;
        
        //TODO: Templates are temporarily disabled, since the functionality is not fully implemented.
        // console.log("render area dialog", selectedarea.toJS());
        let availableTemplates = templates;
        if(selectedarea.get('isV2')) {
            availableTemplates = v2Templates
        }
        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Area Dialog</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="my-4">
                        <Input label="Name" error={formErrors.get('name')}  onChange={this.valueChanged.bind(this, ['name'])} value={selectedarea.get('name')} />
                        { currentUser.get('isSuperAdmin') && <Checkbox disabled={disableVersion} label="This area uses v2 graphics" onChange={this.valueChanged.bind(this, ['isV2'])} checked={selectedarea.get('isV2')} /> }
                        <Checkbox label="Hide node in the menu" onChange={this.valueChanged.bind(this, ['hidden'])} checked={selectedarea.get('hidden')} />
                        <Checkbox label="Status log enabled" onChange={this.valueChanged.bind(this, ['statuslogenabled'])} checked={selectedarea.get('statuslogenabled')} />
                        {rotationEnabled && <Checkbox label="Rotating area" onChange={this.valueChanged.bind(this, ['rotating'])} checked={selectedarea.get('rotating')} /> }
                        {/* { selectedarea.get('isV2') &&
                            <Fragment>
                                <Checkbox label="Backdrop" onChange={this.valueChanged.bind(this, ['backdrop'])} checked={!!selectedarea.get('backdrop')} />
                             </Fragment>
                        } */}
                        { selectedarea.get('isV2') &&
                            <Fragment>
                                <div className="grid grid-cols-16">
                                    <div className="col-span-16">Colours</div>
                                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedarea.getIn(['style', 'backgroundColor'])} onChange={this.valueChanged.bind(this, ['style', 'backgroundColor'])} /></div>
                                </div>
                                <AreaDimensions 
                                    onChange={this.areaChanged.bind(this)} 
                                    area={selectedarea} />
                            </Fragment>
                        }
                       
                        <Checkbox label="This node has a template." onChange={this.valueChanged.bind(this, ['hasTemplate'])} checked={selectedarea.get('hasTemplate')} />
                        {selectedarea.get('hasTemplate') && <Select
                            label="Template"
                            onChange={this.valueChanged.bind(this, ['template'])}
                            value={selectedarea.get('template')}
                            options={availableTemplates && availableTemplates.unshift(Immutable.Map({ value: "", text: "Select..." })) || []}
                        />}
                        {selectedarea.get('hasTemplate') && templatevariables && templatevariables.map((variable) => {
                            return (
                                <Input
                                    label={variable.get('displayname')}
                                    onChange={this.valueChanged.bind(this, ['templatevariables', variable.get('name')])}
                                    value={selectedarea.getIn(['templatevariables', variable.get('name')])}
                                />
                            )
                        })}
                        { !selectedarea.get('hasTemplate') && !selectedarea.get('isV2') && <Select
                            label="Category"
                            onChange={this.categoryChanged.bind(this)}
                            value={selectedcategory}
                            options={(backdrops || Immutable.List([])).valueSeq().map((backdrop) => {
                                return {
                                    value: backdrop.get('value'),
                                    text: backdrop.get('name')
                                }
                            })}
                        /> }
                        { !selectedarea.get('hasTemplate') && !selectedarea.get('isV2') && <div className="grid grid-cols-16 mt-4 gap-2">
                            {(backdrops && backdrops.getIn([selectedcategory, 'backdrops']) || []).map((backdrop, index) => {
                                let imagePath = backdrop.get('file').indexOf('~') == 0 ? `${customerBackdropPath}_${backdrop.get('file').replace('~', '')}.svg` : `/files/editor/backdrops_${backdrop.get('file')}.svg`
                                return (
                                    <div key={index} className="col-span-8 md:col-span-4" onClick={this.backdropChanged.bind(this, backdrop.get('file'))}>
                                        <div className={`rounded border text-center ${backdrop.get('file') == selectedarea.getIn(['backdrop', 'file']) && "bg-green-lightest border-green" || "border-grey-lighter"} hover:border-blue p-2`}>
                                            <img className="w-full" src={imagePath} />
                                            <h6 >{backdrop.get('name')}</h6>
                                        </div>
                                    </div>
                                )
                            })}
                        </div> }


                        { !selectedarea.get('hasTemplate') && selectedarea.get('isV2') && <Select
                            label="Category"
                            onChange={this.categoryChanged.bind(this)}
                            value={selectedcategory}
                            options={(composedTemplates || Immutable.List([])).valueSeq().map((composedTemplate) => {
                                return {
                                    value: composedTemplate.get('value'),
                                    text: composedTemplate.get('name')
                                }
                            })}
                        /> }
                        { !selectedarea.get('hasTemplate') && selectedarea.get('isV2') && <div className="grid grid-cols-16 min-w-full mt-4 gap-2">
                            {(composedTemplates && composedTemplates.getIn([selectedcategory, 'backdrops']) || []).map((composedTemplate) => {
                                let imagePath = composedTemplate.get('file').indexOf('~') == 0 ? `${customerComposedTemplatePath}_${composedTemplate.get('file').replace('~', '')}.png` : `/files/editor/backdrops-v2_${composedTemplate.get('file')}.png`
                                return (
                                    <div className="col-span-8 md:col-span-4" onClick={this.composedTemplateChanged.bind(this, composedTemplate.get('file'))}>
                                        <div className={`rounded border text-center ${composedTemplate.get('file') == selectedarea.getIn(['composedTemplate', 'file']) && "bg-green-lightest border-green" || "border-grey-lighter"} hover:border-blue p-2`}>
                                            <img className="w-full" src={imagePath} />
                                            <h6 >{composedTemplate.get('name')}</h6>
                                        </div>
                                    </div>
                                )
                            })}
                        </div> }

                        {/* <V2BackdropSelector active={selectedarea.get('isV2')} selectedarea={selectedarea} changeValue={this.valueChanged.bind(this)} /> */}

                        { selectedarea.get('hasTemplate') && !selectedarea.get('isV2') && selectedarea.get('backdrop') && <div className="grid grid-cols-16 gap-2 mt-4">

                            {(() => {
                                let backdrop = selectedarea.get('backdrop');
                                let imagePath = backdrop.get('file').indexOf('~') == 0 ? `${customerBackdropPath}_${backdrop.get('file').replace('~', '')}.svg` : `/files/editor/backdrops_${backdrop.get('file')}.svg`
                                return (
                                    <div className="col-span-8 md:col-span-4">
                                        <div className={`rounded border text-center ${backdrop.get('file') == selectedarea.getIn(['backdrop', 'file']) && "bg-green-lightest border-green" || "border-grey-lighter"} hover:border-blue p-2`}>
                                            <img className="w-full" src={imagePath} />
                                            <h6 >{backdrop.get('name')}</h6>
                                        </div>
                                    </div>
                                )
                            
                            })()}

                        </div> }

                        { !selectedarea.get('isV2') && <UploadBackdropInput onUploadFinish={() => this.getBackdrops('site-specific')} backdrops={(backdrops || List()).getIn([selectedcategory, 'backdrops']) || List()} customer={customer.get('name').toLcHyphen()} site={site.get('name')} showUploadField={selectedcategory == 'site-specific'}/> }
                    </div>
                    { !selectedarea.get('isV2') && <Button role="success" onClick={this.backdropChanged.bind(this, '')}>Remove Background</Button> }
                </DialogBody>
                <DialogFooter>
                    <div className="pl-1"><Button role="cancel" onClick={this.props.onClose}>Cancel</Button></div>
                    <div className="pl-1"><Button role="success" onClick={this.saveHandler.bind(this)}>Save</Button></div>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default AreaDialog;


// `/files/editor/components_${options.node.getIn(['component', 'name'])}.svg`

async function loadV2Backdrop(name) {
    let backdropXML = getEmptyBackdrop();
    let backdropResponse = await axios.get(`/files/editor/backdrops-v2_${name}.json`);
    let backdrop = Immutable.fromJS(backdropResponse.data);
    let images = [];
    backdrop.forEach((layer) => {
        layer.get('nodes').forEach((node) => {
            // assuming animation
            images.push(node);
        })
    })
    for(var i in images) {
        let image = images[i];
        let xml = await d3.xml(`/files/editor/components_${image.getIn(['component', 'name'])}.svg`);
        let d3Image = d3.select(xml.documentElement).select(`#layer1`);
        d3.select(backdropXML.documentElement).append('g').html(d3Image.html())
        .attr('x', image.getIn(['position','x']))
        .attr('y', image.getIn(['position','y']))
        .attr('transform', `rotate(${image.getIn(['configuration', 'rotation'], 0)},${image.getIn(['position', 'x'])},${image.getIn(['position', 'y'])}) translate(${image.getIn(['position', 'x'])}, ${image.getIn(['position', 'y'])})`)
        .attr('transform', `matrix(${image.getIn(['configuration', 'scale'], 100.0) / 100},0,0,${image.getIn(['configuration', 'scale'], 100.0) / 100},${image.getIn(['position', 'x'])},${image.getIn(['position', 'y'])})`)
    }

    return backdropXML;


}

function RadioInput({ label, name, currentValue, value, onChange, onBackdropSettingChange, backdrop }) {

    return <label forname={name}><input type="radio" name={name} checked={currentValue == value} onChange={() => onChange(value)} className="mr-2" />{label}</label>
}

function AreaDimensions({ area, onChange }) {

    
    const areaStyle = area.getIn(['style']) || Map();
    const backdrop = area.getIn(['backdrop']);
    const standardDimensions = {
        '1920-800': 'default',
        '1080-600': 'dialog' 
    }

    let currentWidth = areaStyle.get('width', 1920);
    let currentHeight = areaStyle.get('height', 800);

    let  currentDimension = 'custom';
    if(standardDimensions[`${currentWidth}-${currentHeight}`]) {
        currentDimension = standardDimensions[`${currentWidth}-${currentHeight}`];
    }

    if(!backdrop) {
        currentDimension = 'none';
    }



    return <div>
        <div>Presets</div>
        <div className="flex flex-row">
            <div className="flex flex-col px-4">
                <RadioInput label="None" name="none" currentValue={currentDimension} value="none" onChange={() => onChange(area.setIn(['style','width'], 0).setIn(['style','height'], 0).setIn(['backdrop'], false))}  />   
                <RadioInput label="Default" name="default" currentValue={currentDimension} value="default" onChange={() => onChange(area.setIn(['style','width'], 1920).setIn(['style','height'], 800).setIn(['backdrop'], true))}   />
                <RadioInput label="Dialog" name="dialog"  currentValue={currentDimension} value="dialog" onChange={() => onChange(area.setIn(['style','width'], 1080).setIn(['style','height'], 600).setIn(['backdrop'], true))} />
                <RadioInput label="Custom" name="custom"  currentValue={currentDimension} value="custom" onChange={() => onChange(area.setIn(['style','width'], 0).setIn(['style','height'], 0).setIn(['backdrop'], true))} />
            </div>
            <div className="px-4 grid grid-cols-2 gap-8">
                <Input label="Width" value={areaStyle.get('width') || currentDimension == 'none' && 0 || currentDimension == 'default' && 1920} onChange={(v) => onChange(area.setIn(['style','width'], v))} />
                <Input label="Height" value={areaStyle.get('height') || currentDimension == 'none' && 0 || currentDimension == 'default' && 800} onChange={(v) => onChange(area.setIn(['style','height'], v))} />
            </div>
        
        </div>
        
     
    </div>
}

function V2Thumbnail({ backdrop }) {

    // const thumbnail = useRef(null);
    const [dataUrl, setDataUrl] = useState('');

    useEffect(() => {
        loadV2Backdrop(backdrop).then((xml) => {

            let img = 'data:image/svg+xml;base64,';
            window.d3 = d3;
            // console.log(d3.select(xml.documentElement));

            let s = (new XMLSerializer()).serializeToString(d3.select(xml.documentElement).node());

            img += btoa(s);
            setDataUrl(img);

            // thumbnail.current.append(xml.documentElement);
        });
        
    }, [backdrop])

    return <img class="w-full" src={dataUrl} />

    // return <div ref={thumbnail}>

    // </div>

}

function getEmptyBackdrop() {
    let xml = { documentElement: d3.create('svg').attr('width', '1920').attr('height','800').node() };
    d3.select(xml.documentElement).append('g').attr('id', 'mainlayer');
    // xml.documentElement.classList.add('w-full');
    return xml;
}

async function getV2Backdrops() {
    let globalBackdropResponse = await axios.get('/files/editor/backdrops-v2.json');
    let globalBackdrops = Immutable.fromJS(globalBackdropResponse.data);
    return globalBackdrops;
}

function V2BackdropSelector({ active, selectedarea, changeValue }) {

    const [backdrops, setBackdrops] = useState(List());
    const [category, setCategory] = useState('');

    useEffect(() => {
        if(active) {
            getV2Backdrops().then((backdrops) => {
                setBackdrops(backdrops);
            });
        }
    }, [active])

    if(!active) {
        return null;
    }

    return <div>
         <Select
            label="Category"
            onChange={(value) => setCategory(value)}
            value={category}
            placeholder={'Select backdrop category'}
            options={(backdrops || Immutable.List([])).valueSeq().map((backdrop) => {
                return {
                    value: backdrop.get('value'),
                    text: backdrop.get('name')
                }
            })}
        />
        <div className="grid grid-cols-16 mt-4 gap-2">
            {(backdrops && backdrops.getIn([category, 'backdrops']) || []).map((backdrop) => {
                // let imagePath = backdrop.get('file').indexOf('~') == 0 ? `${customerBackdropPath}_${backdrop.get('file').replace('~', '')}.svg` : `/files/editor/backdrops_${backdrop.get('file')}.svg`
                return (
                    <div className="col-span-8 md:col-span-4" onClick={() => changeValue(['backdrop'], backdrop)}>
                        <div className={`rounded border text-center ${backdrop.get('file') == selectedarea.getIn(['backdrop', 'file']) && "bg-green-lightest border-green" || "border-grey-lighter"} hover:border-blue p-2`}>
                            {/* <img className="w-full" src={imagePath} /> */}
                            <V2Thumbnail backdrop={backdrop.get('file')} />
                            <h6 >{backdrop.get('name')}</h6>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>

}

function UploadBackdropInput({ showUploadField, customer, site, backdrops, onUploadFinish }) {

    const [file, setFile] = useState(undefined);
    const [directory, setDirectory] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = () => {
        let filename = name.toLowerCase().split(' ').join('-');
        let directoryName = (directory || "").split(' ').join('-');
        let extension = (file.name || "").replace(/.*?(\.\w*$)/,'$1');
        filename = directoryName ? `${directoryName}_${filename}` : filename;
        let filenameWithExtension = filename + extension;
        setIsUploading(true);
        uploadBackdrop(customer, site, filenameWithExtension, file).then(() => {
            backdrops = backdrops.push(Map({
                'name': name,
                "file": `~${filename}`,
                "layer": List().push(Map({
                    "name": "User 1 layer",
                    "value": "layer1user"
                })).push(Map({
                    "name": "User 2 layer",
                    "value": "layer2"
                }))
            }));
            return saveCustomerBackdrops(customer, site, backdrops)
        }).then(() => {
            setIsUploading(false);
            setFile(undefined);
            setDirectory(undefined);
            setName(undefined);
            onUploadFinish && onUploadFinish();
        })
    }

    if(!showUploadField) {
        return null;
    }

    if(isUploading) {
        return <React.Fragment>Uploading...</React.Fragment>
    }

    return <React.Fragment>
        <div className="grid grid-cols-16" style={{background:"#f8f8f8",padding:"10px",marginTop:"10px"}}>
            <h4 className="col-span-16">Upload new backdrop</h4>
            <div className="col-span-12 md:col-span-4">
                <FileInput style={{height:"auto"}} onChange={(file) => setFile(file)} />
            </div>
            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setName(value)} value={name} placeholder="Name"  />
            </div>
            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setDirectory(value)} value={directory} placeholder="Directory name" />
            </div>
            <div className="col-span-12 md:col-span-4">
                 <Button role="success" onClick={() => { uploadFile() }}>Upload</Button>
            </div>
        </div>
    </React.Fragment>

}