import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Immutable, { Map } from 'immutable';
import { connect } from 'react-redux';
import Dropdown, { Item as DropDownItem } from 'Controls/Dropdown';
import {saveEvent} from './../Actions/Common';
import ServiceBus from 'Services/ServiceBus';
import ChangePasswordDialog from 'BmsView/Users/ChangePasswordDialog';
import UserDisplayPropertiesDialog from 'BmsView/Users/UserDisplayPropertiesDialog';
import CustomerDisplayPropertiesDialog from 'BmsView/Customer/CustomerDisplayPropertiesDialog';
import { toggleEditmode } from 'BmsView/Actions';
import { useEditMode } from '../../Context/EditMode';
import { toggleLeftSlide } from 'BmsView/Layout/TopMenu/Actions';
import {logout} from 'Actions/Authentication'
// import history from './../History';
import { redirect } from 'react-router-dom';
import AboutDialog from './AboutDialog';
import DesktopService from 'Services/DesktopService';
import { PencilIcon, PencilSquareIcon, UserIcon } from '@heroicons/react/24/solid';
import { useBMS } from 'BmsView/Context/BMS';
import { useRevalidator } from 'react-router-dom';
import { routerDataService } from '../Context/BMS';
import SystemSettingsDialog from '../SystemSettings/SystemSettingsDialog';
import RealtimeDebuggerDialog from '../../System/RealtimeDebugger/RealtimeDebuggerDialog';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default connect((state) => ({
    editmode: state.get('editmode'),
    customer: state.get('customer'),
    leftslide_toggled: state.get('leftslide_toggled'),
    saveAreaHandle: state.get('saveAreaHandler'),
    platform: state.get('platform', Map())
}))(_NavBar);

function _NavBar({ children, editmode, leftslide_toggled, platform, saveAreaHandle, expanded, setExpanded }) {

    const contextRef = useRef();

    const { customer } = useBMS();
    const { currentUser } = useBMS();
    const { site } = useBMS();

    const revalidator = useRevalidator();


    const [user, setUser] = useState();
    const [aboutDialog, setAboutDialog] = useState(false);
    const [displayUserSettingsDialog, setDisplayUserSettingsDialog] = useState();
    const [displayCustomerSettingsDialog, setDisplayCustomerSettingsDialog] = useState();
    const [displaySystemSettingsDialog, setDisplaySystemSettingsDialog] = useState();
    const [displayRealtimeDebuggerDialog, setDisplayRealtimeDebuggerDialog] = useState();


    const toggleExpandedNavBar = () => {
        setExpanded(!expanded);
    }

    const displayAboutDialog = () => {
        setAboutDialog(true);
    }

    const _logout = async () => {
        ServiceBus.send('WEB_MESSAGE_REQ', {
            type:'DEREGISTER_FOR_ALARMS',
            target:'webserver'
        });

        if (customer) {
            let event = Immutable.Map({
                type:4,
                datetime:new Date(),
                name:currentUser.get('email'),
                event:`${currentUser.get('firstname')} ${currentUser.get('lastname') ? currentUser.get('lastname') : " "} disconnected`
            });

            await saveEvent(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, event);
        }
        
        await logout();
        // redirect('/');
        // console.log("resetting router data");
        routerDataService.resetRouterData();
        // console.log("revalidating");
        revalidator.revalidate();

    }


    contextRef.current = {
        saveAreaHandle: saveAreaHandle
    }

    useEffect(() => {

        const desktopMenuHandle = (menuAction) => {
            // console.log("receiving menu action", menuAction);
            switch (menuAction) {
                case 'settings-user-display-settings':
                    setDisplayUserSettingsDialog(() => true);
                break;
                case 'settings-customer-display-settings':
                    setDisplayCustomerSettingsDialog(() => true);
                break;
                case 'settings-change-password':
                    setUser(() => true);
                break;
                case 'settings-logout':
                    _logout();
                break;
                case 'editmode-toggle':
                    toggleEditmode();
                break;
                case 'editmode-save':
                    contextRef.current.saveAreaHandle();
                break;
            }
        }

        DesktopService.subscribe('menu-action', desktopMenuHandle);
        
        return () => {
            DesktopService.unsubscribe('menu-action', desktopMenuHandle);
        }

    }, [])

    useEffect(() => { 
        DesktopService.send('update-editmode-menu',  { editmode: editmode })
    }, [editmode])

    let platformName = platform.get('name', 'BmsView');

    let editmodeAuthorized = false;

    if (customer && site) {
        let signature = `${customer.get('name').toLcHyphen()}:${site.get('name').toLcHyphen()}`;
        let editAuthorization = currentUser.getIn(['sites'], Immutable.List([])).filter((siteauth) => {
            if (!siteauth) {
                return false;
            }
            return siteauth.get('name') == signature && siteauth.get('canedit');
        }).first();
        editmodeAuthorized = !!editAuthorization;
    }

    const mobileActiveClassName = expanded ? '' : 'hidden xl:flex';
    const defaultLinkClassName = classNames('text-gray-700 cursor-pointer','block px-4 py-1', 'hover:bg-[rgb(0,0,0,0.15)] whitespace-nowrap');

    useEffect(() => {
        DesktopService.send('update-editmode-save-menu',  { enabled: !!saveAreaHandle && editmodeAuthorized });
    }, [saveAreaHandle, editmode, editmodeAuthorized])
    

    let desktopClass = '';
    if(window && window.bmsDeskEnv) {
        desktopClass = 'absolute -top-full';
    }

    return <div className={`${desktopClass} flex py-2 px-4 bg-[rgb(18,40,58)] w-full flex-col xl:items-center xl:flex-row`}>
        <ChangePasswordDialog user={user} onClose={() => setUser(false)} currentUser={currentUser} />
        <AboutDialog opened={aboutDialog} onClose={() => setAboutDialog(false)} />
        <UserDisplayPropertiesDialog active={displayUserSettingsDialog} onClose={() => setDisplayUserSettingsDialog(false)} />
        <CustomerDisplayPropertiesDialog active={displayCustomerSettingsDialog} onClose={() => (setDisplayCustomerSettingsDialog(false))} />
        <SystemSettingsDialog active={displaySystemSettingsDialog} onClose={() => setDisplaySystemSettingsDialog(false)} />
        <RealtimeDebuggerDialog opened={displayRealtimeDebuggerDialog} onClose={() => setDisplayRealtimeDebuggerDialog(false)} />
        <div className="flex items-center">
            <div className="text-[white] font-bold text-xl py-3 xl:mr-4 flex-none" onClick={() => toggleLeftSlide()}>
                <BarIcon className="w-6 h-6 text-[#FFFFFF]" />
            </div>

            <div className="text-[white] font-bold text-xl py-3 xl:mr-4 flex-1">
                <Link to="/">{platformName}</Link>
            </div>

            <div className="text-[white] font-bold text-xl py-3 xl:mr-4 flex-none xl:hidden">
                <div className="px-2 py-1 border border-[#aaa] rounded-md" onClick={() => toggleExpandedNavBar()}>
                    <BarIcon className="w-6 h-6 text-[#aaa]" />
                </div>
            </div>
        </div>
        {children}
        <div className={`flex flex-col xl:flex-row ${mobileActiveClassName} gap-2`}>
            <div className="flex flex-row gap-2">
                <div id="rotation-button"></div>
                <div id="alarm-button"></div>
            </div>
            {editmodeAuthorized && !currentUser.get('isReadOnly') && 
                <div className="flex">
                    <EditModeButton editmode={editmode} onClick={() => toggleEditmode()} />
                </div>
            }
                {/* <AreaSaveButton saveAreaHandle={saveAreaHandle} /> */}
            <div id="area-save-button" className="flex"></div>
            {(currentUser.getIn(['isSuperAdmin']) || currentUser.getIn(['isAdmin'])) && <div>
                <Link 
                    to="/users" 
                    className="inline-flex w-full xl:justify-center items-center px-6 py-3 text-[rgba(255,255,255,0.5)] hover:text-[rgba(255,255,255,0.75)] hover:bg-[#1c3d5a]">
                        User management
                </Link>
            </div>}
            <Dropdown alignRight={true} title={<UserButton currentUser={currentUser} />}>
                <div className="px-4 py-2 border-b">Welcome, {currentUser.get('firstname')}</div>
                <DropDownItem className={defaultLinkClassName} onClick={() => setDisplayUserSettingsDialog(true)}>User display settings</DropDownItem>
                <DropDownItem className={defaultLinkClassName} onClick={() => setDisplayCustomerSettingsDialog(true)}>Customer display settings</DropDownItem>
                <DropDownItem className={defaultLinkClassName} onClick={() => setUser(true)}>Change Password</DropDownItem>
                {(currentUser.getIn(['isSuperAdmin']) || currentUser.getIn(['isAdmin'])) &&
                    <DropDownItem className={defaultLinkClassName} onClick={() => displayAboutDialog()}>About</DropDownItem>
                }
                {currentUser.getIn(['isSuperAdmin']) && <DropDownItem className={defaultLinkClassName} onClick={() => setDisplaySystemSettingsDialog(true)}>
                    System settings
                </DropDownItem> }
                {currentUser.getIn(['isSuperAdmin']) && <DropDownItem className={defaultLinkClassName} onClick={() => setDisplayRealtimeDebuggerDialog(true)}>
                    Realtime Debugger
                </DropDownItem> }
                <DropDownItem className={defaultLinkClassName} onClick={() => _logout()}>Logout</DropDownItem>
            </Dropdown>
        </div>
    </div>

}

function UserButton({  currentUser }) {
    // ${currentUser.get('firstname')}

    return <div className="flex flex-row gap-2 items-center">
        <UserIcon className="h-6 w-6 text-white" />
        <div className="hidden 2xl:block">
            {currentUser.get('firstname')}
        </div>
    </div>
}

function EditModeButton({ onClick, editmode }) {
    const { disableEditmode } = useEditMode();
    return <button 
        className={`w-full inline-flex items-center px-6 py-3 border border-transparent text-sm rounded-md text-white ${!editmode ? 'bg-[#666666] hover:bg-[#0062cc]' :  'bg-[#0ecc00] hover:bg-[#269e24]'} `} 
        disabled={disableEditmode} 
        onClick={onClick}>
            <PencilSquareIcon className={`w-5 h-5 mr-2`} />
            {editmode ? '- on' : <span className="text-white/40">- off</span>}
    </button>
}

export function BarIcon({ className }) {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={className}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>
}

export function DiskIcon({ className }) {
    
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={2}  fill="none" stroke="currentColor" className={className}>
        <g>
            <path strokeLinecap="round" strokeLinejoin="round" d="M22,22 H2 V3 A1,1,0,0,1,3,2 H7 V8 H17  V2 l5,4Z M7,2H17V8H7Z "/>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12,11a4,4,0,1,0,4,4A4,4,0,0,0,12,11Z"/>
        </g>
    </svg>
}

function AreaSaveButton({ saveAreaHandle }) { 
    return <button 
        className="w-full inline-flex items-center px-6 py-3 border border-transparent text-sm rounded-md text-white bg-[#007bff] hover:bg-[#0062cc]" 
        onClick={e => {
            // console.log("save clicked");
            saveAreaHandle()
        }}>
            <DiskIcon className="w-5 h-5 text-white" />
    </button>
}