import React, { useState, useEffect } from 'react';
import Calendar from 'Controls/Calendar';
import ServiceBus from 'Services/ServiceBus';
import Immutable, { Map, List, fromJS, isMap, set } from 'immutable';
import { convertDoubleToTimeString, convertTimeStringToDouble } from 'Utils/Timestamp';
import swal from 'sweetalert2';
import WeekTimes from '../../../../Configuration/Calendars/TimeTemplates/WeekTimes';
import { ModifyPeriodDialog, DayTemplateSelectionDialog } from '../../../../Configuration/Calendars/TimeTemplates/WeekTimesDialog';
import TimezoneDialog from './TimezoneDialog';
import PointGroupPanel from './PointGroupPanel';
import { usePointGroupInfo, usePointGroupManagementStore } from '../../../../Configuration/PointGroupManagement';
import MultipeTimeZonesConfirmDialog from './MultipleTimeZonesConfirmationDialog';
import { create } from 'zustand';
import saveToPanel from './shared/saveToPanel';
import getPin from './shared/getPin';

import { useTimezoneScreenStore } from './store/useTimezoneScreenStore';

import useTimezoneCompleteHandlers from './hooks/useTimezoneCompleteHandlers';
import useTimezoneHandlers from './hooks/useTimezoneHandlers';

import { getFormattedTimezone, removeCollidingElements, convertEvents, getTimeRangeToInfo } from './utils';

import requestTimezones from './shared/requestTimezones';
import useOnDayAction from './hooks/useOnDayAction';
import useTimezoneState from './hooks/useTimezoneState';
import PointGroupManagementButton from '../../../../Configuration/PointGroupManagement/Components/PointGroupManagementButton';
import PointGroupManagementDialog from '../../../../Configuration/PointGroupManagement/Components/PointGroupManagementDialog';
import UserDefinedButton from 'Controls/Button/UserDefined';


function Timezone({ customer, selectedpoint, address, currentuser }) {

    const [{
        selectediq2timezone, selectedtimezoneindex, selectedtimezone
    }, setTimezoneState] = useState({
        selectediq2timezone: undefined,
        selectedtimezoneindex: -1,
        selectedtimezone: undefined,
    });
    const [displaying, setDisplaying] = useState('current');
    const [selectedPeriod, setSelectedPeriod] = useState(undefined);

    const { 
        events, 
        setEvents, 
        setDirty, 
        dirty, 
        bacnetScheduleDefault, 
        resetState, 
        isSelectedDayTemplate, 
        setIsSelectedDayTemplate 
    } = useTimezoneState();

    const onDayAction = useOnDayAction();
    // const isSelectedDayTemplate = undefined // needs to be set in state as well.

    const isIQ2 = parseInt(address[2]) == 4
    useEffect(() => {
        requestTimezones({ customer, address });
        return () => {
            resetState();
        }
    }, [])

    useTimezoneCompleteHandlers({ customer, address});

    useTimezoneHandlers({ customer, address});

    const closePointGroupManagementDialog = usePointGroupManagementStore((state) => state.close);

    // used to be timezoneSelected
    const onSelectTimezone = (start, end) => {
        const selectedtimezone = getFormattedTimezone(start, end);
        const selectedtimezoneindex = -1;
        setTimezoneState({ selectedtimezone, selectedtimezoneindex });
        // both need to be set to state.
    }

    // used to be editTimezone
    const onEditTimezone = (toEdit, index) => {
        // both need to be set to state.
        const selectediq2timezone = toEdit;
        const selectedtimezoneindex = index;
        setTimezoneState({ selectediq2timezone, selectedtimezoneindex });
    }

    // used to be removeEvent
    const onRemoveEvent = (index) => {
        setEvents(events.setIn([index, 'removed'], true).setIn([index, 'dirty'], true));
        setDirty(true);
    }

    // used to be saveTimezone
    const onSaveTimezone = (timezone) => {
        // should be gotten from state
        // let { selectedtimezoneindex } = this.state;
        let originalEvents = events;
        //Find index nearest to time.
        let starttime = parseFloat(timezone.get('startindouble'));
        let endtime = parseFloat(timezone.get('endindouble'));
        let startindex = (starttime - (starttime % 0.5)) * 2;
        let endindex = (endtime - (endtime % 0.5)) * 2;
        if (selectedtimezoneindex < 0) {
            timezone = timezone.set('start', startindex).set('end', endindex);
            
            

            
            let convertedEvents = convertEvents(originalEvents);
            let newEvents = convertEvents(List().push(timezone));
            let convertedTimezone = newEvents.first();
            convertedEvents = removeCollidingElements(convertedTimezone, convertedEvents);
            convertedEvents.forEach((e,i) => {
                if(e.get('isDeleted')) {
                    originalEvents = originalEvents.update(i, x => x.set('removed', true).set('dirty', true))
                }
            })

            setEvents(originalEvents.push(timezone));
            setDirty(true);
            // set selectedtimezone to undefined
            setTimezoneState({ selectedtimezone: undefined });
            return;
           
        }
        
        
        let originalEvent = originalEvents.get(selectedtimezoneindex);
        originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', starttime).set('endindouble', endtime).set('dirty', true);
        let convertedEvents = convertEvents(originalEvents);
        let newEvents = convertEvents(List().push(timezone));
        let convertedTimezone = newEvents.first();
        convertedEvents = removeCollidingElements(convertedTimezone, convertedEvents);
        convertedEvents.forEach((e,i) => {
            if(e.get('isDeleted')) {
                originalEvents = originalEvents.update(i, x => x.set('removed', true).set('dirty', true))
            }
        })

        setEvents(originalEvents.set(selectedtimezoneindex, originalEvent));
        setDirty(true);
        setTimezoneState({ selectedtimezone: undefined });
        // set selectedtimezone to undefined
        
    }

    // used to be clearSelection
    const onClearSelection = () => {
        const selectedtimezone = undefined;
        const selectediq2timezone = undefined;
        setTimezoneState({ selectedtimezone, selectediq2timezone });
    }

    // used to be display
    const onDisplay = (name) => {
        const displaying = name;
        setDisplaying(displaying);
    }

    // used to be modifyPeriodDialogOnSave
    const onModifyPeriodDialogOnSave = (firstPeriod, secondPeriod) => {
        let index = firstPeriod.get('$originalIndex');
        let changedEvent = firstPeriod;//.merge(changes);
        let dow = changedEvent.get('dow');
        let starttime = changedEvent.get('starttime');
        let endtime = changedEvent.get('endtime');
        let [startindex, endindex, startindouble, endindouble] = getTimeRangeToInfo(starttime, endtime);
       
        let originalEvents = events;
        let originalEvent = events.get(index);
        originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', startindouble).set('endindouble', endindouble).set('dirty', true);
        let convertedEvents = convertEvents(events);
        convertedEvents = removeCollidingElements(changedEvent, convertedEvents);
        if(secondPeriod) {
            convertedEvents = removeCollidingElements(secondPeriod, convertedEvents);
        }
        convertedEvents.forEach((e,i) => {
            if(e.get('isDeleted')) {
                originalEvents = originalEvents.update(i, x => x.set('removed', true).set('dirty', true))
            }
        })
        if(secondPeriod) {
            let starttime = secondPeriod.get('starttime');
            let endtime = secondPeriod.get('endtime');
            let [startindex, endindex, startindouble, endindouble] = getTimeRangeToInfo(starttime, endtime);
            let newEvent = Map({
                start: startindex, 
                end: endindex, 
                startindouble: startindouble, 
                endindouble: endindouble,
                day: secondPeriod.get('dow'),
                starttimeindex: ((startindex - (startindex % 48)) / 48) * 24 + startindouble
            })
            originalEvents = originalEvents.push(newEvent);
        }

        setEvents(originalEvents.set(index, originalEvent));
        setDirty(true);
        setTimezoneState({ selectedtimezone: undefined });

    }

    // used to be modiyPeriodOnClose
    const onModifyPeriodDialogOnClose = () => {
        setSelectedPeriod(undefined);
    }

    const onApplyDayTemplate = (template) => {
        if(template) {
            let originalEvents = events;
            let dow = isSelectedDayTemplate - 1;
            let periods = template.get('periods', List());
            let newEvents = List();
            let isWholeWeek = isSelectedDayTemplate == 0;
            
            periods.forEach((period) => {
                let starttime = timeIndexToString60(period.get('startindex'));
                let endtime = timeIndexToString60(period.get('endindex'));
                let [startindex, endindex, startindouble, endindouble] = getTimeRangeToInfo(starttime, endtime);
                newEvents = newEvents.push(Map({
                    start: startindex, 
                    end: endindex, 
                    startindouble: startindouble, 
                    endindouble: endindouble,
                    day: isWholeWeek ? period.get('dow') : dow,
                    starttimeindex: ((startindex - (startindex % 48)) / 48) * 24 + startindouble,
                    dirty: true
                }))
            })
  
            originalEvents = originalEvents.map(x => {
                if(x.get('day') == dow || isWholeWeek) {
                    x = x.set("removed", true);
                    x = x.set("dirty", true);
                }
                return x;
            });
            
            originalEvents = originalEvents.merge(newEvents);
           
            setEvents(originalEvents);
            setDirty(true);

            // set isSelectedDayTemplate to undefined  
            setIsSelectedDayTemplate(undefined);
        } else {
            setIsSelectedDayTemplate(undefined);
        }
    }

    const onCloseChooseDayTemplate = () => {
        // set isSelectedDayTemplate to undefined
        setIsSelectedDayTemplate(undefined);
    }

    // togglePointGroupPanel => removed
    // selectGroup = removed
    // setSelectedGroups = removed
    // saveToPoints => removed

    let convertedEvents = convertEvents(events);

    // only show groups for bacnet schedules
    let showGroups = address && address[1] == 9 && address[2] == 17;
    let scheduleType = address && address[1] == 9 && address[2] == 17 ? 'Bacnet Schedule' : 'Schedule';
    // or trend 
    showGroups = showGroups || (address && address[1] == 5 && address[2] == 12);
    
    console.log("selectedpoint from timezones", selectedpoint);
    // console.log(this.props);

    return (
        <div className="flex flex-row">
            <div className="flex-1">
                <DayTemplateSelectionDialog 
                    showWeek={isSelectedDayTemplate === 0}
                    customer={customer} 
                    isOpened={isSelectedDayTemplate > -1} 
                    onSave={onApplyDayTemplate} 
                    onClose={onCloseChooseDayTemplate} /> 
                <ModifyPeriodDialog
                    tabindex={1}
                    isOpened={!!selectedPeriod}
                    onSave={onModifyPeriodDialogOnSave}
                    period={selectedPeriod}
                    onClose={onModifyPeriodDialogOnClose} />

                <TimezoneDialog 
                    selecteditem={selectediq2timezone} 
                    onClose={onClearSelection} 
                    onSave={onSaveTimezone} />
                <div className="px-2 py-1">
                    {isIQ2 && <>
                        <h2 className="mb-4">Displaying {displaying} week.</h2>
                    </>}
                    { !isIQ2 && <NonIQ2Calendar setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} /> ||

                        <Calendar 
                            onDayAction={onDayAction}
                            onSelect={onSelectTimezone} 
                            events={events} render={(props) => {
                            let { colheight, colwidth, events } = props;

                            if (!events) {
                                return <data></data>
                            }

                            return events.map((event, index) => {
                                let inbetween = event.get('end') - event.get('start');
                                let starttime = convertDoubleToTimeString(event.get('startindouble'));
                                let endtime = convertDoubleToTimeString(event.get('endindouble'));

                                if (displaying != event.get('type')) {
                                    return (
                                        <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                                            backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                                            width: `${colwidth * 0.95}px`,
                                            height: `${inbetween * colheight}px`,
                                            marginTop: `${(event.get('start') * colheight)}px`,
                                            marginLeft: `${(event.get('day')) * colwidth}px`,
                                            zIndex: 0
                                        }}></div>
                                    )
                                }

                                return (
                                    <div className={`event ${event.get('removed') && 'removed' || ''}`} style={{
                                        backgroundColor: event.get('type') == 'current' ? '#0088cc' : 'yellow',
                                        width: `${colwidth * 0.8}px`,
                                        height: `${inbetween * colheight}px`,
                                        marginTop: `${(event.get('start') * colheight)}px`,
                                        marginLeft: `${(event.get('day')) * colwidth}px`,
                                        zIndex: 1
                                    }} onDoubleClick={() => onEditTimezone(event, index)}>
                                        <div className="flex flex-row">
                                            <span className="title flex-grow">{starttime} - {endtime}</span>
                                            <a className="inline-block px-2 bg-red text-blue hover:bg-red-light" onClick={() => onRemoveEvent(index)}>x</a>
                                        </div>
                                    </div>
                                );
                            })
                        }} />

                    }
                    {isIQ2 && <div className="px-2 py-1 flex-row">
                        <div className="grid grid-cols-16 mt-4">
                            <div className="col-span-12 md:col-span-5 md:offset-6">
                                <div className="grid grid-cols-16 gap-4" onClick={() => onDisplay('current')}>
                                    <div className="col-span-4 md:col-span-2 px-4 py-3" style={{ backgroundColor: '#0088cc' }}></div>
                                    <div className="col-span-8 md:col-span-10 text-sm">Display current week</div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-5 md:offset-6 mt-2">
                                <div className="grid grid-cols-16  gap-2" onClick={() => onDisplay('standard')}>
                                    <div className="col-span-4 md:col-span-2 text-white px-4 py-3" style={{ backgroundColor: 'yellow' }}></div>
                                    <div className="col-span-8 md:col-span-8 text-sm">Display standard week</div>
                                </div>

                            </div>
                        </div>


                    </div>}
                </div>
                <PointGroupManagementDialog 
                    cursor={`TimezonesGroupPanel`}
                    onClose={() => closePointGroupManagementDialog({ cursor: 'TimezonesGroupPanel' }) } />
                {/* <div className="box px-4 py-3 mt-8 mb-8 flex clear-both items-center">
                    <div className="flex-1 text-center">
                        {bacnetScheduleDefault && <div>Default value: {bacnetScheduleDefault}</div> }
                    </div>
                    <div>
                        <PanelButtons 
                            customer={customer}
                            currentuser={currentuser}
                            events={events}
                            boundto={selectedpoint.getIn(['configuration','boundto'])}
                            dirty={dirty} 
                            onSaveToPanel={onSaveToPanel} />
                    </div>
                </div> */}
            </div>
        </div>
    )


}


function NonIQ2Calendar({ setSelectedPeriod, selectedPeriod }) {

    const [isSelectedDayTemplate, setIsSelectedDayTemplate] = useState(undefined);
    const [{
        selectediq2timezone, selectedtimezoneindex, selectedtimezone
    }, setTimezoneState] = useState({
        selectediq2timezone: undefined,
        selectedtimezoneindex: -1,
        selectedtimezone: undefined,
    });
    const [displaying, setDisplaying] = useState('current');
    // const [selectedPeriod, setSelectedPeriod] = useState(undefined);

    const { events, setEvents, setDirty } = useTimezoneState();
    const onDayAction = useOnDayAction();

    const onDayActionAdapter = (day, action) => {
        onDayAction(day + 1, action);
    }

    const onModifyRequestedAdapter = (event) => {
        const selectedPeriod = event;

        setSelectedPeriod(selectedPeriod);
    }

    const onRemoveMultipleAdapter = (toRemove) => {
        let originalEvents = events;    
        toRemove.forEach((event) => {
            let index = event.get(['$originalIndex']);
            originalEvents = originalEvents.setIn([index, 'removed'], true).setIn([index, 'dirty'], true);
        })
        setEvents(originalEvents);   
    }

    const onRemoveAdapter = (event) => {
        let index = event.get('$originalIndex');
        onRemoveEvent(index);
    }

    const onChangeEventAdapter = (event, changes) => {
        let index = event.get('$originalIndex');
        let changedEvent = event.merge(changes);
        let dow = changedEvent.get('dow');
        let starttime = changedEvent.get('starttime');
        let endtime = changedEvent.get('endtime');

        let [startHours, startMinutes] = starttime.split(':');
        let [endHours, endMinutes] = endtime.split(':');

        let startindouble = Number(startHours) + (Number(startMinutes) / 60);
        let endindouble = Number(endHours) + (Number(endMinutes) / 60);

        let startindex = (startindouble - (startindouble % 0.5)) * 2;
        let endindex = (endindouble - (endindouble % 0.5)) * 2;
        
        let originalEvents = events;
        let originalEvent = events.get(index);
        originalEvent = originalEvent.set('start', startindex).set('end', endindex).set('startindouble', startindouble).set('endindouble', endindouble).set('dirty', true);
        let convertedEvents = convertEvents(events);

        convertedEvents = removeCollidingElements(changedEvent, convertedEvents);
        convertedEvents.forEach((e,i) => {
            if(e.get('isDeleted')) {
                originalEvents = originalEvents.update(i, x => x.set('removed', true).set('dirty', true))
            }
        })

       
        setEvents(originalEvents.set(index, originalEvent));
        setDirty(true);
        const selectedtimezone = undefined;
        setTimezoneState({ selectedtimezone });
        

    }

    const onRangeSelectedAdapter = (range) => {
        range = range.sort((a,b) => a.get('dow') - b.get('dow'));
        let formattedEvents = List();
        range.forEach((block) => {
            let start = (block.get('dow') * 48) + Math.round(block.get('startindex') / 30);
            let end = (block.get('dow') * 48) + Math.round((block.get('endindex') - 30) / 30);
            end = end + 1;
            let hoursstart = start * 0.5;
            let hoursend = end * 0.5;
            let starttimeHours = hoursstart % 24;
            let endtimeHours = hoursend % 24;
            let startDay = (hoursstart - starttimeHours) / 24;
            let endDay = (hoursend - endtimeHours) / 24;
            if(endDay > startDay) {
                end = 48;
                endtimeHours = 24
            }
            formattedEvents = formattedEvents.push(Map({
                day: (start - (start % 48)) / 48,
                start: start % 48,
                end: (end % 48) < (start % 48) ? 48 : (end % 48),
                startindouble: starttimeHours,
                endindouble: endtimeHours,
                starttimeindex: ((start - (start % 48)) / 48) * 24 + starttimeHours,
                dirty: true
            }))

        });

        
        let convertedEvents = convertEvents(events);
        let originalEvents = events;

        // console.log("state of events before mutation", events);
        // console.log("state of formattedEvents", formattedEvents);

        formattedEvents = formattedEvents.forEach((evt) => {
            let starttime = parseFloat(evt.get('startindouble'));
            let endtime = parseFloat(evt.get('endindouble'));
            let startindex = (starttime - (starttime % 0.5)) * 2;
            let endindex = (endtime - (endtime % 0.5)) * 2;
            evt = evt.set('start', startindex).set('end', endindex);

            let newEvents = convertEvents(List().push(evt));
            let convertedTimezone = newEvents.first();
            convertedEvents = removeCollidingElements(convertedTimezone, convertedEvents);
            convertedEvents.forEach((e,i) => {
                if(e.get('isDeleted')) {
                    originalEvents = originalEvents.update(i, x => x.set('removed', true).set('dirty', true))
                }
            })

            originalEvents = originalEvents.push(evt)

        })

        // console.log("state of events", events);
        setEvents(originalEvents);
        setDirty(true);
        setTimezoneState({ selectedtimezone: undefined });
        
        

    }

    // used to be removeEvent
    const onRemoveEvent = (index) => {
        setEvents(events.setIn([index, 'removed'], true).setIn([index, 'dirty'], true));
        setDirty(true);
    }

    let convertedEvents = convertEvents(events);

    return <WeekTimes
        extraActions={['selectWeeklyTemplate']}
        onDayAction={onDayActionAdapter} 
        onModifyRequested={onModifyRequestedAdapter} 
        events={convertedEvents.filter(x => !x.get('removed'))} 
        onRemoveMultiple={onRemoveMultipleAdapter} 
        onRemove={onRemoveAdapter} 
        onChangeEvent={onChangeEventAdapter} 
        onRangeSelected={onRangeSelectedAdapter} />
}



export function PanelButtons({ dirty, onSaveToPanel, boundto, point, customer, currentuser, events }) {

    const [groupConfirmationPanel, setGroupConfirmationPanel] = useState(false);
    const { groups } = usePointGroupInfo({ boundto, customer, cursor: 'TimezonePanelButtons' });

    let group = groups?.get(0);
    const _onSaveToPanel = () => {
        if(groups.size > 0) {
            setGroupConfirmationPanel(true);
        } else {
            onSaveToPanel();
        }
    }

    console.log("group in panelButtons", group);
    console.log("groups in panelButtons", groups);
    

    return <div className="flex flex-row gap-2">
        <MultipeTimeZonesConfirmDialog 
        
            customer={customer}
            currentuser={currentuser}
            events={events}
            groups={groups}
            opened={groupConfirmationPanel} 
            onSaveSingleToPanel={onSaveToPanel}
            onClose={() => setGroupConfirmationPanel(false)} />

        { dirty && <SaveToPanelButton onSaveToPanel={_onSaveToPanel} /> || null}
        { group && <PointGroupManagementButton 
            point={point} 
            customer={customer}
            panelType="ViewPointsInGroup"
            editable={false}
            cursor="TimezonesGroupPanel">
                <UserDefinedButton role="primary">
                Group: {group.get('name')}
                </UserDefinedButton>
            </PointGroupManagementButton> || null }
    </div>
}

function SaveToPanelButton({ onSaveToPanel}) {
    return  <a 
        className="rounded-md px-3 py-2 bg-green text-white hover:bg-green-dark float-right cursor-pointer" 
        onClick={onSaveToPanel}>
            Save to panel
    </a>
}

function timeIndexToString60(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}

export default Timezone;