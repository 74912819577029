import { Fragment, useEffect } from 'react';
import { create } from 'zustand';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { fromJS, List, OrderedMap } from 'immutable';

import { getGroups } from 'BmsView/Customer/Configuration/Actions';
import { searchPoints } from 'BmsView/Customer/Site/Actions';

export const groupTypes = {
    'Bacnet Schedule': (addr) => addr[1] == 9 && addr[2] == 5,
    'Schedule': (addr) => addr[1] == 5 && addr[2] == 12
}


export const usePointGroupManagementStore = create((set) => ({
    
    cursors: OrderedMap(),
    open: async ({ customer, cursor, address, type, group, panelType, editable }) => {
        set((state) => ({ 
            cursors: state.cursors.update(cursor, OrderedMap(), (c) => {
                return c
                .set('loading', true)
                .set('editable', false)
                .set('availablePoints', List())
                .set('customer', undefined)
                .set('groups', OrderedMap())
                .set('isOpened', false)
                .set('panelType', '');
            }) 
        }))

        let groupInformation = undefined;
        let availablePoints = List();
        if (group) {
            groupInformation = {
                groupList: List([group]),
                groupHashMap: OrderedMap().set(group.get('_id'), group),
                groupHashMapByType: OrderedMap().set(group.get('type'), OrderedMap().set(group.get('_id'), group))
            }
            if(editable) {
                availablePoints = await fetchAvailablePoints(customer, group);
            }


        } else {
            groupInformation = await getGroupInformation(customer)
        }

        let { groupList, groupHashMap, groupHashMapByType } = groupInformation;
        let relevantGroups = OrderedMap();
        let groups = groupHashMap;

        if(type) {
            let typeGroups = groupHashMapByType.get(type, OrderedMap());
            groups = typeGroups;
        }


        if(address) {
            let point = await getSelectedPointInformation(customer, address);
            if(point) {
                let pointGroups = point.get('pointgroups');
                if(pointGroups) {
                    pointGroups.forEach((group) => {
                        let relevantGroup = groups.get(group.get('groupid'));
                        if(relevantGroup) {
                            relevantGroups = relevantGroups.set(group.get('groupid'), relevantGroup);
                        }
                    });
                }
    
            } 
           
            let pointScheduleType = getPointScheduleType(address);

            if(pointScheduleType) {
                relevantGroups = relevantGroups.filter((group) => {
                    return group.get('type') == pointScheduleType;
                }).map((group) => {
                    return group.update('points', OrderedMap(), points => points.filter((point) => {
                        let addr = point.get('addr').toArray();
                        if(addr[1] == address[1] && addr[2] == address[2]) {
                            return true;
                        }
                        return false;
                    }));
                });
            }


        }


        if(!address) {
            relevantGroups = groups;
        }
        relevantGroups = await addPointsToRelevantGroups(customer, relevantGroups);
        let relevantGroupsList = relevantGroups.toList();
        

        return set((state) => ({ 
            cursors: state.cursors.update(cursor, OrderedMap(), (c) => {
                return c
                .set('loading', false)
                .set('availablePoints', availablePoints)
                .set('editable', editable)
                .set('customer', customer)
                .set('groups', relevantGroupsList)
                .set('panelType', panelType)
                .set('isOpened', true);
            })
        }))
    },
    close: ({ cursor }) => set((state) => ({
        cursors: state.cursors.update(cursor, OrderedMap(), (c) => {
            return c.set('isOpened', false);
        })
    })),

}));

export function getPointScheduleType(address) {
    let pointScheduleType = undefined;
    Object.keys(groupTypes).forEach((type) => {
        if (groupTypes[type](address)) {
            pointScheduleType = type;
        }
    });
    return pointScheduleType;
}

export function usePointGroupInfo({ customer, boundto, cursor }) {


    const open = usePointGroupManagementStore((state) => state.open);
    const close = usePointGroupManagementStore((state) => state.close);
    const groups = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'groups']) || OrderedMap());

    useEffect(() => {
        if(boundto) {

            let address = boundto.split('-');
            let _cursor = cursor || 'usePointGroupInfo';
            open({ customer, cursor: _cursor, address });

            return () => {
                close({ cursor });
            }
        }

    }, [boundto])

    return {
        groups
    }

}

async function fetchAvailablePoints(customer, group) {
    console.log("trying to search points on server");
    let moduleTypeNumber = group.get('type') == 'Bacnet Schedule' ? 9 : 5;
    let pointTypeNumber = group.get('type') == 'Bacnet Schedule' ? 5 : 12;

    const customerUrl = `${customer.get('name').toLowerCase().replace(/ /g,"")}`;
    const customerIp = `${customer.get('ipaddress').toLowerCase()}`;

    let query = {};
    query['addr.1'] = moduleTypeNumber;
    query['addr.2'] = pointTypeNumber;

    console.log("searching points on server")
    let points = await searchPoints(customerUrl, customerIp, query);

    return fromJS(points);
}


async function addPointsToRelevantGroups(customer, relevantGroups) {

    if(relevantGroups.isEmpty()) {
        return relevantGroups;
    }

    let _points = await searchPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, {
        pointgroups: { $elemMatch: { groupid: { $in: relevantGroups.keySeq().toArray() } } }
    });
    let points = fromJS(_points);
    return relevantGroups.map((group) => {
        let groupPoints = points.filter((point) => {
            return point.get('pointgroups').some((pointGroup) => {
                return pointGroup.get('groupid') == group.get('_id');
            });
        });
        return group.set('points', groupPoints);
    });

}

async function getSelectedPointInformation(customer, address) {
    let _selectedpoints = await searchPoints(`${customer.get('name').toLowerCase().replace(/ /g, "")}`, `${customer.get('ipaddress').toLowerCase()}`, {
        addr: address.map((byte) => { return parseInt(byte) })
    });
    let selectedPoints = fromJS(_selectedpoints);
    let point = selectedPoints.first();

    return point;

}

async function getGroupInformation(customer) {
    let customerUrl = `${customer.get('name').toLowerCase().replace(/ /g, "")}`;
    let customerIp = `${customer.get('ipaddress').toLowerCase()}`;
    let _groups = await getGroups(`${customerUrl}`, `${customerIp}`, 'pointgroups');
    let groupList = fromJS(_groups);
    let groupHashMap = OrderedMap();
    let groupHashMapByType = OrderedMap();

    groupList.forEach((group) => {
        groupHashMap = groupHashMap.set(group.get('_id'), group);
        let type = group.get('type');
        let typeList = groupHashMapByType.get(type, OrderedMap());
        typeList = typeList.set(group.get('_id'), group);
        groupHashMapByType = groupHashMapByType.set(type, typeList);
    });

    return {
        groupList,
        groupHashMap,
        groupHashMapByType
    }

}

