import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { fromJS, List, OrderedMap, Map } from 'immutable';
import { usePointGroupManagementStore } from '..';
import { searchPoints, savePoint } from '../../Actions';
import UserDefinedButton from 'Controls/Button/UserDefined';
import ListSelection from 'Controls/ListSelection';

export default function PointGroupManagementDialog({ cursor, onClose }) {

    const isOpened = usePointGroupManagementStore((state) => !!(state.cursors.getIn([cursor, 'isOpened']) || false));

    return (
        <Dialog opened={isOpened} onClose={onClose}>
            <PointGroupManagementDialogPanel cursor={cursor} />
        </Dialog>
    );
}

function PointGroupManagementDialogPanel({ cursor }) {

    const panelType = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'panelType']) || '');
    const close = usePointGroupManagementStore((state) => state.close);
    
    let panel = <div />
    if(panelType == 'EditPointsInGroup') {
        return <EditPointsInGroupPanel cursor={cursor} onClose={() => close({ cursor: cursor }) } />
    } else if(panelType == 'ViewPointsInGroup') {
        return <ViewPointsInGroupPanel cursor={cursor} onClose={() => close({ cursor: cursor }) } />
    }


    return null;

}


function usePointMutations({ availablePoints, group }) {

    const [pointsInGroup, setPointsInGroup] = useState(OrderedMap());
    const [pointsOutGroup, setPointsOutGroup] = useState(OrderedMap());

    useEffect(() => {
        if(availablePoints.size == 0 || !group) {
            return;
        }
        let pointsInGroup = OrderedMap();
        let pointsOutGroup = OrderedMap();
        pointsInGroup = group.get('points').reduce((acc, point) => {
            return acc.set(point.get('_id'), point.set('$initialInGroup', true));
        }, OrderedMap());
        pointsOutGroup = availablePoints.reduce((acc, point) => {
            if(pointsInGroup.has(point.get('_id'))) {
                return acc;
            }
            return acc.set(point.get('_id'), point);
        }, OrderedMap());
        setPointsInGroup(pointsInGroup);
        setPointsOutGroup(pointsOutGroup);
    }, [availablePoints, group]);


    const addPointToGroup = (point) => {
        setPointsInGroup((pointsInGroup) => {
            return pointsInGroup.set(point.get('_id'), point);
        });
        setPointsOutGroup((pointsOutGroup) => {
            return pointsOutGroup.delete(point.get('_id'));
        });
    }

    const removePointFromGroup = (point) => {
        setPointsInGroup((pointsInGroup) => {
            return pointsInGroup.delete(point.get('_id'));
        });
        setPointsOutGroup((pointsOutGroup) => {
            return pointsOutGroup.set(point.get('_id'), point);
        });
    }

    return {
        pointsInGroup,
        pointsOutGroup,
        addPointToGroup,
        removePointFromGroup
    }
}

function ViewPointsInGroupPanel({ cursor, onClose }) {

    const groups = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'groups']) || List());
    const customer = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'customer']) || fromJS({}));
    const group = groups.first();

    return <Fragment>
        <DialogHeader>
            <DialogTitle>Point in group</DialogTitle>
        </DialogHeader>
        <DialogBody>
            <DialogContent>
                <div>
                    <div className={`font`}>{group.get('name')}</div>
                    <div>
                        <div>
                            <div className="">
                                {group.get('points').map((point, index) => {
                                    return <div className="">{point.get('name')}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                </DialogContent>
        </DialogBody>
        <DialogFooter>
            <UserDefinedButton role="primary" onClick={onClose}>Close</UserDefinedButton>
        </DialogFooter>
    </Fragment>
}


function EditPointsInGroupPanel({ cursor, onClose }) {
    const groups = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'groups']) || List());
    const customer = usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'customer']) || fromJS({}));
    const availablePoints =  usePointGroupManagementStore((state) => state.cursors.getIn([cursor, 'availablePoints']) || List());
    const group = groups.first();

    const { 
        pointsInGroup, 
        pointsOutGroup, 
        addPointToGroup, 
        removePointFromGroup 
    } = usePointMutations({ availablePoints, group });

    const onSave = async () => {

        let pointsAdded = pointsInGroup.filter((point) => {
            return !point.get('$initialInGroup');
        })

        let pointsRemoved = pointsOutGroup.filter((point) => {
            return point.get('$initialInGroup') == true;
        })



        let pointsToSave = List();
        pointsAdded.forEach((point) => {
            point = point.update('pointgroups', List(), (x) => {
                return x.push(Map({ groupid: group.get('_id'), type: group.get('type') }));
            })
            pointsToSave = pointsToSave.push(point);
        });

        pointsRemoved.forEach((point) => {
            point = point.update('pointgroups', List(), (x) => {
                return x.filter((_group) => {
                    return _group.get('groupid') != group.get('_id');
                });
            })
            pointsToSave = pointsToSave.push(point);
        });

        
        let customerUrl = customer.get('name').toLowerCase().replace(/ /g, "");
        let ipAddress = customer.get('ipaddress').toLowerCase();

        if(pointsToSave.size > 0) {

            do {
                let point = pointsToSave.first();
                point = point.delete('$initialInGroup');
                await savePoint(customerUrl, ipAddress, point);
                pointsToSave = pointsToSave.skip(1);
            } while(pointsToSave.size > 0);


        }


    }

    const onListChange = (leftList, rightList) => { 
        leftList.forEach((point) => {
            if(!pointsInGroup.has(point.get('_id'))) {
                addPointToGroup(pointsOutGroup.get(point.get('_id')));
            }
        });

        rightList.forEach((point) => {
            if(pointsInGroup.has(point.get('_id'))) {
                removePointFromGroup(pointsInGroup.get(point.get('_id')));
            }
        });

    }

    const leftList = useMemo(() => {
        return pointsInGroup.toList().map(x => x.set('label', x.get('name')));
    }, [pointsInGroup]);

    const rightList = useMemo(() => {
        return pointsOutGroup.toList().map(x => x.set('label', x.get('name')));
    }, [pointsOutGroup]);

    return <Fragment>
        <DialogHeader>
            <DialogTitle>Point Group Management</DialogTitle>
        </DialogHeader>
        <DialogBody>
            <DialogContent>
                <div>
                    <div className={`font`}>{group.get('name')}</div>
                    <div>
                      
                        <div>
                            <div className="">
                                
                                <ListSelection 
                                    boxContainerClassName={"max-h-96"}
                                    leftLabel="Points in Group"
                                    rightLabel="Points Available"
                                    leftList={leftList} 
                                    rightList={rightList} 
                                    onChange={onListChange} />
                           
                            </div>
                        </div>
                    </div>
                </div>
                </DialogContent>
        </DialogBody>
        <DialogFooter>
            <UserDefinedButton role="primary" onClick={onClose}>Close</UserDefinedButton>
            <UserDefinedButton role="success" onClick={onSave}>Save</UserDefinedButton>
        </DialogFooter>
    </Fragment>
}