import React from 'react';
import {fromJS} from 'immutable';
import Input from 'Controls/Form/Input';
import TextArea from 'Controls/Form/TextArea';
import Gradient from './Gradient';
import ColorPicker from 'Controls/ColorPicker';

class EzyButton extends React.Component {
    valueChanged(field, int, value) {
        let { onChange } = this.props;

        if (int) value = parseInt(value);

        onChange(field, value);
    }

    gradientChange(field, value) {
        let { selectedcomponent, onChange } = this.props;
        let grads = fromJS([
            { "stop": "0%", "colour":"#00aad4" },
            { "stop": "100%", "colour":"#004050"}]);

        let gradsHeader = fromJS([
            { "stop": "0%", "colour":"#5fbcd3" },
            { "stop": "100%", "colour":"#00aad4"}]);

        // this is to take care of pre-existing ezy buttons
        if (!selectedcomponent.getIn(['configuration', 'gradient'])) {
            onChange(['configuration', 'gradient'], true);
            onChange(['configuration', 'grads'], grads);
            onChange(['configuration', 'gradientHeader'], true);
            onChange(['configuration', 'gradsHeader'], gradsHeader);
        }

        onChange(field, value);
    }

    render() {
        let { selectedcomponent} = this.props;

        let grads = fromJS([
            { "stop": "0%", "colour":"#00aad4" },
            { "stop": "100%", "colour":"#004050"}]);

        let gradsHeader = fromJS([
            { "stop": "0%", "colour":"#5fbcd3" },
            { "stop": "100%", "colour":"#00aad4"}]);

        return (
            <div className="my-4">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 md:col-span-4">
                        <TextArea
                            label="Label"
                            rows="2"
                            value={selectedcomponent.getIn(['configuration','value'], '')}
                            onChange={this.valueChanged.bind(this, ['configuration', 'value'], false)}
                        />
                    </div>    
                </div>
                <div className="grid grid-cols-12 gap-2">   
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            label="Scale %"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'scale'], '') || ''}
                            onChange={this.valueChanged.bind(this, ['configuration', 'scale'], true)}
                        /> 
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 my-2">
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            label="Border Width"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'borderwidth'], 4)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'borderwidth'], true)}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2">
                        <Input
                            label="Radius Corner"
                            type="number"
                            value={selectedcomponent.getIn(['configuration', 'ry'], 45)}
                            onChange={this.valueChanged.bind(this, ['configuration', 'ry'], true)}
                        />
                    </div>
                </div> 
                <div className="grid grid-cols-12 gap-2"> 
                    <div className="col-span-4">
                        <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradient'], true)} label={"Button Gradient"} gradientField={""}
                            gradients={selectedcomponent.getIn(['configuration', 'grads'], grads)}
                            defaultGrads={grads}
                            onChange={this.gradientChange.bind(this)}/>
                    </div>
                    <div className="col-span-4">
                        <Gradient gradient={selectedcomponent.getIn(['configuration', 'gradientHeader'], true)} label={"Header Gradient"} gradientField={"Header"}
                            gradients={selectedcomponent.getIn(['configuration', 'gradsHeader'], gradsHeader)}
                            defaultGrads={gradsHeader}
                            onChange={this.gradientChange.bind(this)}/>  
                    </div>
                    <div className="col-span-4"/>
                </div>  
                <div className="grid grid-cols-16 mt-8 gap-2">
                    <div className="col-span-16">Colours</div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Background" value={selectedcomponent.getIn(['configuration', 'style', 'background-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'background-color'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Text" value={selectedcomponent.getIn(['configuration', 'style', 'color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'color'], false)} /></div>
                    <div className="col-span-16 md:col-span-2"><ColorPicker label="Border" value={selectedcomponent.getIn(['configuration', 'style', 'border-color'])} onChange={this.valueChanged.bind(this, ['configuration', 'style', 'border-color'], false)} /></div>
                </div>          
            </div>
        )
    }
}

export default EzyButton;