import { useEffect } from 'react';
import ServiceBus from 'Services/ServiceBus';
import { useTimezoneScreenStore } from '../store/useTimezoneScreenStore';
import { fromJS } from 'immutable';

export default function useTimezoneHandlers() {

    const { setEvents, setDirty, setBacnetScheduleDefault } = useTimezoneScreenStore();

    useEffect(() => {

        const iq2Handler = (message) => {
            let { events, dirty } = getTimezonesIQ2StateFromMessage(message);
            setEvents(events);
            setDirty(dirty);
        }

        const iq3Handler = (message) => {
            let { events, dirty } = getTimezonesIQ3StateFromMessage(message);
            setEvents(events);
            setDirty(dirty);
        }

        const bacnetHandler = (message) => {
            let { events, bacnetScheduleDefault, dirty } = getTimezonesBacnetStateFromMessage(message);
            setEvents(events);
            setBacnetScheduleDefault(bacnetScheduleDefault);
            setDirty(dirty);
        }

        const ucc4Handler = (message) => {
            let { events, dirty } = getTimezonesUCC4StateFromMessage(message);
            setEvents(events);
            setDirty(dirty);
        }

        ServiceBus.on('505-51', iq2Handler);
        ServiceBus.on('507-51', iq3Handler);
        ServiceBus.on('24-151', bacnetHandler);
        ServiceBus.on('0-121', ucc4Handler);

        return () => {
            ServiceBus.off('505-51', iq2Handler);
            ServiceBus.off('507-51', iq3Handler);
            ServiceBus.off('24-151', bacnetHandler);
            ServiceBus.off('0-121', ucc4Handler);
        }

    }, [])


    return null;
}


function getTimezonesIQ3StateFromMessage(msg) {
    let events = [];

    for (let dow = 0; dow < msg.m_prds.length; dow++) {
        let dayProfile = msg.m_prds[dow];

        let length = dayProfile.length;

        // console.log('dayprofile', dayProfile);

        if (length % 2) length--;

        for (let period = 0; period < length; period += 2) {
            let startInDouble = dayProfile[period].h + ((Math.round(dayProfile[period].m / 5) * 5) / 60);
            let endInDouble = dayProfile[period + 1].h + ((Math.round(dayProfile[period + 1].m / 5) * 5) / 60);
            events.push({
                id: dow + "-" + period,
                day: dow,
                start: Math.floor(startInDouble * 2),
                end: Math.floor(endInDouble * 2),
                startindouble: startInDouble,
                endindouble: endInDouble,
                starttimeindex: dow * 24 + startInDouble,
                type: 'current'
            });
        }
    }

    return { events: fromJS(events), dirty: false }
}

function getTimezonesIQ2StateFromMessage(msg) {
    let events = [];

    for (let dow = 0; dow < msg.m_day.length; dow++) {
        let dayProfile = msg.m_day[dow];
        for (let i = 1; i < 3; i++) {
            if (dayProfile['bH' + i] != dayProfile['eH' + i] || dayProfile['bM' + i] != dayProfile['eM' + i]) {

                let startInDouble = dayProfile['bH' + i] + ((Math.round(dayProfile['bM' + i] / 5) * 5) / 60);
                let endInDouble = dayProfile['eH' + i] + ((Math.round(dayProfile['eM' + i] / 5) * 5) / 60);

                events.push({
                    id: dow + "-" + i,
                    day: dow % 7,
                    start: Math.floor(startInDouble * 2),
                    end: Math.floor(endInDouble * 2),
                    startindouble: startInDouble,
                    endindouble: endInDouble,
                    starttimeindex: (dow % 7) * 24 + startInDouble,
                    type: dow >= 7 ? 'standard' : 'current'
                });
            }
        }   
    }
    return { events: fromJS(events), dirty: false }
}

function getTimezonesUCC4StateFromMessage(msg) {
    let events = [];

    for (let dow = 0; dow < 7; dow++) { 
        let dayProfile = msg.m_scheduleTimes[dow];

        let startInDouble = dayProfile.m_bHr + ((Math.round(dayProfile.m_bMn / 5) * 5) / 60);
        let endInDouble = dayProfile.m_eHr + ((Math.round(dayProfile.m_eMn / 5) * 5) / 60);          
        events.push({
            id: dow, // + "-" + period,
            day: dow,
            start: Math.floor(startInDouble * 2),
            end: Math.floor(endInDouble * 2),
            startindouble: startInDouble,
            endindouble: endInDouble,
            starttimeindex: dow * 24 + startInDouble,
            type: 'current'
        });
    }

    return { events: fromJS(events), dirty: false }
} 

function getTimezonesBacnetStateFromMessage(msg) {
    let events = [];
    let originalPeriods = [...msg.m_prds];
    const convertPeriods = (periods) => {
        let newPeriods = [[], [], [], [], [], [], []];
        for(let dow = 0; dow < periods.length; dow++) {
            let currentDay = periods[dow];
            let lastRecord = currentDay[currentDay.length - 1];
            let firstRecord = currentDay[0];
            if(!firstRecord) {
                continue;
            }
            if(firstRecord.v == 0 && firstRecord == lastRecord) {
                newPeriods[dow] = [];
                continue;
            } else if(firstRecord.v == 0 && firstRecord.m == 0 && firstRecord.h == 0) {
                newPeriods[(dow + 6) % periods.length].push({
                    h: 24,
                    m: 0,
                    v: 1
                });
                newPeriods[dow].push(...currentDay.filter(x => x != firstRecord && x != lastRecord));
            } else if(firstRecord.v == 0) {
                newPeriods[dow].push({
                    h: 0,
                    m: 0,
                    v: 1
                });
                newPeriods[dow].push(...currentDay.filter(x => x != firstRecord && x != lastRecord));
            } else {
                newPeriods[dow].push(...currentDay.filter(x => x != lastRecord));
            }
            if(lastRecord.v == 1) {
                newPeriods[dow].push(lastRecord);
                newPeriods[dow].push({
                    h: 24,
                    m: 0,
                    v: 0
                });
            } else {
                newPeriods[dow].push(lastRecord);
            }
        }

        newPeriods = newPeriods.map((day) => {
            return day.sort((a, b) => {
                return ((a.h * 60 + a.m)) - (b.h * 60 + b.m);
            })
        });

        return newPeriods;

    };
    let periods = convertPeriods(originalPeriods);
    for (let dow = 0; dow < periods.length; dow++) {
        let dayProfile = periods[dow];
        let length = dayProfile.length;
        if (length % 2) length--;
        for (let period = 0; period < length; period += 2) {
            let startInDouble = dayProfile[period].h + ((Math.round(dayProfile[period].m / 5) * 5) / 60);
            let endInDouble = dayProfile[period + 1].h + ((Math.round(dayProfile[period + 1].m / 5) * 5) / 60);
            events.push({
                id: dow + "-" + period,
                day: dow,
                start: Math.floor(startInDouble * 2),
                end: Math.floor(endInDouble * 2),
                startindouble: startInDouble,
                endindouble: endInDouble,
                starttimeindex: dow * 24 + startInDouble,
                type: 'current'
            });
        }
    }

    return { events: fromJS(events), bacnetScheduleDefault: msg.m_scheduleDefault || '', dirty: false }
}
