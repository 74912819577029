import * as d3 from 'd3';
import { drawText, drawTextBox, drawTextBoxStripe, setMouseEvents, displayTooltip, displayAlarm, blink } from '../Utils';
import Immutable from 'immutable';



export default function Text(options) {
    return new Promise((resolve, reject) => {
        let createPlacement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})`)

        let placementElement = createPlacement(options);

        let txt = options.node.getIn(['configuration', 'value'], 'polling');
        if (!txt) txt = "polling";

        let textNode = drawText(placementElement, options.node, txt, options.getCustomerSettings, options.getUserSettings);
        let textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

        let textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);
       
        
        if (options.node.getIn(['configuration', 'displaystyle']) == 'button') {
            setMouseEvents(placementElement, options);
            placementElement.style("cursor", "hand");
        }    

        let tooltip = displayTooltip(placementElement, options);
        
        var animate;
        var alarmNode;
        let rules = (options.node.get('configuration')) ? options.node.getIn(['configuration', 'rules']) : undefined;
             
        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;
                textNode.remove();

                let texttodisplay = point.m_formattedValueText ? point.m_formattedValueText : 'Error #' + point.m_errorNumber;
 
                let rule = (rules || Immutable.List([])).filter((rule) => {
                        return (rule.get('matchvalue') == point.m_value);
                    }).first();

                if (rule && (rule.get('action') == 'hide')) return;

                if (rule && rule.get('displaytext')) texttodisplay = rule.get('displaytext');
                
                textNode = drawText(placementElement, options.node, texttodisplay, options.getCustomerSettings, options.getUserSettings);

                if (textBox) textBox.remove();
                textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

                if (textBoxStripe) textBoxStripe.remove();
                textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

        /*        if ( point.m_alarmType != 0 ) {
                    alarmNode = displayAlarm(textNode, options.node.getIn(['position', 'x'], 0), options.node.getIn(['position', 'y'], 0) - 36, options.target, options.index);
                    console.log('alarm node after ', alarmNode);
                } else { if (alarmNode) alarmNode.remove(); } */

                if(rule && (rule.get('action') == 'flashing')) {
                    animate = blink(placementElement);
                }else {
                    if (animate) animate.remove();
                } 
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
                if (animate) animate.remove();
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {
                textNode.remove();
                placementElement.remove();
                options.node = node;
                placementElement = createPlacement(options);
                
                let textToDisplay = options.node.getIn(['configuration', 'value'], 'polling');
                let point = options.pointparams;
                
                if (point && point.m_formattedValueText) textToDisplay = point.m_formattedValueText;
                textNode = drawText(placementElement, options.node, textToDisplay, options.getCustomerSettings, options.getUserSettings); 

                if (textBox) textBox.remove();
                textBox = drawTextBox(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);

                if (options.node.getIn(['configuration', 'displaystyle']) == 'button') {
                    setMouseEvents(placementElement, options);
                    placementElement.style("cursor", "hand");
                } 

                if (textBoxStripe) textBoxStripe.remove();
                textBoxStripe = drawTextBoxStripe(placementElement, textNode, options.node, options.getCustomerSettings, options.getUserSettings);  
                tooltip = displayTooltip(placementElement, options);

                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            remove: () => {
                placementElement.remove();
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}