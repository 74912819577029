import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { fromJS, List } from "immutable";
import { useStateIfMounted } from "use-state-if-mounted";
import { getUnacknowledgedAlarms, getUserUnacknowledgedAlarms } from "../Logs/Actions";
import debuggerService from "../../../Services/DebuggerService";
import { useBMS } from "../../Context/BMS";
import ServiceBus from "../../../Services/ServiceBus";

const logger = debuggerService.getDebugger("Unacknowledged Alarms", "gold");

const UnacknowledgedAlarmsContext = React.createContext({});

export function useUnacknowledgedAlarms({ customer }) {
    return useContext(UnacknowledgedAlarmsContext);
}

function getCurrentSiteFromUser(customer, user, site) {
    let newsite = `${customer.get('name').toLcHyphen()}:${site.get('name')}`;
    let userSite = user.get('sites').find((s) => s.get('name') === newsite);
    if(userSite) {
        return userSite;
    }
    return null;

}

export default function UnacknowledgedAlarmsProvider({ children }) {
    const [unacknowledgedAlarms, setUnacknowledgedAlarms] = useStateIfMounted(List());
    const { customer, currentUser, site } = useBMS();
    
    // console.log("currentUser", currentUser);

    const siteName = site && site.get('name');  

    const refreshUnacknowledgedAlarms = async (abortController) => {
        try {

            // console.log("refreshUnacknowledgedAlarms", customer, currentUser, site);
            // console.log("refreshUnacknowledgedAlarms", getCurrentSiteFromUser(customer, currentUser, site));

            let userSite = getCurrentSiteFromUser(customer, currentUser, site);
            let customername = customer.get('name').toLowerCase().replace(/ /g, "");

            if(!(userSite && userSite.getIn(['alarmsettings', 'alarmbanner']))) {
                // Alarm banner is disabled don't get anything
                return false;
            }

            if(userSite && userSite.getIn(['alarmsettings', 'ackrequired'])) {
                const userName = `${currentUser.get('firstname')} ${currentUser.get('lastname') ? currentUser.get('lastname') : ""}`;
                const response = await getUserUnacknowledgedAlarms(
                    customername, 
                    userName, 
                    `${customer.get('ipaddress').toLowerCase()}`,
                    abortController
                );
                logger.log("Unacknowledged Alarms", response);
                setUnacknowledgedAlarms(fromJS(response));
            } else {
                const response = await getUnacknowledgedAlarms(
                    customername, 
                    `${customer.get('ipaddress').toLowerCase()}`,
                    abortController
                );
                logger.log("Unacknowledged Alarms", response);
                setUnacknowledgedAlarms(fromJS(response));
            }
            
            return true;
        } catch (error) {
            setUnacknowledgedAlarms(fromJS([]))
            console.error(error);
        }
        return false;
    }

    useEffect(() => {
        let abortController = new AbortController();
        logger.log("Unacknowledged Alarms Provider (customer, site, currentUser) mounted");
        (async (abortController) => {
            logger.log("Unacknowledged Alarms Provider Executed");
            await refreshUnacknowledgedAlarms(abortController);
        
        })(abortController);

        return () => {
            logger.log("Unacknowledged Alarms Provider (customer, site, currentUser) unmounted");
            abortController.abort();
        }

    }, [customer, currentUser, siteName]);

    useEffect(() => {
        let abortController = new AbortController();
        logger.log("Unacknowledged Alarms Provider mounted");
        const unsubscribe = ServiceBus.on('API_MESSAGE', async (data) => {
            await refreshUnacknowledgedAlarms(abortController);
        })
        return () => {
            logger.log("Unacknowledged Alarms Provider unmounted");
            unsubscribe();
            abortController.abort();
        }
    }, [])

    const value = useMemo(() => ({ unacknowledgedAlarms, setUnacknowledgedAlarms, refreshUnacknowledgedAlarms }), [
        unacknowledgedAlarms
    ]);

    logger.log("Unacknowledged Alarms Provider Rendered", unacknowledgedAlarms);

    return (
        <UnacknowledgedAlarmsContext.Provider value={value}>
            {children}
        </UnacknowledgedAlarmsContext.Provider>
    );
}