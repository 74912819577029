import React, { useState } from 'react';
import { getComponentsV2 } from './Actions';
import Select from 'Controls/Form/Select';
import Checkbox from 'Controls/Form/Checkbox';
import axios from 'axios';
import FileInput from 'Controls/Form/FileInput';
import Input from 'Controls/Form/Input';
import Immutable, { List, Map } from 'immutable';
import { uploadComponent, saveCustomerComponents } from '../Actions';
import { updateSelectedComponentCategory } from './Actions';
import Button from 'Controls/Button/UserDefined';
import Dialog, { DialogHeader, DialogBody, DialogFooter, DialogTitle, DialogContent } from 'Controls/Dialog/Dialog';
import { withUserSettings } from '../../../../Context/UserSettings';
import { withCustomerSettings } from '../../../../Context/CustomerSettings';
import { initializeDefaults } from './ComponentConfigurationDialog';
import debuggerService from '../../../../Services/DebuggerService';

const logger = debuggerService.getDebugger("ComponentSelectionDialogV2", "lime");

function ComponentSelectionDialog({ selecteditem, onClose, ...props }) {
    return <Dialog opened={!!selecteditem} onClose={onClose} className="min-w-[60vw] max-w-[60vw]">
        <ComponentSelectionDialogPanel selecteditem={selecteditem} onClose={onClose} {...props} />
    </Dialog> 
}

class ComponentSelectionDialogPanel extends React.Component {
    constructor(props) {
        super(props);
        let { customer, site, selectedCategory } = this.props;

        this.state = {
            selectedcategory: selectedCategory || "general",
            currentNode: props.selecteditem,
            sitebasepath: `/files/customers/${customer.get('name').split(' ').join('-').toLowerCase()}_${site.get('name').split(' ').join('-').toLowerCase()}`
        }
    }

    async reloadComponents() {
        let { customer, site } = this.props;
        let { sitebasepath } = this.state;

        let components = await getComponentsV2();
        let siteComponentsResponse = await axios.get(`${sitebasepath}_components_components.json`);
        let siteComponents = siteComponentsResponse.data;

        components['site-specific'] = {
            name: 'site-specific',
            description: 'Site specific',
            components: siteComponents
        }

        this.setState({
            components: Immutable.fromJS(components)
        });
    }

    async componentDidMount() {
        let { customer, site, selectedCategory } = this.props;
        let { sitebasepath } = this.state;

        let components = await getComponentsV2();
        let siteComponentsResponse = await axios.get(`${sitebasepath}_components_components.json`);
        let siteComponents = siteComponentsResponse.data;

        components['site-specific'] = {
            name: 'site-specific',
            description: 'Site specific',
            components: siteComponents
        }

        this.setState({
            selectedcategory: selectedCategory || "general",
            components: Immutable.fromJS(components)
        });
    }

    componentDidUpdate(oldProps, oldState) {
        let { state } = this.state;
    }

    categoryChange(value) {
        updateSelectedComponentCategory(value);
        this.setState(({ selectedcategory }) => ({
            selectedcategory: value
        }))
    }

    componentSelected(component) {
        let { currentNode } = this.state;
        let { placeNode, userSettings: { userSettings }, customerSettings: { customerSettings } } = this.props;
        if( component.get('defaultconfiguration') ) {
            const [ ...objs ] = component.get('defaultconfiguration');

            objs.map((config) => {
                // console.log('default', config);
                currentNode = currentNode.updateIn(['configuration',config[0]], x => x || component.getIn(['defaultconfiguration',config[0]]));
            });
        }

        if (placeNode) {
            if (component.get('name') == 'label') {
                currentNode = currentNode.set('type', 'animation').set('component', component).setIn(['configuration','displaystyle'], 'boxed');
                currentNode = initializeDefaults({selecteditem: currentNode, userSettings, customerSettings});
                logger.log("componentSelected currentNode", currentNode);
                placeNode(currentNode);
                return;
            }

            if (component.get('name') == 'striped-text') {
                currentNode = currentNode.set('type', 'animation').set('component', component).setIn(['configuration','displaystyle'], 'striped');
                currentNode = initializeDefaults({selecteditem: currentNode, userSettings, customerSettings});
                logger.log("componentSelected currentNode", currentNode);
                placeNode(currentNode);
                return;
            }
            
            currentNode = currentNode.set('type', 'animation').set('component', component);
            currentNode = initializeDefaults({selecteditem: currentNode, userSettings, customerSettings});
            logger.log("componentSelected currentNode", currentNode);
            placeNode(currentNode);
        }
    }

    getPath(component) {
        let { sitebasepath } = this.state;

        if(component.get('type') == 'compound') {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.png`;
        }

        if (component.get('thumbnail')) {
            if (component.get('thumbnail').indexOf('~') == 0) {
                return `${sitebasepath}_thumbnails_${component.get('thumbnail').replace('~', '')}.svg`;
            }
            return `/files/editor/thumbnails-v2_${component.get('thumbnail')}.svg`;
        }
        if (component.get('name').indexOf('~') == 0 && component.get('type') == 'image-png') {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.png`;
        }
        if (component.get('name').indexOf('~') == 0 && component.get('type') == 'image-jpg') {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.jpg`;
        }
        if (component.get('name').indexOf('~') == 0) {
            return `${sitebasepath}_components_${component.get('name').replace('~', '')}.svg`;
        }
        return `/files/editor/components-v2_${component.get("name")}.svg`;
    }

    render() {
        let { customer, site } = this.props;
        let { selectedcategory, currentNode, components } = this.state;

        return (
            <DialogContent className="min-w-[60vw] min-h-[30vh]">
                <DialogHeader>
                    <DialogTitle>Add component</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="w-1/3">
                        <Select onChange={this.categoryChange.bind(this)} options={(components || []).reduce((list, component) => {
                            list.push({
                                value: component.get('name'),
                                text: component.get('description')
                            });
                            return list;
                        }, []).sort((a,b) => (a.text || "").localeCompare(b.text))} value={selectedcategory} />
                    </div>
                    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-4">
                        {components && components.getIn([selectedcategory, "components"]).map((component, index) => {
                            
                            if(component.get('type') == 'compound') {

                                return <li key={index} onClick={this.componentSelected.bind(this, component)}>
                                    <div className="group p-2 block rounded-lg bg-gray-100 focus-within:ring-2 hover:ring-4 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                        <img src={this.getPath(component)} alt="" className="object-scale-down h-24 pointer-events-none group-hover:opacity-75" height="100" width="100"  />
                                    </div>
                                    <p className="mt-2 block text-sm text-gray-900 pointer-events-none">{component.get('description')}</p>
                                </li>

                            }


                            return (
                                <li key={index} onClick={this.componentSelected.bind(this, component)}>
                                    <div className="group p-2 block rounded-lg bg-gray-100 focus-within:ring-2 hover:ring-4 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                        <img src={this.getPath(component)} alt="" className="object-scale-down h-24 pointer-events-none group-hover:opacity-75" height="100" width="100"  />
                                    </div>
                                    <p className="mt-2 block text-sm text-gray-900 pointer-events-none">{component.get('description')}</p>
                                </li>
                            )
                        })}
                    </ul>
                    <UploadComponentInput onUploadFinish={() => this.reloadComponents()} components={(components || List()).getIn([selectedcategory, 'components']) || List()} customer={customer.get('name').toLcHyphen()} site={site.get('name')} showUploadField={selectedcategory == 'site-specific'}/>
                </DialogBody>
                <DialogFooter>
                    <Button onClick={this.props.onClose}>Close</Button>
                </DialogFooter>
            </DialogContent>
        )
    }
}

export default withUserSettings(withCustomerSettings(ComponentSelectionDialog));


function UploadComponentInput({ showUploadField, customer, site, components, onUploadFinish }) {

    const [file, setFile] = useState(undefined);
    const [directory, setDirectory] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);
    const [fill, setFill] = useState(false);
    const [show, setShow] = useState(false);
    const [hotspot, setHotspot] = useState(false);

    const uploadFile = () => {
        let filename = name.toLowerCase().split(' ').join('-');
        let directoryName = (directory || "").split(' ').join('-');
        let extension = (file.name || "").replace(/.*?(\.\w*$)/,'$1');
        filename = directoryName ? `${directoryName}_${filename}` : filename;
        let filenameWithExtension = filename + extension;
        let stateids = [];

        if(fill) stateids = [{"action": "fill", "description": "Fill"}];
        if(show) stateids = [{"action": "show", "description": "Show"}];

        let type = hotspot ? "hotspot" : "image";

        if(extension == ".png" && type == "image") {
            type = 'image-png';
        }

        if(extension == ".jpg" && type == "image") {
            type = 'image-jpg';
        }

        let newcomponent = Map({
            "name": `~${filename}`,
            "type": type,
            "componentid": "layer1",
            "description": name,
            "sitespecific":true,
            "layer": "layer1user",
            "stateids": stateids
        });

        setIsUploading(true);
        uploadComponent(customer, site, filenameWithExtension, file).then(() => {
            components = components.push(newcomponent);
            return saveCustomerComponents(customer, site, components)
        }).then(() => {
            setIsUploading(false);
            setFile(undefined);
            setDirectory(undefined);
            setName(undefined);
            onUploadFinish && onUploadFinish();
        })
    }

    if(!showUploadField) {
        return null;
    }

    if(isUploading) {
        return <React.Fragment>Uploading...</React.Fragment>
    }

    return <React.Fragment>
        <div className="grid grid-cols-16 gap-2" style={{background:"#f8f8f8",padding:"10px",marginTop:"10px"}}>
            <h4 className="col-span-16">Upload new component</h4>

            <div className="col-span-12 md:col-span-4">
                <FileInput style={{height:"auto"}} onChange={(file) => setFile(file)} />
            </div>

            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setName(value)} value={name} placeholder="Name"  />
            </div>
            <div className="col-span-12 md:col-span-4">
                <Input type="text" onChange={(value) => setDirectory(value)} value={directory} placeholder="Directory name" />
            </div>
            <div className="col-span-12 md:col-span-4">
                 <Button role="success" onClick={() => { uploadFile() }}>Upload</Button>
            </div>  
            <div className="col-span-12 md:col-span-4">
                <Checkbox name="fill" label="Action = Fill" onChange={(v) => setFill(v)} checked={fill}  />     
            </div> 
            <div className="col-span-12 md:col-span-4">
                <Checkbox name="show" label="Action = Show" onChange={(v) => setShow(v)} checked={show}  />     
            </div> 
            <div className="col-span-12 md:col-span-4">
                <Checkbox name="hotspot" label="Hotspot" onChange={(v) => setHotspot(v)} checked={hotspot}  />     
            </div>     
        </div>
    </React.Fragment>

}


//<img className="p-4 w-100 mw-100 mh-100" src={this.getPath(component)} />