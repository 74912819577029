import React, {useState} from 'react';
import TransitioningDialog from 'Controls/Dialog/TransitioningDialog';
import SettingsPanel from 'Controls/SettingsForm/SettingsPanel';
import { useCustomerSettings } from 'Context/CustomerSettings';
import Button from 'Controls/Button/UserDefined'

const CustomerDisplaySettingsDialog = ({ onClose, active }) => {

    const { customerSettings, setCustomerSettings, saveCustomerSettings, resetCustomerSettings } = useCustomerSettings();

    const changeSettings = () => {
        saveCustomerSettings().then(() => {
            onClose();
        })
       
    }

    const closeDialog = () => {
        resetCustomerSettings();
        onClose();
    }

    return (<TransitioningDialog panelWidthClass="min-w-min max-w-max " onClose={onClose} opened={!!active}>

        <SettingsPanel type="customer" settings={customerSettings} setSettings={setCustomerSettings} label={`Customer Settings`} />

        <div className="flex">
            <div className="flex-none p-2">
                <Button role="save" className="" onClick={() => changeSettings()}>Save</Button>
            </div>
            <div className="flex-none p-2">
                <Button role="close" className="" onClick={() => closeDialog()}>Close</Button>
            </div>
        </div>

    </TransitioningDialog>)

}

export default CustomerDisplaySettingsDialog;
