import * as d3 from 'd3';
import { displayTooltip, drawRect, drawCircle, drawPath, setDefs, setStateids } from '../Utils';

export default function Shape(options) {
    return new Promise((resolve, reject) => {
        let shapetype =  options.node.getIn(['configuration','shapetype'], 'rect');
        let rotation = options.node.getIn(['component', 'defaultrotation'], 0);
        var shape;

        if ( options.node.getIn(['configuration', 'rotation']) ) rotation = options.node.getIn(['configuration', 'rotation']);

        let createPlacementElement = (options) => d3.select(options.target).append('g').attr('data-index', options.index)
            .attr('x', options.node.getIn(['position', 'x']))
            .attr('y', options.node.getIn(['position', 'y']))
            .attr('transform', `translate(${options.node.getIn(['position', 'x'])}, ${options.node.getIn(['position', 'y'])})rotate(${rotation})`)
 
        let placementElement = createPlacementElement(options);
             
        if ( shapetype == 'rect'){
            options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'), 
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads']));
            
            shape = drawRect(placementElement, 0, 0, options.node.getIn(['configuration', 'width'], 200), options.node.getIn(['configuration', 'height'], 100),
                options.node.getIn(['configuration', 'borderwidth'], 1),options.node.getIn(['configuration', 'border-color'], '#000000'),
                options.node.getIn(['configuration', 'ry'], 0), options.node.getIn(['configuration', 'gradient']),options.node.get('id'),
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'), options.node.getIn(['configuration', 'opacity'],1)
            );            
        }

        if ( shapetype == 'circle'){
            options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'),
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads']));
            
            shape = drawCircle(placementElement, 0, 0, options.node.getIn(['configuration', 'radius'], 50), 
                options.node.getIn(['configuration', 'borderwidth'], 1),options.node.getIn(['configuration', 'border-color'], '#000000'),
                options.node.getIn(['configuration', 'gradient']),options.node.get('id'),
                options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey')
            );            
        }

        if ( shapetype == 'eqtriangle'){
            let sideLength = options.node.getIn(['configuration', 'sidelength'], 100);
            let path = `M${sideLength/2},0, ${sideLength}, ${sideLength}, 0,${sideLength} Z`;

            let idPath = 1000 - options.node.get('id');
            
            options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'),
                options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                options.node.getIn(['configuration', 'grads']));

            shape = drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
                options.node.getIn(['configuration', 'style', 'background-color'],'#cccccc'), 0, options.node.getIn(['configuration', 'strokewidth'], "black"));     
        }

        let s = shape;

        let tooltip = displayTooltip(placementElement, options);

        let oldvalue = -1;

        resolve({
            setValue: (point) => {
                options.node = options.node.setIn(['pointconfiguration'], point);
                options.pointparams = point;

                if (point.m_value != oldvalue) {
                    // console.log('values not equal ', point.m_value, oldvalue );
                    oldvalue = point.m_value;

                    if (options.node.getIn(['configuration', 'rules'])) {
                        options.node.getIn(['configuration', 'rules'], []).map((rule) => {
                            // console.log('rule ', rule );
                            if (rule.get('matchvalue') == point.m_value) {
                                s.style("fill", rule.get('fill', '#222222'));
/*
                                if (rule.get('action') == 'flashing') {
                                    animate = blink(placementElement);
                                } else {
                                    if (animate) animate.remove();
                                } */
                            }
                        })
                    }
                }
            },
            remove: () => {
                d3.select(placementElement).remove();
            },
            getElement: () => {
                return placementElement;
            },
            setOptions: (newOptions) => {
                options = {...options, ...newOptions}
            },
            redrawElement: (node, isSelected) => {

                options.node = node;
                placementElement.remove();
                placementElement = createPlacementElement(options);
                shapetype = options.node.getIn(['defaultconfiguration','shapetype']) || options.node.getIn(['configuration','shapetype'], 'rect');  
                if ( shapetype == 'rect'){
                    options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'),
                        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),    
                        options.node.getIn(['configuration', 'grads']));
                        
                    drawRect(placementElement, 0, 0, options.node.getIn(['configuration', 'width'], 200), options.node.getIn(['configuration', 'height'], 100),
                    options.node.getIn(['configuration', 'borderwidth'], 0),options.node.getIn(['configuration', 'border-color'], '#000000'),
                    options.node.getIn(['configuration', 'ry'], 45), options.node.getIn(['configuration', 'gradient'] ),options.node.get('id'),
                    options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey'),
                    options.node.getIn(['configuration', 'opacity'],1));   
                }

                if ( shapetype == 'circle'){
                    options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'), 
                        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                        options.node.getIn(['configuration', 'grads']));
                    
                    drawCircle(placementElement, 0,0, options.node.getIn(['configuration', 'radius'], 50), 
                        options.node.getIn(['configuration', 'borderwidth'], 1),options.node.getIn(['configuration', 'border-color'], '#000000'),
                        options.node.getIn(['configuration', 'gradient']),options.node.get('id'),
                        options.node.getIn(['configuration', 'style', 'background-color'],'lightgrey')
                    );            
                }

                if ( shapetype == 'eqtriangle'){
                    let sideLength = options.node.getIn(['configuration', 'sidelength'], 100);
                    let path = `M${sideLength/2},0, ${sideLength}, ${sideLength}, 0,${sideLength} Z`;
        
                    let idPath = 1000 - options.node.get('id');
                    
                    options.node.getIn(['configuration', 'gradient'] ) && setDefs(placementElement, options.node.get('id'),
                        options.node.getIn(['configuration', 'grad-x1'], 0) ,options.node.getIn(['configuration', 'grad-y1'], 1),
                        options.node.getIn(['configuration', 'grad-x2'], 0) ,options.node.getIn(['configuration', 'grad-y2'], 0),
                        options.node.getIn(['configuration', 'grads']));
        
                    drawPath(placementElement, path, options.node.getIn(['configuration', 'gradientHeader'], false ),idPath,
                        options.node.getIn(['configuration', 'style', 'background-color'],'#cccccc'), 0, options.node.getIn(['configuration', 'strokewidth'], "black"));     
                }

                tooltip = displayTooltip(placementElement, options);
                oldvalue = -1;
                if(isSelected) {
                    placementElement.attr('class', 'node-selected');
                }
            },
            setContextMenu: (editmode) => {
                options.editmode = editmode;
            },
            resetIndex: (index) => {
                options.index = index;
                placementElement.attr('data-index', options.index);
            }
        });
    });
}