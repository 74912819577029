import React, { useEffect, useState } from 'react';
import { List } from 'immutable';

export default function ListSelection({ boxContainerClassName, leftList, rightList, leftLabel, rightLabel, onChange, onContextMenu = () => {} }) {

    const [leftSelection, setLeftSelection] = useState([]);
    const [rightSelection, setRightSelection] = useState([]);


    const onLeftSelect = (indexes) => {
        setLeftSelection(indexes);
        setRightSelection([]);
    }

    const onRightSelect = (indexes) => {
        setRightSelection(indexes);
        setLeftSelection([]);
    }

    const moveSelectToRight = () => {
        let newLeftList = leftList;
        let newRightList = rightList;
        leftSelection.forEach((selection) => {
            newRightList = newRightList.push(leftList.get(selection))
        })
        newLeftList = leftList.filter((x,i) => leftSelection.indexOf(i) == -1)
        onChange(newLeftList, newRightList);
    }

    const moveSelectToLeft = () => {
        let newLeftList = leftList;
        let newRightList = rightList;
        rightSelection.forEach((selection) => {
            newLeftList = newLeftList.push(rightList.get(selection))
        })
        newRightList = rightList.filter((x,i) => rightSelection.indexOf(i) == -1)
        onChange(newLeftList, newRightList);
    }

    const moveAllToRight = () => {
        let newLeftList = List();
        let newRightList = rightList.merge(leftList);
        onChange(newLeftList, newRightList);
    }

    const moveAllToLeft = () => {
        let newRightList = List();
        let newLeftList = leftList.merge(rightList);
        onChange(newLeftList, newRightList);
    }   

    useEffect(() => {
        setLeftSelection([]);
        setRightSelection([]);
    }, [leftList, rightList])

    return <div className="grid grid-cols-[1fr_auto_1fr] h-full max-h-full overflow-hidden">

        <div className=" flex flex-col">
            {leftLabel && <div className="p-2">{leftLabel}</div> || null }
            <div className={`${boxContainerClassName} flex-1`}>
                <ListBox items={leftList} value={leftSelection} onSelect={onLeftSelect}  onContextMenu={(e, index) => onContextMenu(e, index, "left")} />
            </div>
        </div>
       
        <div className="">
            { (leftLabel || rightLabel ) && <div className="p-2">&nbsp;</div> || null}
            <div className="flex flex-col p-2 justify-items-center">
                <button disabled={!leftSelection && leftSelection !== 0} onClick={moveSelectToRight} className="p-2 border">&gt;</button>
                <button onClick={moveAllToRight} className="p-2 border">&gt;&gt;</button>
                <button disabled={!rightSelection && leftSelection !== 0} onClick={moveSelectToLeft} className="p-2 border">&lt;</button>
                <button onClick={moveAllToLeft} className="p-2 border">&lt;&lt;</button>
                
            </div>
        </div>
        <div className=" flex flex-col">
            {rightLabel && <div className="p-2">{rightLabel}</div> || null }
            <div className={`${boxContainerClassName} flex-1`}>
                
                <ListBox items={rightList} value={rightSelection} onSelect={onRightSelect} onContextMenu={(e, index) => onContextMenu(e, index, "right")} />
            </div>
        </div>
        

    </div>


}

function ListBox({ items, onSelect, value, onContextMenu }) {

    const onClick = (e, index) => {
        if(e.shiftKey) {
            // e.stopPropagation(e);
            e.preventDefault(e);
            if(value.indexOf(index) > -1) {
                console.log("value was found");
                let filterOut = value.indexOf(index);
                return onSelect([...value.filter((x,i) => i != filterOut)])
            }
            return onSelect([...value, index])
        }
        return onSelect([index])
    }

    return <div className="border w-full h-full select-none overflow-y-scroll flex flex-col" >
        { items.map((item, index) => {
            
        let className = "p-2 ";
            if(value.indexOf(index) > -1) {
                className += " bg-[blue] text-[#FFFFFF]"
            }
            return <div key={index.toString()} onContextMenu={(e) => onContextMenu(e, index)}  onClick={(e) => onClick(e, index)} className={className}>
                {item.get('label')}
            </div>
        })}

    </div>
}