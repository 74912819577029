import { Map } from 'immutable';
import { convertDoubleToTimeString, convertTimeStringToDouble } from 'Utils/Timestamp';

export function getPointNumber(address) {
    let buffer = new ArrayBuffer(10);
    let view = new DataView(buffer);

    address.forEach((value, index) => {
        view.setUint8(index, value);
    });

    return view.getUint16(5, true);
}


export function getFormattedTimezone(start, end) {
    end = end + 1;

    let hoursstart = start * 0.5;
    let hoursend = end * 0.5;

    let starttimeHours = hoursstart % 24;
    let endtimeHours = hoursend % 24;

    let startDay = (hoursstart - starttimeHours) / 24;
    let endDay = (hoursend - endtimeHours) / 24;

    if (endDay > startDay) {
        end = 48;
    }

    return Map({
        day: (start - (start % 48)) / 48,
        start: start % 48,
        end: (end % 48) < (start % 48) ? 48 : (end % 48),
        startindouble: starttimeHours,
        endindouble: endtimeHours,
        starttimeindex: ((start - (start % 48)) / 48) * 24 + starttimeHours,
        dirty: true
    })
}

export function removeCollidingElements(event, periods) {
    const getIndexFromTime = (time) => {
        let [startHours, startMinutes] = time.split(':');
        return Number(startHours) * 60 + Number(startMinutes);
    }
    return periods.map((x) => {
        if(event == x) {
            return x;
        }

        let currentStart = getIndexFromTime(event.get('starttime'));
        let currentEnd = getIndexFromTime(event.get('endtime'));
        let comparingStart = getIndexFromTime(x.get('starttime'));
        let comparingEnd = getIndexFromTime(x.get('endtime'));


        let sameDay = x.get('dow') == event.get('dow');
        let startIsInOtherEventRange = comparingStart >= currentStart && comparingStart < currentEnd;
        let endIsInOtherEventRange = comparingEnd < currentEnd && comparingEnd > currentStart;
        if(sameDay && (startIsInOtherEventRange || endIsInOtherEventRange)) {
            return x.set('isDeleted', true);
        }
        return x; 
    })

}

export function convertEvents(events) {
    return events.map((event, index) => {

        let startttime = convertDoubleToTimeString(event.get('startindouble'));
        let endtime = convertDoubleToTimeString(event.get('endindouble'));
        event = event.set('starttime', startttime);
        event = event.set('endtime', endtime);
        event = event.set('dow', event.get('day'));
        event = event.set('startindex', event.get('start'));
        event = event.set('endindex', event.get('end'));
        event = event.set('$originalIndex', index);
        return event;
    })
}

// used to be timeRangeToInfo
export function getTimeRangeToInfo(starttime, endtime) {
    let [startHours, startMinutes] = starttime.split(':');
    let [endHours, endMinutes] = endtime.split(':');
    let startindouble = Number(startHours) + (Number(startMinutes) / 60);
    let endindouble = Number(endHours) + (Number(endMinutes) / 60);
    let startindex = (startindouble - (startindouble % 0.5)) * 2;
    let endindex = (endindouble - (endindouble % 0.5)) * 2;
    return [startindex, endindex, startindouble, endindouble];
}

export function timeIndexToString60(time) {
    let hours = (time - time % 60) / 60;
    let minutes = time % 60;
    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `0${hours}:${minutes}`.replace(/^[0-9]([0-9]{2})/, '$1');

}
