import { useTimezoneScreenStore } from '../store/useTimezoneScreenStore';
import useTimezoneState from './useTimezoneState';
import { Map } from 'immutable'

function useClipboard() {
    const clipboard = useTimezoneScreenStore((state) => state.clipboard);
    const setClipboard = useTimezoneScreenStore((state) => state.setClipboard);

    return { clipboard, setClipboard }
}

export default function useOnDayAction() {

    const { clipboard, setClipboard } = useClipboard();
    
    const events = useTimezoneScreenStore((state) => state.events);
    const setEvents = useTimezoneScreenStore((state) => state.setEvents);
    const dirty = useTimezoneScreenStore((state) => state.dirty);
    const setDirty = useTimezoneScreenStore((state) => state.setDirty);
    const resetState = useTimezoneScreenStore((state) => state.resetState);

    const { isSelectedDayTemplate, setIsSelectedDayTemplate } = useTimezoneState();

    const setTimezoneToAllDay = (day) => {
        let newEvents = events.map(event => {
            if(event.get('day') == day) {
                event = event.set('removed', true).set('dirty', true);
            }
            return event;
        })
        newEvents = newEvents.push(Map({
            day: day,
            dirty: true,
            end: 48,
            endindouble: 24,
            start: 0,
            startindouble: 0,
            starttimeindex: day * 24,
            type: "current"
        }))
        setEvents(newEvents);
        setDirty(true);
    }

    const setTimezoneToOffAllDay = (dayIndex) => {
        let newEvents = events.map(event => {
            if(event.get('day') == dayIndex) {
                event = event.set('removed', true).set('dirty', true);
            }
            return event;
        })
        setEvents(newEvents);
        setDirty(true);
    }

    const copyDay = (day) => {
        setClipboard({
            copiedEvents: events.filter(x => x.get('day') == day)
        })
    }

    const pasteDay = (day) => {
        const copiedEvents = clipboard.copiedEvents;
        if(copiedEvents) {
            let newEvents = events.map(x => {
                if(x.get('day') == day) {
                    x = x.set("removed", true);
                    x = x.set("dirty", true);
                }
                return x;
            });
            copiedEvents.forEach((copiedEvent) => { 
                copiedEvent = copiedEvent.set('day', day);
                copiedEvent = copiedEvent.set('starttimeindex', (day * 24) + copiedEvent.get('starttimedouble'));
                copiedEvent = copiedEvent.delete('id');
                newEvents = newEvents.push(copiedEvent)
            })
            setEvents(newEvents);
            setDirty(true);
        }
    }

    const repeatOnAllDays = (day) => {
        let dayEvents = events.filter(x => x.get('day') == day && !x.get('removed'));
        let originalEvents = events;
        originalEvents = originalEvents.map((event) => {
            if(event.get('day') != day) {
                event = event.set('removed', true);
                event = event.set('dirty', true);
            }
            return event;
        });
        for(let i = 0; i < 7; i++) {
            if(i != day) {
                originalEvents = originalEvents.merge(dayEvents.map((event) => {
                    event = event.set('day', i);
                    event = event.set('starttimeindex', (i * 24) + event.get('starttimedouble'));
                    event = event.delete('id');
                    event = event.set('dirty', true);
                    return event;
                }))
            }
        }
        setEvents(originalEvents);
        setDirty(true);
    
    }



    const onDayAction = (day, action) => {
        let dayIndex = day - 1;
        switch(action) {
            case 'onAllDay': setTimezoneToAllDay(dayIndex); break;
            case 'offAllDay': setTimezoneToOffAllDay(dayIndex); break;
            case 'copyDay': copyDay(dayIndex); break;
            case 'pasteDay': pasteDay(dayIndex); break;
            case 'repeatOnAllDays': repeatOnAllDays(dayIndex); break;
            case 'selectTemplate': 
            case 'selectWeeklyTemplate': 
                setIsSelectedDayTemplate(day);
            break;
            default:
        }
    }

    return onDayAction

}