import { eventDisplayInfo, backgroundColour, backgroundBorderColour, textColour, evaluateCondition } from "./constants";
import { ExclamationTriangleIcon, BellSlashIcon, BellAlertIcon, InformationCircleIcon, WrenchScrewdriverIcon, CheckCircleIcon, MagnifyingGlassIcon, CommandLineIcon } from '@heroicons/react/24/outline';
import { useBMS } from "../../BmsView/Context/BMS";
import { useAlarmFilterGroups } from "../../BmsView/Customer/Context/AlarmFilterGroups";
import { OrderedMap } from 'immutable';

function convertDateString(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
}
function EventRecord({ event, children, siteAlarmFilterGroups = OrderedMap() }) {
    let Icon = eventDisplayInfo[event.get('type')].Icon;
      
    const { currentUser } = useBMS();

    let icon = event.get('type');  

    return (  
        icon != undefined &&
            <div className={`@container rounded-md ${backgroundColour[eventDisplayInfo[icon].colour]} p-2 border-4 border-double ${backgroundBorderColour[eventDisplayInfo[icon].colour]}`}>
                <div className="flex item-center justify-between ">
                    <Icon className={`${eventDisplayInfo[icon].animate ? 'animate-ping h-4 w-4' : 'h-6 w-6'} ${textColour[eventDisplayInfo[icon].colour]} aria-hidden=true`} />
                    <div className="ml-4"><h3>{eventDisplayInfo[icon].type}</h3></div>
                    <div className="ml-4">{event.get('datetime')}</div>
                </div>
                { event.get('type') == 3 && 
                <div className="flex ml-8 item-center justify-between  flex-col @2xl:flex-row">
                    <div className="text-nowrap">
                        Point : { event.get('point')}
                    </div>
                    <div className="flex flex-row gap-4">
                        <div  className="text-nowrap">
                            Panel : { event.get('panel')}
                        </div>                        
                    </div>
                </div>}
                <div className="flex ml-8 item-center justify-between">
                    <pre className="text-wrap">
                        {event.get('event')}
                    </pre>
                </div>
                { event.get('type') == 3 && 
                <div className="flex ml-8 item-center justify-between">
                    <pre className="text-wrap">
                        User : {event.get('user')}
                    </pre>
                </div>}
                { event.get('type') == 3 &&
                <div className="flex ml-8 gap-3 justify-end">
                    {/* <div>
                        Priority : { alarm.get('priority')}
                    </div> */}
                        {children}
                </div>}
            </div>
    )
}

EventRecord.ShowDetailsButton = EventRecordShowDetailsButton;

function EventRecordShowDetailsButton({ alarm, displayEventDetails }) {
    return <div className="flex">
        <div><MagnifyingGlassIcon className="ml-2 h-6 w-6 text-red-900 cursor-hand" aria-hidden="true" onClick={() => displayEventDetails(alarm)} /></div>
    </div>
}


export default EventRecord;