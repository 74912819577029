import React from 'react';
import {connect} from 'react-redux';
import ServiceBus from 'Services/ServiceBus';
import Button from 'Controls/Button/UserDefined';
import Grid from 'Controls/GridV2';
import Input from 'Controls/Form/Input';
import Swal from 'sweetalert2';
import ModuleTypes from 'Configuration/ModuleTypes';
import { ChevronUpIcon, ChevronDownIcon, PencilSquareIcon, TrashIcon, PlusIcon, PlayCircleIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import { withBMS } from 'BmsView/Context/BMS';

class SubsystemStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subsystems: []
        }

        this.processSubsystemStatusResp = this.processSubsystemStatusResp.bind(this);
        this.processGetRevisionInfoMsgResp = this.processGetRevisionInfoMsgResp.bind(this);

        ServiceBus.on('SUBSYSTEM_STATUS_RESP', this.processSubsystemStatusResp);
        ServiceBus.on('302-11', this.processGetRevisionInfoMsgResp);
    }

    componentDidMount() {
        this.getSubsystemStatus();

        this.polling = setInterval(() => {
            this.getSubsystemStatus();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.polling);
        ServiceBus.off('SUBSYSTEM_STATUS_RESP', this.processSubsystemStatusResp);
        ServiceBus.off('302-11', this.processGetRevisionInfoMsgResp);
    }

    processSubsystemStatusResp(resp) {
        this.setState({
            subsystems: resp
        }); 

        resp.map((subsystem) => {
            if (subsystem.status == 'Connected') {
                this.getSubsystemCommunicationsStatus(subsystem.number)
            }
        })
    }

    processGetRevisionInfoMsgResp(resp) {
        let {subsystems} = this.state;

        let newsubsystems = subsystems.map((subsystem) => {
            if (subsystem.number == resp.m_subsystemNumber) {
                subsystem.comms = resp.m_communicating;
            } 
            return subsystem;
        });

        this.setState({
            subsystems: newsubsystems
        });
    }

    getSubsystemStatus(){
        let customer = this.props.params;

        if ( customer.get('subsystems') == undefined ) return;

        return new Promise((resolve, reject) => {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                type: 'GET_SUBSYSTEM_STATUS',
                target: 'webserver',
                customer: customer.get('name'),
                subsystems: customer.get('subsystems')
            });
        });
    }

    getSubsystemCommunicationsStatus(subsystem){
        let customer = this.props.params;

        return new Promise((resolve, reject) => {
            ServiceBus.send('WEB_MESSAGE_REQ', {
                m_level: 10,
                m_communicNum: 302,  
                m_subsystemNumber: subsystem,
                type: 'GENERIC',
                target: 'signalr',
                databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
            })
        })
    }

    stopServiceBus() {
        Swal.fire({
            title: `Are you sure you want to stop the Service Bus ?`,
            text: "You will need to restart manually or restart Web Server",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, stop it!'
        }).then(async (result) => {
            return new Promise((resolve, reject) => {
                ServiceBus.send('WEB_MESSAGE_REQ', {
                    type : 'SERVICE_BUS_EXIT',
                    m_level: 10,
                    m_communicNum: 100,  
                    target: 'webserver'
                })
            })
        })
    }

    changeStatus(subsystem) {
        let customer = this.props.params;

        if(subsystem && !subsystem.local) {
            Swal.fire("Error", "Cannot change status of remote subsystem", "error");
            return;
        }

        if ( subsystem.status == 'Connected' ) {
            return new Promise((resolve, reject) => {
                ServiceBus.send('WEB_MESSAGE_REQ', {
                    type: 'GENERIC',
                    m_level: 10,
                    trackMessage: true,
                    m_guiPoll: 0,
                    m_communicNum: 99,  
                    m_subsystemNumber: subsystem.number,
                    target: 'signalr',
                    databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
                }); 
            });       
        } else {
            return new Promise((resolve, reject) => {
                ServiceBus.send('WEB_MESSAGE_REQ', {
                    type: 'SERVICE_BUS_START_SUBSYSTEM',
                    m_level: 10,
                    m_communicNum: 101, 
                    target: 'webserver',
                    directoryname: customer.get('name').toLowerCase().replace(/ /g, "-"),
                    subsystemnumber: subsystem.number
                }); 
            });       
        }
    }

    render() {
        let {subsystems} = this.state;
        let {currentUser} = this.props;
        let customer = this.props.params;

        return (
            <div className='container mx-auto my-4'>
                <h1>Subsystem Status</h1>
                <div className="grid grid-cols-16 w-full">
                    <div className="col-span-16 md:col-span-4">
                        <Input className="" disabled={true} label="Customer" value={customer.get('name')}/>
                    </div>
                    
                </div>
                <div className="my-4">
                    <h4>Subsystem List</h4>
                    <div>
                        <Grid>
                            <Grid.Header>
                                <Grid.Column classes="col-span-16 md:col-span-4">Name</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2">Number</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2">Type</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">Status</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-3">Communications</Grid.Column>
                                <Grid.Column classes="col-span-16 md:col-span-2 text-right">Action</Grid.Column>
                            </Grid.Header>    
                            <Grid.Data>
                                {(subsystems || []).map((subsystem, index) => {
                                    return (
                                        <Grid.Row key={index} className="hover:bg-gray-200">
                                            <Grid.Column classes="col-span-16 md:col-span-4">{subsystem.name}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-2">{subsystem.number}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-2">{ModuleTypes[subsystem.type].name}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-3">{subsystem.status}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-3">{(subsystem.comms == undefined && 'Down') || (subsystem.comms ? 'Up' : 'Down' )}</Grid.Column>
                                            <Grid.Column classes="col-span-16 md:col-span-2">
                                                <Grid.Controls>
                                                    {(subsystem.status == 'Connected') ?
                                                    <Grid.Control>
                                                        <StopCircleIcon className="w-6 text-red-500" onClick={this.changeStatus.bind(this, subsystem)}/>
                                                        {/* <Button role="danger" className="w-100" onClick={this.changeStatus.bind(this, subsystem)}>Stop</Button> */}
                                                    </Grid.Control> :
                                                    <Grid.Control>
                                                        <PlayCircleIcon className="w-6 text-green-500" onClick={this.changeStatus.bind(this, subsystem)}/>
                                                        {/* <Button role="success" className="w-100" onClick={this.changeStatus.bind(this, subsystem)}>Start</Button>     */}
                                                    </Grid.Control>
                                                    }
                                                </Grid.Controls>
                                               
                                            </Grid.Column>
                                        </Grid.Row>
                                    );    
                                })}
                            </Grid.Data>
                        </Grid>
                    </div>
                </div>
                {currentUser.getIn(['isSuperAdmin']) &&
                    <div className="my-4">
                        <h4>Service Bus</h4>
                        <div>
                            <Button role="danger" onClick={this.stopServiceBus.bind(this)}>Stop Service Bus</Button>
                        </div>
                    </div>
                }    
            </div>
        );
    }    
}

// export default connect((state) => ({
//     currentUser: state.get('currentUser'),
// }))(SubsystemStatus);

export default withBMS(SubsystemStatus);