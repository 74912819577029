import { usePointGroupManagementStore } from "..";
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid';

export default function PointGroupManagementButton({ customer, cursor, group, point, editable = false, children, panelType = '' }) {

    const open = usePointGroupManagementStore((state) => state.open);
    const close = usePointGroupManagementStore((state) => state.close);

    const onClick = () => {
        let dialogSettings = {
            customer,
            cursor,
            panelType,
            editable
        };
        if(point) {
            dialogSettings.address = point.get('addr');
        }
        if(group) {
            dialogSettings.type = group.get('type');
            dialogSettings.group = group;
        }
        open(dialogSettings);
    }

    if(!point && !(group && (group.get('type') == 'Bacnet Schedule' || group.get('type') == 'Schedule') )) {
        return null;
        
    }

    return (
        <button onClick={onClick}>
            {
                children || 
                <CursorArrowRaysIcon 
                    title="Point Group Management"
                    className="text-gray-300 group-hover:text-gray-800 group-hover:hover:text-blue-500 w-6" /> ||  
                'Point Group Management'
            }
            
        </button>
    );


}