import React, { useEffect, useState, useRef, Fragment } from 'react';
// import Graphic from './Graphic';
import Graphic from './GraphicV2';
import ComponentSelectionDialog from './ComponentSelectionDialogV2';
import PointListDialog from './PointListDialog';
import Immutable, { List, Map } from 'immutable';
import { useSelector } from 'react-redux';
import ComponentConfigurationDialog from './ComponentConfigurationDialog';
import { getArea, saveArea, updateNodeConfiguration } from './Actions';
import ServiceBus from 'Services/ServiceBus';
import PointStatusDialog from './PointStatusDialog';
import PointListStatusDialog from './PointListStatusDialog/indexV2';
import HistoriesDialog from './HistoriesDialog';
import ReportsDialog from './ReportsDialog';
import EmailFormDialog from './EmailFormDialog';
import Excel500WeeklyScheduleDialog from 'BmsView/Customer/Configuration/Subsystems/Ex500/WeeklyScheduleDialog';
import SchedulePointGroupDialog from 'BmsView/Customer/Configuration/SchedulePointGroupDialog';
import ControllerPointListDialog from 'BmsView/Customer/Configuration/ControllerPointListDialog';
import ConversionDialog from './ConversionDialog';
import Swal from 'sweetalert2';
import './Reducers';
import './PointControl/Reducers';
// import history from 'BmsView/History';
import LayerManager from './LayerManager';
import { useArea } from '../../../../Context/Area';
import { useSubscription } from '../../../../Context/AreaPoint';
import LeftSlide from '../../../Layout/LeftSlide';
import AreaSidebar from 'BmsView/Customer/Site/Area/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useBMS } from '../../../Context/BMS';
import AreaSaveButton from './SaveAreaButton';
import { useAreaDialogContext } from '../../../AreaDialog/Context';
import useAreaCustomer from './Hooks/useAreaCustomer';
import useAreaSite from './Hooks/useAreaSite';
import ModuleTypes from 'Configuration/ModuleTypes';

export const POLLING_TIME = 30000;

export const localReferences = {
    nodes: 0
}

let editModeMessageShowed = false;

function Area(props) {

    const subscription = useSubscription();

    const helpers = useRef();

    const navigate = useNavigate();

    const componentSelectedCategory = useSelector(state => state.get('componentSelectedCategory'))
    const editmode = useSelector(state => state.get('editmode'));
    const showMobileGraphic = useSelector(state => state.get('showMobileGraphic'));

    const { customer } = useAreaCustomer();
    const { site } = useAreaSite();
    const { currentUser:currentuser } = useBMS();   
    const { openDialog } = useAreaDialogContext();
    
    let editmodeAuthorized = false;

    if (customer && site) {
        let signature = `${customer.get('name').toLcHyphen()}:${site.get('name').toLcHyphen()}`;
        let editAuthorization = currentuser.getIn(['sites'], Immutable.List([])).filter((siteauth) => {
            if (!siteauth) {
                return false;
            }
            return siteauth.get('name') == signature && siteauth.get('canedit');
        }).first();
        editmodeAuthorized = !!editAuthorization;
    }


    const [pointData] = useState(Map());
    const [localState, setLocalState] = useState({
        conversionDialogOpened: false,
        conversionCoords: { x: 0, y: 0 },
        currentLayerIndex: 0,
        currentNode: null,
    })

    const { points, setPoints } = useArea();
    const { layers, setLayers } = useArea();
    const { nodes, setNodes } = useArea();

    helpers.current = {
        getCustomer: () => customer,
        getCurrentUser: () => currentuser,
        getLocalState: () => localState,
        getLayers: () => layers,
        getEditMode: () => editmode,
        getPointData: () => pointData,
        getProps: () => props
    }

    const openAreaDialog = (url) => {
        openDialog(url);
    }

    const createNodeConfiguration = () => {
        const { currentNodeConfiguration } = props; 
        return currentNodeConfiguration.set('nodes', nodes)
    }

    const getPin = (subsystemNumber) => {
        let customer = helpers.current.getCustomer();
        let selectedsubsystem = customer.get('subsystems', []).filter((subsystem) => {
            return subsystem.get('number') == subsystemNumber;
        });
        return (selectedsubsystem.first() && selectedsubsystem.first().get('type') == 5 && selectedsubsystem.first().get('pin')) ? selectedsubsystem.first().get('pin') : 0;
    }

    const commandPoint = (selectedpoint, type, value, priority) => {
        let customer = helpers.current.getCustomer();
        let currentuser = helpers.current.getCurrentUser();
        let address = selectedpoint.getIn(['configuration', 'boundto']).split('-');
        let subsystemNumber = address.map((value) => { return parseInt(value); })[0];
        if (type >= 2){
            value = parseFloat(value);
        }
        else{
            type = value;
        }
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: subsystemNumber,
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: address.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: type,
            m_priority: priority || getPin(subsystemNumber),
            m_value: value,
            m_engUnitGuarded: 0,
            m_oldValue: selectedpoint.getIn(['pointconfiguration', 'm_value'])
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const nodeChangeDetection = (currentNode) => {
        if(!currentNode) {
            return '';
        }
        return `id-${currentNode.get('id')}-layer-${currentNode.get('layer')}`;
    }

    useEffect(() => {
        if(!subscription) return () => {};
        if(localState.currentNode && !helpers.current.getEditMode()) {
            let currentNode = localState.currentNode
            if(currentNode.getIn(['configuration', 'boundto'])) {
                let unsubscribe = subscription.subscribe(currentNode.getIn(['configuration', 'boundto']), (message) => {
                    
                    setLocalState(() => ({...localState,
                        currentNode: currentNode.set('pointconfiguration', Immutable.fromJS(message.pointconfiguration))
                        .set('rawvalue', message.pointconfiguration.m_value)
                        .set('currentvalue', message.value)
                        .set('currentalarmstate', message.pointconfiguration.m_formattedAlarmText)
                        .set('lastcommanded', message.pointconfiguration.m_whoCommanded)
                        .set('pointname', message.pointconfiguration.m_pointName)
                        .set('error', message.pointconfiguration.m_errorNumber)
                    }));


                });
                return () => {
                    unsubscribe();
                }
            }
        }
    },[nodeChangeDetection(localState.currentNode), subscription])

    const pointUpdated = (point) => {
        let customer = helpers.current.getCustomer();
        let request = {
            trackMessage: true,
            m_level: 10,
            m_communicNum: 300,
            target: 'signalr',
            type: "GENERIC",
            m_subsystemNumber: point.getIn(['configuration', 'boundto']).split('-')[0],
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }
        let comm = {
            m_pointAddr: point.getIn(['configuration', 'boundto']).split('-').map((bit) => { return parseInt(bit); }),
        }
        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    // sets state points
    const clearQueue = () => {
        setPoints({})
    }

    // no state
    const changeLayers = (layers) => {
        layersChanged(layers);
    }

    // will be called when a node is moved
    // will be called when a node changes layers
    // only called in drag and move up and down one layer
    // set state layers, updated
    const nodeChanged = (layer, node) => {
        setLayers((layers) => {
            let nodes = layers.getIn([layer, 'nodes']);
            let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
            let index = nodes.indexOf(editedNode);
            return layers.setIn([layer, 'nodes'], nodes.set(index, node));
        });
    }

    // layers from state ( conditionally )
    const multiNodeChanged = (layer, node, _layers) => {
        let layers = helpers.current.getLayers()
        _layers = _layers || layers;
        let nodes = _layers.getIn([layer, 'nodes']);
        let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
        let index = nodes.indexOf(editedNode);
        return _layers.setIn([layer, 'nodes'], nodes.set(index, node))
    }

    // sets state layers
    const layersChanged = (newLayers) => {
        newLayers = newLayers.map((layer, layerIndex) => {
            return layer.update('nodes', (nodes) => {
                return nodes.map(node => node.set('layer', layerIndex))
            })
        })
        setLayers(newLayers);
    }

    // layers from state
    // sets state layers, action, updated
    const nodeMovedToFront = (node, completely) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();

        let nodes = layers.getIn([node.get('layer'), 'nodes']);
        let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
        let index = nodes.indexOf(editedNode);
        if(completely && index == nodes.size - 1) {
            return;
        }
        if(completely) {
            nodes = nodes.delete(index);
            nodes = nodes.push(editedNode);
            setLayers(layers.setIn([node.get('layer'), 'nodes'], nodes));
        } else {
            let firstNode = nodes.get(index);
            let secondNode = nodes.get(index + 1);
            if(!secondNode || secondNode.get('layer') != firstNode.get('layer')) {
                return 
            }
            let mutatedList = nodes.withMutations(nodes => {
                firstNode = nodes.get(index);
                secondNode = nodes.get(index + 1);
                return nodes.set(index, secondNode)
                .set(index + 1, firstNode) 
            })
            setLayers(layers.setIn([node.get('layer'), 'nodes'], mutatedList))
        }
        
        setLocalState({...localState,
            action: 'placement',
            updated: Date.now()
        })
    }

    // layers from state
    // sets state layers, action, updated
    const nodeMovedToBack = (node, completely) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();
        
        let nodes = layers.getIn([node.get('layer'), 'nodes']);
        let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
        let index = nodes.indexOf(editedNode);
        if(completely && index == 0) {
            // already at the bottom
            return;
        }
        if(completely) {
            nodes = nodes.delete(index);
            nodes = nodes.unshift(editedNode);
            return setLayers(layers.setIn([node.get('layer'), 'nodes'], nodes));
        }
        let firstNode = nodes.get(index);
        let secondNode = nodes.get(index - 1);
        if(!secondNode || (index - 1) < 0 || secondNode.get('layer') != firstNode.get('layer')) {
            return
        }
        setLayers(layers.setIn([node.get('layer'), 'nodes'], nodes.withMutations(nodes => nodes.set(index, secondNode)
        .set(index - 1, firstNode))))
        setLocalState({...localState,
            action: 'placement',
            updated: Date.now()
        })
    }

    const onReIndexArea = () => {

        // TODO: when reindexing all other references to id's should be removed like selection
        // TODO: the components are being rendered with a reference to id an array position as key, see if this causes problems

        let layers = helpers.current.getLayers();
        localReferences.nodes = 0;
        let newLayers = layers.map((layer, index) => {
            let nodes = layer.get('nodes');
            nodes = nodes.map((node, index) => {
                return node.set('id', ++localReferences.nodes);
            })
            return layer.set('nodes', nodes);
        })
        setLayers(newLayers);
    }
    
    // params, currentNodeConfiguration, baseDirectory, customerMainDirectory from props
    const loadArea = () => {
        let props = helpers.current.getProps();
        
        let { currentNodeConfiguration, baseDirectory, customerMainDirectory } = props;
        if (!currentNodeConfiguration) {
            setLayers(List().push(Map({ nodes: List() })));
            setNodes(Immutable.List([]))
            return;
        }

        let promise = undefined;
        if(currentNodeConfiguration.get('hasTemplate')) {
            const setTemplateVariables = (templateString) => {
                if(currentNodeConfiguration.get('templatevariables')) {
                    currentNodeConfiguration.get('templatevariables').map((val, key) => {
                        let expr = new RegExp(`\\[${key}\\]`, 'g');
                        // console.log(`replacing [${key}] with ${val}`);
                        templateString = templateString.replace(expr, val);
                    })
                }
                return templateString;
            } 

            const setTemplateValuesToNode = (node) => {
                if(node.getIn(['configuration','boundto'])) {
                    node = node.updateIn(['configuration','boundto'], x => setTemplateVariables(x))
                }
                if(node.getIn(['configuration','value'])) {
                    node = node.updateIn(['configuration','value'], x => setTemplateVariables(x))
                }
                if(node.getIn(['configuration','linkto'])) {
                    node = node.updateIn(['configuration','linkto'], x => setTemplateVariables(x))
                }
                if(node.getIn(['configuration','points'])) {
                    let points = node.getIn(['configuration','points']);
                    points = points.map(point => {
                        if(point.get('value')){
                            point = point.update('value', x=> setTemplateVariables(x))
                        }
                        return point;
                    })
                    node = node.setIn(['configuration','points'], points);
                }
                return node;

            }

            promise = getArea(`${customerMainDirectory}_templates-v2_${currentNodeConfiguration.get('template')}`).promise.then((data) => {
                let layers = Immutable.fromJS(data);
                
                layers = layers.map((layer, layerindex) => {
                    return layer.update('nodes', nodes => nodes.map(node => {
                        return setTemplateValuesToNode(node).set('layer', layerindex);
                    }))
                })
                setLayers(layers);
            })  
        } else {
                promise = getArea(`${baseDirectory}_${currentNodeConfiguration.get('name', '').toLowerCase().split(' ').join('-')}.json`).promise.then((data) => {
                let nodes = Immutable.fromJS(data);
                let layers = List();
                if((nodes.first() || Map()).get('nodes')) {
                    layers = nodes;
                    nodes = List();
                }
                layers.forEach((layer, index) => {
                    let layerNodes = layer.get('nodes').map((x, i) => x.set('layer', index));
                    nodes = nodes.push(...layerNodes);
                })
                let highestId = nodes.reduce((max, node) => Math.max(node.get('id'), max), 0);
                localReferences.nodes = highestId;
                nodes = nodes.map(
                    x => x.get('id') ? x : x.set('id', ++localReferences.nodes)
                );
                layers = layers.map((layer, index) => {
                    return layer.update('nodes', nodes => nodes.map(node => {
                        node = node.set('layer', index);
                        return node.get('id') ? node : node.set('id', ++localReferences.nodes);
                    })).update('name', (x = `Layer #${index + 1}`) => x + "");
                })
                // this is only used by the area sidebar, apperently the area can be saved there
                // so be cautious on the implicitions for area of this.
                // updateNodeConfiguration(currentNodeConfiguration.set('nodes', nodes));
                // console.log("setting layers", layers); 
                setLayers(layers);
                // setPointData(generatePointDataMap(layers));
            })
        }

        
        promise.catch((err) => {
            if (err && err.response && err.response.status == 404) {
                setLayers(Immutable.List([]))
            }
        });
    }

    // nodes, layers from state
    // currentNodeConfiguration, params, baseDirectory from props
    const localSaveArea = () => {
        let layers = helpers.current.getLayers();
        let props = helpers.current.getProps();
        let { currentNodeConfiguration, customerMainDirectory, baseDirectory } = props;
        let path = `${baseDirectory}_${currentNodeConfiguration.get('name').toLowerCase().split(' ').join('-')}.json`;
        const nodeUpdater = (x) => x.delete('layer');
        let layersToSave = layers.map(x => x.update('nodes', y => y.map(nodeUpdater))).toList();
        
        if(currentNodeConfiguration.get('hasTemplate')) {
            layersToSave = layers.map(x => x.update('nodes', node => {
                if(node.getIn(['configuration','template_boundto'])) {
                    node = node.updateIn(['configuration','boundto'], x => node.getIn(['configuration','template_boundto']))
                    node = node.deleteIn(['configuration','template_boundto']);
                }
                if(node.getIn(['configuration','template_value'])) {
                    node = node.updateIn(['configuration','value'], x => node.getIn(['configuration','template_value']));
                    node = node.deleteIn(['configuration','template_value']);
                }
                if(node.getIn(['configuration','template_linkto'])) {   
                    node = node.updateIn(['configuration','linkto'], x => node.getIn(['configuration','template_linkto']))
                    node = node.deleteIn(['configuration','template_linkto']);
                }
                if(node.getIn(['configuration','points'])) {
                    let points = node.getIn(['configuration','points']);
                    points = points.map(point => {
                        if(point.get('template_value')){
                            point = point.set('value', point.get('template_value'))
                            point = point.delete('template_value')
                        }
                        return point;
                    })
                    node = node.setIn(['configuration','points'], points);
                }
                return node;
            })).toList();
            
            path = `${customerMainDirectory}_templates-v2_${currentNodeConfiguration.get('template')}`;

        }

        let rawNodes = layersToSave.toJS();
        
        saveArea(rawNodes, path).then(() => {
            Swal.fire("Success", "The area is saved successfully", "success");
        }).catch((err) => {
            Swal.fire("Error", "An error occurred while saving the area", "error");
        });
    }

    const toggleConversionDialogOpened = () => {
        let localState = helpers.current.getLocalState();
        setLocalState({...localState,
            conversionDialogOpened: !localState.conversionDialogOpened
        })
    }

    const changeConversionCoords = (x, y) => {
        setLocalState((localState) => ({ ...localState, conversionCoords: { x, y } }))
    }

    // TODO: refactor into layers
    // nodes, conversionCoords, layers from state
    // currentNodeConfiguration, params, baseDirectory from props
    const convertArea = () => {
        let localState = helpers.current.getLocalState();
        let { conversionCoords } = localState;
        let x = Number(conversionCoords.x);
        let y = Number(conversionCoords.y);
        let changedNodes = nodes.map((node) => {
            node = node && node.setIn(['position', 'x'], node.getIn(['position', 'x']) + (x) );
            node = node && node.setIn(['position', 'y'], node.getIn(['position', 'y']) + (y) );
            return node;
        })
        setNodes(changedNodes);
        setLocalState({...localState,
            conversionDialogOpened: false
        })
    }

    // Add component, Add pointlist (type == 'pointlist)
    const createNode = (evt, type) => {
        setLocalState((localState) => ({...localState,
            action: type || 'creation',
            currentNode: Immutable.fromJS({
                position: {
                    x: evt.x,
                    y: evt.y
                }
            })
        }));
    }

    const placeNodes = (nodes) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();

        let { currentLayerIndex } = localState;
        nodes = nodes.map(node => node.set('id', node.get('id') || ++localReferences.nodes));
        nodes = nodes.map(node => node.set('layer', currentLayerIndex));
        let newLayers = layers.updateIn([currentLayerIndex, 'nodes'], List() ,x => x.push(...nodes));
        if(!newLayers.getIn([currentLayerIndex, 'name'])) {
            newLayers = newLayers.setIn([currentLayerIndex, 'name'], `Layer #${currentLayerIndex + 1}`);
        }
        setLayers(newLayers);
        setLocalState({...localState,
            action: 'placement',
            currentNode: undefined,
            updated: Date.now()
        })
    }

    const placeNode = (node) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();
   
        let { currentLayerIndex } = localState;
        node = node.set('id', node.get('id') || ++localReferences.nodes);
        node = node.set('layer', currentLayerIndex);
        let newLayers = layers.updateIn([currentLayerIndex, 'nodes'], List() ,x => x.push(node));
        if(!newLayers.getIn([currentLayerIndex, 'name'])) {
            newLayers = newLayers.setIn([currentLayerIndex, 'name'], `Layer #${currentLayerIndex + 1}`);
        }
        setLayers(newLayers);
        setLocalState({...localState,
            action: 'placement',
            currentNode: undefined,
            updated: Date.now()
        })
    }

    const saveNode = (node) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();
        
        let { currentLayerIndex, currentNodeIndex } = localState;
        if (node.get('pointconfiguration')) {
            node = node.delete('pointconfiguration');
        }
        let changedNodes = layers.getIn([node.get('layer'), 'nodes']);
        let nodes = layers.getIn([node.get('layer'), 'nodes']);
        if(currentNodeIndex !== undefined) {
            let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
            let index = nodes.indexOf(editedNode);
            changedNodes = changedNodes.set(index, node);
        } else {
            changedNodes = changedNodes.push(node);
        }
        setLayers(layers.setIn([node.get('layer'), 'nodes'], changedNodes));
        setLocalState({...localState,
            updated: Date.now(),
            currentNodeIndex: undefined,
            currentNode: undefined,
            chartNode:undefined,
            reportNode:undefined,
            emailNode:undefined,
            ex500TimeProgramNode:undefined,
            controllerPointListNode:undefined,
            schedulePointGroupNode:undefined,
            action: undefined
        })
    }

    const savePointlist = (node) => {
        let layers = helpers.current.getLayers();
        let localState = helpers.current.getLocalState();

        let { currentLayerIndex } = localState;
        let pointlistWasCreated = !!!node.get('id');
        node = node.set('id', node.get('id') || ++localReferences.nodes);
        node = node.set('layer', currentLayerIndex);
        let newLayers = layers.updateIn([currentLayerIndex, 'nodes'], List() ,x => x.push(node));
        if(!newLayers.getIn([currentLayerIndex, 'name'])) {
            newLayers = newLayers.setIn([currentLayerIndex, 'name'], `Layer #${currentLayerIndex + 1}`);
        }
        
        if (node.get('pointconfiguration')) {
            node = node.delete('pointconfiguration');
        }
        let changedNodes = layers.getIn([node.get('layer'), 'nodes']);
        let nodes = layers.getIn([node.get('layer'), 'nodes']);
        if(!pointlistWasCreated) {
            let editedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
            let index = nodes.indexOf(editedNode);
            changedNodes = changedNodes.set(index, node);
        } else {
            changedNodes = changedNodes.push(node);
        }
        setLayers(layers.setIn([node.get('layer'), 'nodes'], changedNodes));
        setLocalState({...localState,
            updated: Date.now(),
            currentNodeIndex: undefined,
            currentNode: undefined,
            chartNode:undefined,
            reportNode:undefined,
            emailNode:undefined,
            ex500TimeProgramNode:undefined,
            schedulePointGroupNode:undefined,
            action: undefined
        })
    }

    // called by graphic onNodeConfiguration
    const configureNode = (index, node, options) => {


        console.log("configureNode called", index, node, options);

        let localState = helpers.current.getLocalState();
        let editmode = helpers.current.getEditMode();

        if (index == undefined){
            index = node.get('_ownindex');
        }
        let layer = node.get('layer');
        // let { openAreaDialog } = props;
        let action = editmode && 'placement';
        if (node.getIn(['component', 'type']) == 'pointlist') {
            action = 'pointlist';
            if (!editmode) {
                node = node.updateIn(['configuration', 'points'], (arr) => {
                    arr = arr.map((point) => {
                        return point;
                    })
                    return arr;
                })
                return setLocalState({...localState,
                    pointlistnode: node
                });
            }
        }

        if (!action && node.getIn(['configuration', 'islink'])) {
            if(node.getIn(['configuration','isdialog'])) {
                openAreaDialog(node.getIn(['configuration', 'linkto']));
            } else {
                navigate(node.getIn(['configuration', 'linkto']));
            }
            return;
        }

        if(node.getIn(['configuration', 'boundto']) && !editmode) {
            // console.log("setting the last point value to the node");
            let pointValues = subscription.getLastValue(node.getIn(['configuration', 'boundto']));
            if(pointValues) {
                node = node.set('pointconfiguration', Immutable.fromJS(pointValues.pointconfiguration))
                .set('rawvalue', pointValues.pointconfiguration.m_value)
                .set('currentvalue', pointValues.value)
                .set('currentalarmstate', pointValues.pointconfiguration.m_formattedAlarmText)
                .set('lastcommanded', pointValues.pointconfiguration.m_whoCommanded)
                .set('pointname', pointValues.pointconfiguration.m_pointName)
                .set('error', pointValues.pointconfiguration.m_errorNumber)
            }
            
        }
        let newState = {...localState, 
            action: action,
            currentNodeIndex: index,
            currentLayerIndex: layer
        }
        if (node.getIn(['component', 'type']) == 'chart') {
            newState.chartNode = node;
        } else if (node.getIn(['component', 'type']) == 'report') {
            newState.reportNode = node;
        } else if (node.getIn(['component', 'type']) == 'systemobject') {
            if (node.getIn(['component', 'subtype']) == 'email') {
                newState.emailNode = node;
            } else if (node.getIn(['component', 'subtype']) == 'ex500timeprogram') {
                newState.ex500TimeProgramNode = node;
            } else if (node.getIn(['component', 'subtype']) == 'controllerpointlist') {
                newState.controllerPointListNode = node;
            } else if (node.getIn(['component', 'subtype']) == 'schedulepointgroup') {
                newState.schedulePointGroupNode = node;
            }
        } else {
            newState.currentNode = node;
        } 
        setLocalState(newState);
    }

    // i don't know if layers has an implication on this.
    const selectNode = (node) => {
        let rawNode = node.toJS();
        setLocalState((localState) => ({...localState,
            currentNode: node
        }));
    }

    const deleteNode = (_index, node) => {
        let layers = helpers.current.getLayers();
        let layer = node.get('layer');
        let nodes = layers.getIn([layer, 'nodes']);
        let deletedNode = nodes.filter(x => x.get('id') == node.get('id')).first();
        let index = nodes.indexOf(deletedNode);
        let newLayers = layers.deleteIn([layer, 'nodes', index]);
        setLayers(newLayers);
    }

    const deleteNodes = (nodeIds) => {
        let layers = helpers.current.getLayers();
        let newLayers = layers.map((layer, layerIndex) => {
            return layer.update('nodes', (nodes) => {
                return nodes.filter(node => !nodeIds.includes(node.get('id')))
            })
        });
        setLayers(newLayers);

    }

    const clearSelection = () => {
        setLocalState((localState) => ({...localState,
            currentNode: undefined,
            currentNodeIndex: undefined,
            chartNode:undefined,
            reportNode:undefined,
            emailNode:undefined,
            ex500TimeProgramNode:undefined,
            controllerPointListNode: undefined,
            schedulePointGroupNode: undefined,
            action: undefined
        }));
    }

    const clearPointlist = () => {
        setLocalState(localState => ({...localState,
            pointlistnode: undefined
        }));
    }

    
    // sets state(controllerPointListNode) not used by graphics
    const clearControllerPointListDialog = () => {
        setLocalState((localState) => ({...localState,
            controllerPointListNode: undefined
        }));
    }

    useEffect(() => {
        let props = helpers.current.getProps();
        let { baseDirectory, renderedInDialog } = props;
        let customerName = site.get('customerName');
        let siteName = site.get('siteName');
        // updateNodeConfiguration(currentNodeConfiguration, indexOfCurrentNodeConfiguration);
        let baseCustomerDirectory = baseDirectory.split('_');
        if ( baseCustomerDirectory[0] + '_' + baseCustomerDirectory[1] == customerName + '_' + siteName ) {
            loadArea();
        }    

        return () => {

        }
    }, [])

    useEffect(() => {
        loadArea();
    }, [site, props.currentNodeConfiguration])

    let { currentNodeConfiguration, renderedInDialog } = props;
    let { currentLayerIndex, conversionCoords, conversionDialogOpened, currentNode, chartNode, reportNode, emailNode, ex500TimeProgramNode, schedulePointGroupNode, controllerPointListNode, action, pointlistnode } = localState;


    if (!currentNodeConfiguration) {
        return <data></data>
    }
    return (
        <React.Fragment>
            { !renderedInDialog && editmodeAuthorized && editmode && <AreaSaveButton onSave={localSaveArea} />  }
            { !renderedInDialog && <Fragment>
                <LeftSlide>
                    <AreaSidebar 
                        match={ {  params: [props.nodepath] } }
                        nodeconfigurationindex={props.indexOfCurrentNodeConfiguration}
                        nodeconfiguration={createNodeConfiguration()} />
                </LeftSlide>
            </Fragment> }
            <ConversionDialog
                selecteditem={conversionDialogOpened}
                changeCoords={changeConversionCoords}
                x={conversionCoords.x}
                y={conversionCoords.y}
                onSave={convertArea}
                onClose={toggleConversionDialogOpened} />
            <ComponentSelectionDialog 
                selectedCategory={componentSelectedCategory}
                customer={customer} 
                site={site} 
                selecteditem={action == 'creation' && currentNode} 
                placeNode={placeNode} 
                onClose={clearSelection} />
            <ComponentConfigurationDialog 
                area={currentNodeConfiguration}
                customer={customer} 
                selecteditem={action == 'placement' && (currentNode || chartNode || reportNode || emailNode || ex500TimeProgramNode || schedulePointGroupNode || controllerPointListNode)} 
                onSave={saveNode} 
                onClose={clearSelection} />
            <PointListDialog 
                selecteditem={action == 'pointlist' && currentNode} 
                onSave={savePointlist}  
                onClose={clearSelection} />
            <PointStatusDialog 
                currentuser={currentuser} 
                customer={customer} 
                site={site} 
                selecteditem={!action && currentNode && currentNode.getIn(['configuration', 'boundto']) && currentNode} 
                onClose={clearSelection} 
                updatePoint={pointUpdated} />
            <PointListStatusDialog 
                lastupdated={points.lastupdated} 
                onSelect={selectNode} 
                selecteditem={!currentNode && pointlistnode || undefined} 
                onClose={clearPointlist} />
            <HistoriesDialog 
                customer={customer} 
                selecteditem={!action && chartNode && chartNode.getIn(['configuration', 'boundto']) && chartNode} 
                onClose={clearSelection} />
            <ReportsDialog 
                customer={customer} 
                selecteditem={!action && reportNode && reportNode.getIn(['configuration', 'points']) && reportNode} 
                onClose={clearSelection} />
            <EmailFormDialog 
                currentuser={currentuser} 
                customer={customer} 
                selecteditem={!action && emailNode} 
                onClose={clearSelection} />
            <Excel500WeeklyScheduleDialog 
                currentuser={currentuser} 
                customer={customer} 
                selecteditem={!action && ex500TimeProgramNode} 
                timeProgramName={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'objectName'], '')}
                subsystemNumber={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'subsystemNumber'], 0)}
                controllerNumber={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'controllerNumber'], 1)}
                timeProgramIndex={ex500TimeProgramNode && ex500TimeProgramNode.getIn(['configuration', 'timeProgramIndex'], 0)}
                onClose={clearSelection} /> 
            { controllerPointListNode && !editmode && <ControllerPointListDialog
                currentuser={currentuser} 
                customer={customer} 
                onSelect={selectNode} 
                selecteditem={!action && controllerPointListNode} 
                subsystemNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'subsystemNumber'], 0)}
                subsystemType={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'subsystemType'], 5)}
                controllerNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'controllerNumber'], 1)}
                networkNumber={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'networkNumber'], 0)}
                pointtype={controllerPointListNode && controllerPointListNode.getIn(['configuration', 'pointType'], 0)}
                onClose={clearControllerPointListDialog} />}
            <SchedulePointGroupDialog 
                currentuser={currentuser} 
                customer={customer} 
                selecteditem={!action && schedulePointGroupNode} 
                onClose={clearSelection} /> 
            <Graphic
                // key={(this.state.updated || "").toString()}
                onConvertArea={currentuser.get('isSuperAdmin') ? toggleConversionDialogOpened : undefined}
                nodeConfiguration={currentNodeConfiguration}
                backdrop={currentNodeConfiguration.getIn(['backdrop'])}
                style={currentNodeConfiguration.get('style')}
                layersChanged={layersChanged}
                openAreaDialog={openAreaDialog}
                placeNodes={placeNodes}
                currentLayerIndex={currentLayerIndex}
                onNodeCreation={createNode}
                nodeChanged={nodeChanged}
                multiNodeChanged={multiNodeChanged}
                nodeMovedToBack={nodeMovedToBack}
                nodeMovedToFront={nodeMovedToFront}
                onReIndexArea={onReIndexArea}
                onNodeConfiguration={configureNode}
                configureNode={configureNode}
                clearQueue={clearQueue}
                editmode={editmode}
                deleteNode={deleteNode}
                deleteNodes={deleteNodes}
                customer={customer}
                site={site}
                pointUpdated={pointUpdated}
                showMobileGraphic={showMobileGraphic}
                renderedInDialog={renderedInDialog}
            />
            { editmode && <LayerManager onSelect={(index) => { setLocalState((localState) => ({ ...localState, currentLayerIndex: index })) }} currentLayerIndex={currentLayerIndex} layers={layers} onChange={changeLayers} /> }
        </React.Fragment>
    )






}

export default Area;